import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Transit extends ServerExtra {
    private readonly _session: string;

    private readonly _domain: string;

    constructor( private readonly data ) {
        super( ServerExtraType.TRANSIT );

        this._session = data?.session;
        this._domain = data?.domain;

        this.data = null;
    }

    get session(): string {
        return this._session;
    }

    get domain(): string {
        return this._domain;
    }

    public toJson(): any {
        return {
            session: this.session,
            domain : this.domain
        };
    }
}
