<div fxLayout="column"
     fxFlexFill>

    <div [hidden]="!_authService.loggedIn"
         class="banner-container" fxFlex="auto">

            <ks-banner *ngIf="_authService.impersonating"
                    color="danger"
                       icon="user-groups"
                       [buttons]="impersonateButtons"
                       timerText="Auto logout in"
                       [timer]="impersonationTimerValue"
                       [timerStartAt]="impersonationExpires"
                       [reset]="resetImpersonationTimer"
                       [style.border-bottom]="(confirmed || (banner && banner.bannerObj.name)) ? '1px #434343 dotted' : ''"
                       (timerComplete)="onImpersonationTimerComplete()">
                You are currently impersonating {{_authService.loggedInUser.name}}, please logout when you are finished.
            </ks-banner>

            <ks-banner *ngIf="banner && banner.bannerObj.name" [color]="banner.bannerObj.color" [close]="true" (onClose)="closeBanner()"
                       [icon]="banner.bannerObj.icon" [buttons]="statusBannerButtons">{{banner.bannerObj.name}}
            </ks-banner>

            <ks-banner *ngIf="showOpsPilotUpgrade"
                       color="info"
                       icon="warning-filled"
                       [buttons]="opspilotUsageButtons"
                       [close]="true"
                       (onClose)="handleOpspilotUpgradeClose()">
                You have reached your OpsPilot token usage limit
            </ks-banner>
    </div>

    <div class="not-banner-container">
        <ks-toolbar fxFlex="0 0 50px"
                    [title]="'FusionReactor'"
                    [subtitle]="'Cloud'"
                    [centermenu]="centreToolbarMenu"
                    [leftmenu]="leftToolbarMenu"
                    [img]="'assets/FRLogo-blue-white.svg'"
                    *ngIf="_authService.loggedIn"
                    [showTimePicker]="showTimePicker">
            <ks-toolbar-content>

                <ul class="horizontal-list ks-toolbar-menu"
                    fxLayout="row"
                    fxLayoutAlign="start center">
                    <li class="nav-separator"></li>
                    <li>
                        <button (click)="toggleChat()" class="help-menu no-margin-right">
                            <i class="menu-icon-right icon icon-comments"></i>
                        </button>
                    </li>
                    <li>
                        <ks-dropdown [closeOnOutsideClick]="true"
                                     [closeOnClick]="true"
                                     [open]="openHotkeys"
                                     (click)="openHotkeys = true">
                            <ks-dropdown-toggle>
                                <button class="help-menu no-margin-right">
                                    <i class="menu-icon-right icon icon-keyboard"></i>
                                </button>
                            </ks-dropdown-toggle>
                            <ks-dropdown-menu class="align-right" [forceWidth]="400">
                                <app-hotkey-list></app-hotkey-list>
                            </ks-dropdown-menu>
                        </ks-dropdown>
                    </li>
                    <li>
                        <ks-dropdown>
                            <ks-dropdown-toggle [disabled]="accountLocked">
                                <button class="help-menu">
                                    <i class="menu-icon-right icon icon-question"></i>
                                </button>
                            </ks-dropdown-toggle>
                            <ks-dropdown-menu class="align-right">
                                <ul class="vertical-list nav-dropdown-menu">
                                    <li id="open-docs-btn"
                                        (click)="showDocs()"
                                        class="horizontal-buttons">
                                        <i class="icon icon-document has-text-right"></i>
                                        <span>Documentation</span>
                                    </li>
                                    <li class="horizontal-buttons"
                                        (click)="openInstallationGuide()">
                                        <i class="icon icon-new-app has-text-right"></i>
                                        <span>Installation Guide</span>
                                    </li>
                                </ul>
                            </ks-dropdown-menu>
                        </ks-dropdown>
                    </li>
                    <li>
                        <ks-dropdown>
                            <ks-dropdown-toggle>
                                <img id="avatar-menu"
                                     [src]="'favicon.ico'" data-avatar alt="avatar"/>

                                <span *ngIf="unreadCount > 0"
                                      class="change-log-indicator"></span>
                            </ks-dropdown-toggle>
                            <ks-dropdown-menu class="align-right">
                                <ul class="vertical-list nav-dropdown-menu">
                                    <li *ngIf="isDevMode" class="horizontal-buttons" (click)="copyAccountId()" >
                                        <i class="icon icon-copy"></i>
                                        <span class="account">ID: {{ accountId }}</span>
                                    </li>
                                    <li [routerLink]="['/account', 'settings']"
                                        class="horizontal-buttons">
                                        <i class="icon icon-users has-text-right"></i>
                                        <span>Account</span>
                                    </li>
                                    <li *ngIf="!isNotCloud"
                                        [routerLink]="['/account', 'billing', 'details']"
                                        [ksNeedsPermission]="['billing.all']"
                                        class="horizontal-buttons">
                                        <span class="icon material-icons has-text-right">payment</span>
                                        <span>FR Cloud Billing</span>
                                    </li>
                                    <li  *ngIf="!isNotCloud"
                                         [routerLink]="['/account', 'apikey']"
                                        [ksNeedsPermission]="['apiKey.create']"
                                        class="horizontal-buttons">
                                        <span class="icon material-icons has-text-right">key</span>
                                        <span>API Keys</span>
                                    </li>
                                    <li [routerLink]="['/profile']"
                                        class="horizontal-buttons">
                                        <i class="icon icon-user has-text-right"></i>
                                        <span>Profile</span>
                                    </li>
                                    <li *ngIf="!isNotCloud"
                                        [routerLink]="['/configuration']"
                                        [ksNeedsPermission]="['services.update']"
                                        class="horizontal-buttons">
                                        <i class="icon icon-cog has-text-right"></i>
                                        <span>Configuration</span>
                                    </li>
                                    <li [routerLink]="['/account', 'users']"
                                        [queryParams]="{ add_user: true }"
                                        [ksNeedsPermission]="['users.create']"
                                        class="horizontal-buttons">
                                        <i class="icon icon-user-add has-text-right"></i>
                                        <span>Invite team members</span>
                                    </li>
                                    <li (click)="giveFeedback()"
                                        class="horizontal-buttons">
                                        <i class="icon icon-comments has-text-right"></i>
                                        <span>Give feedback</span>
                                    </li>
                                    <li (click)="openStatusPage()" class="horizontal-buttons">
                                        <i class="icon icon-check has-text-right"></i>
                                        <span>Status page</span>
                                    </li>
                                    <li (click)="openChangeLogDrawer()"
                                        class="horizontal-buttons">
                                        <i class="icon icon-field-list has-text-right">
                                        </i>
                                        <span>Updates</span>
                                        <span *ngIf="unreadCount > 0" class="change-log-indicator inline"></span>
                                    </li>
                                    <li [routerLink]="['about']"
                                        class="horizontal-buttons">
                                        <i class="icon icon-question has-text-right"></i>
                                        <span>About</span>
                                    </li>
                                    <li (click)="_authService.logout().then()"
                                        class="horizontal-buttons">
                                        <span class="icon material-icons has-text-right">logout</span>
                                        <span>Logout</span>
                                    </li>
                                </ul>
                            </ks-dropdown-menu>
                        </ks-dropdown>
                    </li>
                </ul>

            </ks-toolbar-content>
        </ks-toolbar>

        <div [fxFlex]="'calc(100% - ' + (_authService.loggedIn ? '50' : '0') + 'px)'"
             fxFlexFill
             fxLayout="row">

            <div class="page-container" [class.opspilot-open]="isOpsPilotOpen">

                <ks-atom-spinner *ngIf="showLoader"></ks-atom-spinner>

                <app-modal></app-modal>

                <router-outlet></router-outlet>

                <ks-nag *ngIf="_eventService.nags.length"
                        [watch]="_eventService.nags.length">

                    <h2 class="ks-toolbar-title" ks-nag-title>
                        <ks-icon fontIcon="alert"
                                 style="color: #ff4514; font-size:  small; margin-right: 10px"></ks-icon>
                        FusionReactor Cloud has been updated
                    </h2>

                    <h3 *ngFor="let nag of _eventService.nags; trackBy:trackPrimitiveFn">
                        <span>{{ nag }}</span>
                        <a class="link" (click)="refresh()">refresh</a>
                        <span> to get it now, </span>
                        <span> or </span>
                        <a class="link" (click)="openChangeLogDrawer()">view changelog</a>
                    </h3>

                </ks-nag>
            </div>

            <app-ops-pilot *ngIf="_authService.loggedIn"></app-ops-pilot>

            <div id="docs-container"
                 *ngIf="isShowingDocs && _authService.loggedIn">
                <span fxLayoutAlign="center center"
                      (click)="isShowingDocs = false"
                      class="doc-minimizer tag icon icon-arrow-right shadow-2 clickable"></span>
                <iframe data-cy="docs-frame" [src]="docsSafeUrl"></iframe>
            </div>
        </div>
    </div>
</div>

<ng-template #changeLogViewer>
    <app-changelog-viewer></app-changelog-viewer>
</ng-template>

<ng-template #installationGuideTemplate>
    <app-onboarding-agent-installation (finished)="closeInstallationGuide()"></app-onboarding-agent-installation>
</ng-template>

<ng-template #impersonationDialogTmpl>
    <p class="text-center">Are you still using this impersonation session?</p>
    <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px">
        <div>
            <button class="btn btn-primary" (click)="impersonationDialog.instance.close.next(true)">Yes</button>
            <button class="btn btn-default" (click)="impersonationDialog.instance.close.next(false)">No</button>
        </div>
    </div>
</ng-template>
