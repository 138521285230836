import { Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    ActivatedRoute,
    GuardsCheckEnd,
    GuardsCheckStart,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    ResolveEnd,
    ResolveStart,
    Router,
    RouterEvent
} from '@angular/router';
import {
    KsDialogService,
    KsDrawerService,
    KsDrawerSize,
    KsEntityService,
    KsGraphProfileService,
    KsHotkeysService,
    KsLoadingService,
    KsMetricsExplorerService,
    KsNotificationService,
    KsPermissionService,
    KsThemeService,
    KsTimePickerArea,
    KsTimePickerDataObject,
    KsTimePickerService,
    TimePickerDVMException
} from '@intergral/kaleidoscope';
import * as Highcharts from 'highcharts';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { filter, map, pluck, startWith, take, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SharedComponent } from './core/components/shared-component';
import { Billing } from './core/models/Billing';
import { ChangeLog } from './core/models/ChangeLog';
import { IUserGroupPermission } from './core/models/UserGroup';
import { AccountService } from './core/services/account.service';
import { AuthService } from './core/services/auth.service';
import { ChangeLogService } from './core/services/changelog.service';
import { EventService } from './core/services/event.service';
import { LaunchDarklyService } from './core/services/launchdarkly.service';
import { ServerService } from './core/services/server.service';
import { UserService } from './core/services/user.service';
import { UtilityService } from './core/services/utility.service';
import { trackPrimitiveFn } from './core/trackByFunctions/track-primitive';
import { ShowTimePickerService } from './core/services/show-time-picker.service';
import { IntercomService } from './core/services/intercom.service';
import { OpspilotService } from './core/services/opspilot.service';
import { Clipboard } from '@angular/cdk/clipboard';

import { evaluateCloudPermission } from './core/guards/evaluate-cloud.permission';

@Component( {
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : [ './app.component.scss' ]
} )
export class AppComponent extends SharedComponent implements OnDestroy, OnInit {
    public trackPrimitiveFn = trackPrimitiveFn;
    public openHotkeys = false;
    public leftToolbarMenu = [];
    public centreToolbarMenu = [];
    public showLoader = true;
    public errors: string[] = [];
    public impersonationExpires = 0;
    public resetImpersonationTimer: BehaviorSubject<any> = new BehaviorSubject( undefined );
    public accountLocked = false;
    public isNotCloud = false;
    public showTimePicker = true;
    public loggedIn = false;
    public allowedUrls: any = [
        'guides/servers', 'guides/applications', 'guides/alerting', 'guides/explore', 'guides/dashboards', 'billing/overview',
        'subscription/overview'
    ];

    public accountId: string;
    public isDevMode: boolean;
    public isOpsPilotExperimentalFeature: boolean;
    public showOpsPilotUpgrade = false;
    public isOpsPilotOpen = false;
    public banner: Billing;
    public confirmed = false;
    public impersonationTimerValue: number = 600;
    public unreadCount: number = 0;
    public impersonateButtons: any = [
        {
            input      : 'Logout',
            buttonClass: 'btn-thing',
            clickEvent : () => {
                this._authService.logout().then();
            }
        }
    ];

    public opspilotUsageButtons: any = [
        {
            input      : 'Upgrade now',
            buttonClass: 'btn-thing',
            clickEvent : () => {
                this.router.navigate( [ 'account', 'billing', 'purchase' ] );
            }
        }
    ];

    public statusBannerButtons: any = [
        {
            route      : '',
            input      : '',
            buttonClass: 'btn-thing'
        }
    ];

    public trafficSource: string;
    public state = '';
    // Default flex offset for the main body context
    public mainFlexOffset: number = 0;
    public docsSafeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl( environment.urls.docs );
    @ViewChild( 'impersonationDialogTmpl' ) public impersonationDialogTmpl: TemplateRef<any>;
    @ViewChild( 'changeLogViewer' ) public changeLogViewer: TemplateRef<any>;
    @ViewChild( 'installationGuideTemplate' ) public installationGuideTemplate: TemplateRef<any>;
    public installationGuideDialog: ComponentRef<any>;
    public impersonationDialog: ComponentRef<any>;
    public isShowingDocs = false;

    /**
     * Moment to track the point in time the page visibility became hidden
     */
    private visibilityChangeTime: moment.Moment;
    /**
     * Options required for storing cookies
     */
    private opAssistantHotkeyActive: boolean = false;

    constructor( public _authService: AuthService,
        private readonly dialogService: KsDialogService,
        private readonly router: Router,
        private readonly _serverService: ServerService,
        // required for the service to be created and get server List
        private readonly _utilityService: UtilityService,
        private readonly ksLoadingService: KsLoadingService,
        private readonly sanitizer: DomSanitizer,
        private readonly _userService: UserService,
        private readonly _accountService: AccountService,
        private readonly _timePickerService: KsTimePickerService,
        private readonly route: ActivatedRoute,
        private readonly _hotkeyService: KsHotkeysService,
        private readonly _metricsService: KsMetricsExplorerService,
        private readonly notificationService: KsNotificationService,
        private readonly permissionService: KsPermissionService,
        private readonly entityService: KsEntityService,
        private readonly _drawerManager: KsDrawerService,
        public _eventService: EventService,
        private readonly cookieService: CookieService,
        private readonly _changeLogService: ChangeLogService,
        private readonly ksDialogService: KsDialogService,
        private readonly _graphProfileService: KsGraphProfileService,
        private readonly ksThemeService: KsThemeService,
        private readonly _ldService: LaunchDarklyService,
        private readonly clipboard: Clipboard,
        private readonly _showTimePickerService: ShowTimePickerService,
        private readonly _intercomService: IntercomService,
        private readonly _opspilotService: OpspilotService ) {
        super( _authService, _userService, _timePickerService );

        const locale = ( window.navigator as any ).userLanguage || window.navigator.language;
        moment.locale( locale );

        this.loggedIn = this._authService.loggedIn;
        this._metricsService.setUrl( environment.urls.api.metrics );
        this._metricsService.setLiveDataUrl( environment.urls.api.coms.metric );
        this._graphProfileService.setUrl( environment.urls.api.coms.metric );

        this.listenForTimePicker();
        this.listenForLogin();
        this.listenForFlagChanges();
        this.listenForLogout();
        this.listenForRouting();
        this.listenForAccountUpdates();
        this.listenAndControlFlexOffset();
        this.listenForOpsPilotTrialUpgradeNotification();

        this.addHotKeys();

        // restore theme from cookie for the login screen
        this.restoreTheme();

        this.route.queryParams.pipe( takeUntil( this.ngUnsubscribe ), pluck( 'traffic_source' ) )
            .subscribe( ( traffic_source: string ) => {
                if ( traffic_source ) {
                    this.trafficSource = traffic_source;
                    this._intercomService.update( { traffic_source: this.trafficSource } );
                }
            } );
    }

    public showOpspilotSideMenu() {
        this.openHotkeys = false;
        this._opspilotService.toggleOpsPilotAssistant$.next();
    };

    public ngOnInit(): void {
        this._intercomService.shutdown();
        this.bootAnonymousSupportChat();

        this.subscribeToVisibilityStateChange( ( event: any ) => {
            const isVisible = event.target.visibilityState === 'visible';

            if ( isVisible ) {
                this._timePickerService.disabled( KsTimePickerArea.MINUTE, false );
                this._timePickerService.disabled( KsTimePickerArea.LIVE, false );

                const now = moment();

                if ( now.diff( this.visibilityChangeTime, 'minutes' ) >= 5 ) {
                    const routeHasDisableFlag: boolean = this.checkForRouteWithDisabledFlag();

                    if ( !routeHasDisableFlag ) {
                        this._timePickerService.refresh();
                    }
                }
            } else {
                this.visibilityChangeTime = moment();

                this._timePickerService.disabled( KsTimePickerArea.MINUTE, true );
                this._timePickerService.disabled( KsTimePickerArea.LIVE, true );
            }
        } );

        this._showTimePickerService.showTimePickerSubject
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( value => {
                this.showTimePicker = !this.accountLocked && value;
            } ); // If either is false, hide timepicker

        this._opspilotService.opsPilotAssistantEvent$
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( value ) => {
                if ( value === 'resize' || value === 'open' ) {
                    this.isOpsPilotOpen = true;
                } else if ( value === 'close' ) {
                    this.isOpsPilotOpen = false;
                }
            } );
    }

    public ngOnDestroy(): void {
        super.onDestroy();
    }

    public toggleChat(): void {
        this._intercomService.toggleSupportChat();
    }

    public openInstallationGuide(): void {
        if ( !this.loggedIn ) {
            return;
        }

        if ( this.isNotCloud ) {
            this.isShowingDocs = true;
            return;
        }

        if ( this.installationGuideTemplate && !this.installationGuideDialog ) {
            this.installationGuideDialog = this.dialogService.create( {
                template        : this.installationGuideTemplate,
                closeButton     : false,
                closeOnEscape   : false,
                closeOnBlur     : false,
                clickableOverlay: false
            } );
        }
    }

    public closeInstallationGuide(): void {
        if ( this.installationGuideDialog ) {
            this.dialogService.destroy( this.installationGuideDialog );

            this.router.navigate( [], {
                queryParams: {
                    ...this.route.snapshot.queryParams,
                    show_onboard_dialog: undefined
                }
            } );
        }

        this.installationGuideDialog = null;
    }

    /**
     * Redirect the user to the given url
     */
    public redirect( url: string ): void {
        this.router.navigateByUrl( url );
        const chat: any = ( window as any ).Intercom;
        // Skip if chat is not there
        if ( chat ) {
            chat( 'update', { last_request_at: moment().unix() } );
        }
    }

    public restoreSettings(): void {
        this.restoreTheme();

        if ( this.has( 'timePicker.pageRefreshPeriod' ) ) {
            try {
                this._timePickerService.pageRefreshPeriod = this.userSettings.timePicker.pageRefreshPeriod;
            } catch ( e ) {
                console.error( e );
            }
        }

        if ( this.has( 'timePicker.start' ) && this.has( 'timePicker.end' ) ) {
            try {
                this._timePickerService.timeframe = new KsTimePickerDataObject( {
                    start        : this.userSettings.timePicker.start,
                    end          : this.userSettings.timePicker.end,
                    isCurrentTime: this.userSettings.timePicker.currentTime,
                    timezone     : this.userSettings.timePicker.timezone
                } );
            } catch ( e ) {
                console.error( e );

                if ( e instanceof TimePickerDVMException ) {
                    this.notificationService.create( {
                        styleType: 'info',
                        body     : 'Your selected time frame was outside of the account data retention period and has been reset to <i>"Last 30 mins"</i>',
                        title    : 'Time frame reset',
                        timeout  : false
                    } );

                    return;
                }

                this._timePickerService.timeframe = new KsTimePickerDataObject( {
                    start        : this._timePickerService.timeframe.start,
                    end          : this._timePickerService.timeframe.end,
                    isCurrentTime: true
                } );
            }
        }

        if ( this.has( 'timePicker.timezone' ) ) {
            try {
                moment.tz.setDefault( this.userSettings.timePicker.timezone );
            } catch ( e ) {
                console.error( e );
                moment.tz.setDefault( moment.tz.guess() );
            }
        } else {
            moment.tz.setDefault( moment.tz.guess() );
        }
    }

    public checkActiveLink(): void {
        const location = window.location.pathname.split( '/' );
        let active = location[1].toLowerCase();

        // Special logic for grafana i-framed pages
        if ( active === 'g' ) { // All grafana pages start with /g/
            active = location[2].toLowerCase();
            if ( active === 'd' ) { // if active url is now d, it's a dashboard, set dashboards as active
                active = 'dashboards';
            }
        }

        this.centreToolbarMenu.map( ( i: any ) => i.active = i?.route?.toLowerCase() === active );
        this.leftToolbarMenu.map( ( i: any ) => i.active = i?.route?.toLowerCase() === active );
    }

    public getLastChildRoute( route: any ): any {
        if ( !route.children.length ) {
            return route;
        }

        return this.getLastChildRoute( route.children[0] );
    }

    public refresh(): void {
        window.location.reload();
    }

    public giveFeedback(): void {
        this._intercomService.openSupportChatWithMessager();
    }

    public openChangeLogDrawer(): void {
        this.openHotkeys = false;
        this._drawerManager.create( {
            direction   : 'left',
            hideCloseBtn: true,
            template    : this.changeLogViewer,
            size        : KsDrawerSize.MEDIUM,
            cssClass    : 'dark'
        } )
            .instance.close.subscribe( () => {
                this.unreadCount = 0;
            } );
    }

    public showDocs(): void {
        this.openHotkeys = false;
        this.isShowingDocs = !this.isShowingDocs;
    }

    public onImpersonationTimerComplete(): void {
        this.impersonationDialog = this.ksDialogService.create( {
            title      : 'Impersonation Session Expired',
            template   : this.impersonationDialogTmpl,
            closeButton: false
        } );

        this.impersonationDialog.instance.close
            .pipe( take( 1 ) )
            .subscribe( async( isUsingSession: boolean ) => {
                if ( isUsingSession ) {
                    this.resetImpersonationTimer.next( undefined );
                } else {
                    await this._authService.logout( true );
                }
            } );
    }

    public handleOpspilotUpgradeClose(): void {
        this.mainFlexOffset = 0;
    }

    public openStatusPage() {
        window.open( 'http://statuspage.fusionreactor.io/', '_blank' );
    }

    private listenForFlagChanges(): void {
        this._ldService.flagChange.pipe( takeUntil( this.ngUnsubscribe ), filter( d => !!d ) )
            .subscribe( () => {
                const isSLSEnabled: boolean = this._ldService.getFeature( 'fusion-reactor-cloud-ui-use-serverless', false );

                if ( isSLSEnabled ) {
                    const slsServices: string[] = this._ldService.getFeature( 'fusion-reactor-cloud-ui-serverless-services',
                        [ false ] );
                    if ( slsServices.includes( 'coms-metric-service' ) ) {
                        const slsUrl: string = environment.urls.api.coms.metric.replace(
                            environment.urls.base,
                            environment.urls.sls_base
                        );

                        this._metricsService.setLiveDataUrl( slsUrl );
                        this._graphProfileService.setUrl( slsUrl );
                    }
                }
            } );
    }

    /**
     * Boot the support chat widget
     * @default false
     */
    private bootAnonymousSupportChat(): void {
        const chat: any = ( window as any ).Intercom;

        // Skip if chat is not initialized yet
        if ( !chat ) {
            return;
        }

        chat( 'boot', {
            app_id        : environment.chat.app_id,
            current_page  : this.router.url,
            traffic_source: this.trafficSource
        } );
    }

    private bootLoggedInSupportChat(): void {
        const chat: any = ( window as any ).Intercom;

        // Skip if chat is not initialized yet
        if ( !chat ) {
            return;
        }

        const options = {
            app_id        : environment.chat.app_id,
            current_page  : this.router.url,
            traffic_source: this.trafficSource
        };

        if ( !this.loggedIn ) {
            chat( 'boot', options );
        } else {
            chat( 'boot', {
                ...options,
                firstName     : this._authService.loggedInUser.first,
                lastName      : this._authService.loggedInUser.last,
                name          : this._authService.loggedInUser.name,
                email         : this._authService.loggedInUser.email,
                'License Key' : this._authService.loggedInUser.account.license,
                'Company name': this._authService.loggedInUser.account.name,
                plan          : this._authService.loggedInUser.account.plan,
                company       : {
                    company_id: this._authService.loggedInUser.account.id,
                    name      : this._authService.loggedInUser.account.name
                }
            } );
        }
    }

    /**
     * Listen to the relevant banner triggers and ensure the flex offset
     * is recalculated when changes occur that can cause banners to
     * display or be removed
     */
    private listenAndControlFlexOffset(): void {
        this._authService.accountUpdateEvent.pipe( startWith( null as string ) )
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( () => {
                let newFlexOffset: number = 0; // default

                if ( this.banner && this.banner.bannerObj.name ) {
                    newFlexOffset += 47;
                }

                this.mainFlexOffset = newFlexOffset;
            } );
    }

    public closeBanner() {
        this.mainFlexOffset = 0;
    }

    /**
     * Listen for account state changes to ensure the correct banner can be shown at all times
     */
    private listenForAccountUpdates(): void {
        this._authService.accountUpdateEvent
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( account: Billing ) => {
                this.setBannerAccount( account );
            } );
    }

    /**
     * Confusingly named, somehow account becomes Billing which is used for the banner
     * @param billing
     * @private
     */
    private setBannerAccount( billing: Billing ): void {
        this.banner = billing;

        if ( this.statusBannerButtons.length ) {
            this.statusBannerButtons[0].route = this.banner.bannerObj.route;
            this.statusBannerButtons[0].input = this.banner.bannerObj.input;
        } else {
            this.statusBannerButtons.push( {
                route      : this.banner.bannerObj.route,
                input      : this.banner.bannerObj.input,
                buttonClass: 'btn-thing'
            } );
        }

        // Very hacky way of making the message different for users who can't access the billing area for Stripe Cloud accounts.
        if ( this.banner.bannerObj.name.endsWith( 'Please purchase FusionReactor Cloud to continue using this service' ) ) {
            if ( !this.permissionService.has( [ 'billing.all' ] ) ) {
                this.statusBannerButtons = [];
                this.banner.bannerObj.name =
                    'FusionReactor Cloud Expired - An administrator for this account must renew the subscription to continue ' +
                    'using FusionReactor Cloud.';
            }
        }
    }

    private listenForLogout(): void {
        this._authService.logOutEvent
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( () => {
                this.loggedIn = false;
                this.showLoader = false;
                this.banner = null;
                this._timePickerService.disabled( true );
                this.permissionService.userPermissions = [];
                this._metricsService.reset();
                this.mainFlexOffset = 0;

                // Reset user with LaunchDarkly
                this._ldService.changeUser( null ).then();

                // Reset intercom to no user
                this._intercomService.shutdown();
                this.bootAnonymousSupportChat();

                // Reset opspilot
                this._opspilotService.sendMessage( { type: 'opspilot-host.logout' } );
            } );
    }

    /**
     * Listen for changed to the timePicker
     */
    private listenForTimePicker(): void {
        Highcharts.setOptions( {
            global: {
                getTimezoneOffset: ( timestamp: any ) => -moment.tz( timestamp, this._timePickerService.timezone.tz() )
                    .utcOffset(),
                useUTC: true
            } as any
        } );

        this._timePickerService.timezoneUpdated
            .subscribe( ( a: any ) => {
                moment.tz.setDefault( a.tz() );

                Highcharts.setOptions( {
                    global: {
                        getTimezoneOffset: ( timestamp: any ) => -moment.tz( timestamp, a.tz() )
                            .utcOffset(),
                        useUTC: true
                    } as any
                } );

                const newTimeObj = {
                    start      : this._timePickerService.timeframe.start,
                    end        : this._timePickerService.timeframe.end,
                    currentTime: this._timePickerService.timeframe.isCurrentTime,
                    timezone   : a.tz()
                };

                if ( this._authService.loggedInUser ) {
                    this.saveUserSettings( newTimeObj, 'timePicker' );
                }
            } );

        this.subscribeToUserTimePicker( ( timeObj: KsTimePickerDataObject ) => {
            const shouldSave: boolean = !this._timePickerService.timeframe.meta || this._timePickerService.timeframe.meta?.no_refresh === false;

            const newTimeObj = {
                start      : timeObj.start,
                end        : timeObj.end,
                currentTime: timeObj.isCurrentTime,
                timezone   : timeObj.timezone.tz()
            };

            if ( this._authService.loggedInUser && shouldSave ) {
                this.saveUserSettings( newTimeObj, 'timePicker' );
            }
        } );

        this._timePickerService.pageRefreshUpdatedByUser
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( minutes: number ) => {
                const shouldSave: boolean = !this._timePickerService.timeframe.meta || this._timePickerService.timeframe.meta?.no_refresh === false;

                if ( this._authService.loggedInUser && shouldSave ) {
                    this.saveUserSettings( { pageRefreshPeriod: minutes }, 'timePicker' );
                }
            } );
    }

    /**
     * Take action on a login event
     */
    private listenForLogin(): void {
        this._authService.loginEvent
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( () => {
                this.permissionService.userPermissions = this._authService
                    .loggedInUser.group.permissions.map( ( p: IUserGroupPermission ) => p.action );

                this.showLoader = false;
                this.loggedIn = true;

                this.bootLoggedInSupportChat();
                this._intercomService.toggleSupportChat();
                this.accountId = this._authService.loggedInUser.account.id;
                this.isDevMode = this._ldService.getFeature( 'dev-mode', false );
                this.isOpsPilotExperimentalFeature = this._ldService.getFeature( 'allow-ops-pilot-experimental-features', false );
                this.isNotCloud = !evaluateCloudPermission( this.permissionService, this._authService.loggedInUser.account );

                this.setBannerAccount( new Billing( this._authService.loggedInUser.account.toJson() ) );

                if ( this.banner && this.banner.bannerObj.name ) {
                    this.mainFlexOffset += 47;
                }

                this.checkNotBilling();
                this.buildNav();
                if ( this._ldService.getFeature( 'allow-ops-pilot', false ) ) {
                    this.opAssistantHotkeyActive = true;
                    this._hotkeyService.add( 'o', {
                        callback   : () => this.showOpspilotSideMenu(),
                        description: 'Toggle OpsPilot Assistant',
                        component  : this
                    } );
                }

                if ( this.isNotCloud ) {
                    this._timePickerService.disabled( true );
                } else {
                    this._timePickerService.disabled( false );
                }

                this._metricsService.setAuthToken( this._authService.priorityToken );
                this.entityService.setAuthToken( this._authService.priorityToken );
                this._graphProfileService.setAuthToken( this._authService.priorityToken );

                if ( this._authService.loggedInUser.impersonating ) {
                    setTimeout( () => this.resetImpersonationTimer.next( undefined ) );
                    this.mainFlexOffset += 47;
                }

                this._timePickerService.liveModePeriod = this._ldService.getFeature( 'fusion-reactor-cloud-live-mode-period', 1 );

                this._timePickerService.setEarliest( 90 );

                this.restoreSettings();

                this._changeLogService.getChangeLogs( true )
                    .pipe( map( ( changeLogList: ChangeLog[] ) => {
                        changeLogList.forEach(
                            ( changeLog: ChangeLog ) => changeLog.setInitialSeenState( this._authService.loggedInUser.id ) );

                        return changeLogList;
                    } ) )
                    .subscribe( ( changeLogs: ChangeLog[] ) => {
                        this.unreadCount = changeLogs.filter( cl => !cl.seen ).length;
                    } );
            } );
    }

    private listenForOpsPilotTrialUpgradeNotification(): void {
        this._opspilotService.showPopup$.subscribe( () => {
            this.showOpsPilotUpgrade = true;
            this.mainFlexOffset = 47;
        } );
    }

    /**
     * Restore the theme
     * if loggedIn then use the user configured theme
     * if not logged in then restore from cookie
     */
    private restoreTheme(): void {
        if ( this.loggedIn && this.has( 'ui.theme' ) ) {
            this.setActiveTheme( this.userSettings.ui.theme );
        } else {
            const theme = this.cookieService.get( 'theme' );

            this.setActiveTheme( theme );
        }
    }

    /**
     * builds the navigation toolbar based on whether the nav items should be disabled or not.
     */
    private buildNav(): void {
        this.leftToolbarMenu = [
            { /* if I dont do this we get bugs */ }
        ];

        this.centreToolbarMenu = [
            {
                label   : 'Overview',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'overview',
                click   : () => {
                    this.router.navigate( [ '/overview' ] );
                }
            }, {
                label   : 'Servers',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'servers',
                click   : () => {
                    this.router.navigate( [ '/servers', 'detailed' ], { queryParams: { status: 'online' } } );
                }
            }, {
                label   : 'Applications',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'applications',
                click   : () => {
                    this.router.navigate( [ '/applications' ] );
                }
            }, {
                label   : 'Alerting',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'alerting',
                click   : () => {
                    this.router.navigate( [ '/alerting/alerts' ] );
                }
            }, {
                label   : 'Logging',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'logging',
                click   : () => {
                    this.router.navigate( [ '/logging' ] );
                }
            }, {
                label   : 'Explore',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'explore',
                click   : () => {
                    this.router.navigate( [ '/g/explore' ] );
                }
            }, {
                label   : 'Dashboards',
                disabled: this.accountLocked || this.isNotCloud,
                route   : 'dashboards',
                click   : () => {
                    this.router.navigate( [ '/g/dashboards' ] );
                }
            },
            {
                label: 'On-Premise',
                route: 'on-premise',
                click: () => {
                    this.router.navigate( [ '/on-premise' ] );
                }
            }
        ];

        if ( this.isOpsPilotExperimentalFeature ) {
            this.centreToolbarMenu.push(
                {
                    label   : 'OpsPilot',
                    disabled: this.accountLocked || this.isNotCloud,
                    click   : () => {
                        window.open( environment.opspilot.ui, '_blank' );
                    }
                } )
        }

        setTimeout( () => {
            const img: any = document.querySelector( 'img[data-avatar]' );
            img.src =
                this._authService.loggedInUser.avatar
                    ? this._authService.loggedInUser.avatar
                    : this._utilityService.LetterAvatar(
                        this._authService.loggedInUser.name, 50 );
        } );
    }

    /**
     * This handles checking that the account status is not expired and will redirect the user to billing.
     * If the user is already at billing it will disable all the navigation on the website.
     */
    private checkNotBilling(): void {
        this.accountLocked = this.banner?.locked;
        if ( this.router.url !== '/account/billing/purchase' && this.accountLocked && this.router.url !== '/auth/login' ) {
            this.router.navigateByUrl( '/account/billing/purchase' );
        }
    }

    /**
     * Listen for routing events
     * Configure showDocs url
     * Configure loading bar
     * Configure previousUrl incase of 401 redirect
     */
    private listenForRouting(): void {
        this.router.events
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( event: RouterEvent ) => {
                if ( event instanceof GuardsCheckStart || event instanceof ResolveStart ) {
                    if ( this.ksLoadingService.progress !== 100 ) {
                        this.ksLoadingService.progress = 25;
                    }

                    return;
                }

                if ( ( event instanceof GuardsCheckEnd && !event.shouldActivate ) || event instanceof ResolveEnd ) {
                    if ( this.ksLoadingService.progress !== 100 ) {
                        this.ksLoadingService.progress = 50;
                    }

                    return;
                }

                if ( event instanceof ResolveStart ) {
                    if ( this.ksLoadingService.progress !== 100 ) {
                        this.ksLoadingService.progress = 75;
                    }

                    return;
                }

                if ( event instanceof ResolveEnd ) {
                    if ( this.ksLoadingService.progress !== 100 ) {
                        this.ksLoadingService.progress = 90;
                    }

                    return;
                }

                if ( event instanceof NavigationStart ) {
                    this.ksLoadingService.start();

                    this._drawerManager.destroyAll();

                    if ( event.url.search( 'auth' ) === 1 ) {
                        this.showLoader = false;
                    }
                } else if ( event instanceof NavigationCancel ) {
                    if ( this.ksLoadingService.progress >= 0 ) {
                        this.ksLoadingService.complete( true );
                    }
                } else if ( event instanceof NavigationEnd ) {
                    this.checkActiveLink();

                    const route = this.getLastChildRoute( this.route );

                    const liveModeAllowed = this._ldService.getFeature( 'fusion-reactor-cloud-live-mode', true );

                    if ( liveModeAllowed === true ) {
                        if ( route.snapshot.data.componentHandlesLivemode || route.snapshot.parent.data.componentHandlesLivemode ) {
                        /**
                         * The handling of enabling/disabling live mode when inside this route
                         * is up to the component to handle. This means we don't accidentally disable
                         * live mode while navigating around.
                         *
                         * Parent data is checked for transaction details drawer as apparently
                         * child router outlets can't have data {}
                         */

                            if ( this.ksLoadingService.progress >= 0 ) {
                                this.ksLoadingService.complete( true );
                            }

                            return;
                        }

                        this._timePickerService.liveModeUIEnabled =
                        route.snapshot.data.enableLiveMode && route.snapshot.data.enableLiveModeUI;
                        this._timePickerService.liveModeEnabled = route.snapshot.data.enableLiveMode;
                        this._timePickerService.disabled( KsTimePickerArea.LIVE, !route.snapshot.data.enableTimePicker );
                    } else {
                        this._timePickerService.liveModeUIEnabled = false;
                        this._timePickerService.liveModeEnabled = false;

                        this._timePickerService.disabled( KsTimePickerArea.LIVE, true );
                    }

                    this._timePickerService.disabled( KsTimePickerArea.PICKER, !route.snapshot.data.enableTimePicker );

                    const elements: string[] = this.router.url.split( '/' );
                    let page: string = `guides/${ elements[1] }`;

                    if ( elements.includes( 'billing' ) ) {
                        page = 'billing/overview';
                    }

                    if ( this.isNotCloud ) {
                        this.docsSafeUrl =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            `${ environment.urls.docs }/Getting-started/GSOnpremsetup/` );
                    } else if ( this.allowedUrls.includes( page ) ) {
                        this.docsSafeUrl =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            `${ environment.urls.docs }/Cloud/${ page }` );
                    } else {
                        this.docsSafeUrl =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            `${ environment.urls.docs }/Getting-started/GSCloudsetup/` );
                    }

                    if ( this.ksLoadingService.progress >= 0 ) {
                        this.ksLoadingService.complete( true );
                    }
                }
            } );
    }

    private addHotKeys(): void {
        this._hotkeyService.add( 'h', {
            callback   : () => this.showDocs(),
            description: 'Show documentation',
            component  : this
        } );

        this._hotkeyService.add( 'shift+t', {
            callback   : () => this.openTimePickerDrawer(),
            description: 'Open the time picker controls',
            component  : this
        } );

        this._hotkeyService.add( '[', {
            callback   : () => this.timePickerStepLeft(),
            description: 'Step timeframe backwards',
            component  : this
        } );

        this._hotkeyService.add( ']', {
            callback   : () => this.timePickerStepRight(),
            description: 'Step timeframe forwards',
            component  : this
        } );

        this._hotkeyService.add( 'r', {
            callback   : () => this.timePickerRefreshTimeframe(),
            description: 'Refresh current timeframe',
            component  : this
        } );

        this._hotkeyService.add( 'l', {
            callback   : () => this.timePickerLiveMode(),
            description: 'Toggle live mode',
            component  : this
        } );

        this._hotkeyService.add( 'c', {
            callback   : () => this._intercomService.toggleSupportChat(),
            description: 'Toggle live support chat',
            component  : this
        } );

        this._hotkeyService.add( 'q', {
            callback: () => {
                const theme: string = this.ksThemeService.cycleTheme();
                this.cookieService.set( 'theme', theme, 90, '/', environment.cookies.domain, environment.cookies.secure, 'Lax' );

                if ( this.loggedIn ) {
                    this.userSettings.ui.theme = theme;
                    this.saveUserSettings( this.userSettings.ui, 'ui' );
                }
            },
            description: 'Toggle theme',
            component  : this
        } );
    }

    private openTimePickerDrawer(): void {
        this.openHotkeys = false;
        this._timePickerService.openDrawer();
    }

    /**
     * Apply a theme or toggle if none is provided
     * @param theme string
     */
    private setActiveTheme( theme: string = null ): void {
        const themeToApply = theme || 'night';

        this.ksThemeService.changeTheme( themeToApply );

        if ( this.loggedIn ) {
            this.userSettings.ui.theme = themeToApply;
        }

        this.cookieService.set( 'theme', themeToApply, 90, '/', environment.cookies.domain, environment.cookies.secure, 'Lax' );
    }

    private timePickerStepLeft(): void {
        this._timePickerService.stepLeft();
    }

    private timePickerStepRight(): void {
        this._timePickerService.stepRight();
    }

    private timePickerRefreshTimeframe(): void {
        this._timePickerService.refresh();
    }

    private timePickerLiveMode(): void {
        if ( this._timePickerService.liveModeUIEnabled ) {
            this._timePickerService.toggleLiveMode();
        }
    }

    private checkForRouteWithDisabledFlag( route?: any ): boolean {
        let response: boolean = false;

        const routeToCheck: ActivatedRoute = route || this.router.routerState.root;

        response = routeToCheck.children.reduce( ( disabled: boolean, r: ActivatedRoute ) => {
            // early escape clause
            if ( disabled ) {
                return disabled;
            }

            if ( r.snapshot.data.disabledTabReturnRefresh !== undefined ) {
                return r.snapshot.data.disabledTabReturnRefresh === true;
            }

            return this.checkForRouteWithDisabledFlag( r );
        }, response );

        return response;
    }

    public copyAccountId(): void {
        const copyData = `${ this.accountId }\n`;

        this.clipboard.copy( copyData );

        this.notificationService.create( {
            styleType: 'success',
            body     : 'Account ID has been copied to clipboard',
            timeout  : 3000
        } );
    }
}
