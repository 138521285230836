import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpsPilotComponent } from './ops-pilot.component';
import { FlexModule } from '@angular/flex-layout';
import { OpspilotService } from '../core/services/opspilot.service';

@NgModule( {
    declarations: [
        OpsPilotComponent
    ],
    exports: [
        OpsPilotComponent
    ],
    imports: [
        CommonModule,
        FlexModule
    ],
    providers: [ OpspilotService ]
} )
export class OpsPilotModule { }
