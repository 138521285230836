import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ShowTimePickerService } from '../core/services/show-time-picker.service';
import { CookieService } from 'ngx-cookie-service';
import { LaunchDarklyService } from '../core/services/launchdarkly.service';
import { GrafanaService } from '../core/services/grafana.service';

@Component( {
    selector   : 'app-explorer',
    templateUrl: './graf-explorer.component.html',
    styleUrls  : [ './graf-explorer.component.scss' ]
} )
export class GrafExplorerComponent implements OnInit, OnDestroy {
    public showSubNav = false;

    @ViewChild( 'GrafanaElement', { static: true } ) GrafanaElement: ElementRef;
    public iframeUrl: SafeResourceUrl;

    @Input()
    protected defaultGrafanaPath = this.route.snapshot.data.grafanaPath;

    constructor( protected readonly _showTimePickerService: ShowTimePickerService,
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        protected readonly cookieService: CookieService,
        protected readonly ldService: LaunchDarklyService,
        private readonly grafanaService: GrafanaService,
        protected readonly sanitizer: DomSanitizer
    ) {
        this.showSubNav = this.route.snapshot.data.showSubNav || false;
    }

    public ngOnInit() {
        this._showTimePickerService.showTimePicker = false;

        // Shortcuts in grafana will redirect back to cloud, causing the i-frame to contain Cloud
        // To prevent this, we detect if we're inside an i-frame
        if ( window?.parent.location.href !== window?.location.href ) { // else redirect to explore.fusionreactor.io so the content inside the i-frame is just grafana
            window.location.href = this.constructGrafanaHost( window.location.pathname );
        }
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl( this.constructGrafanaHost( this.defaultGrafanaPath ) );
    }

    ngAfterViewInit() {
        this.grafanaService.GrafanaFrame = this.GrafanaElement.nativeElement;
    }

    public ngOnDestroy(): void {
        this.grafanaService.GrafanaFrame = undefined;
        this._showTimePickerService.showTimePicker = true;
    }

    protected constructGrafanaHost( grafanaPath: string ): string {
        const envUrl = this.ldService.getFeature( 'fr-cloud-grafana-explore-2-toggle', true )
            ? environment.urls.grafana
            : environment.urls.grafana2;
        const grafanaHost = new URL( envUrl );
        grafanaHost.pathname = grafanaPath;

        const theme: 'dark' | 'light' = this.cookieService.get( 'theme' ) === 'night' ? 'dark' : 'light';
        grafanaHost.searchParams.append( 'theme', theme );
        for ( const [ key, value ] of Object.entries( this.route.snapshot.queryParams ) ) {
            if ( key !== 'kiosk' ) {
                grafanaHost.searchParams.append( key, value );
            }
        }
        return grafanaHost.toString();
    }
}
