import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'tableColumnSortAlphabetical'
} )
export class TableColumnSortAlphabeticalPipe<T extends Record<string, string> & any> implements PipeTransform {
    transform( value: T[], sortKey: string, sortAscending: boolean = false ): T[] {
        if ( !sortKey ) {
            return value;
        }

        const array = [ ...value ];

        const collator = new Intl.Collator( undefined, { sensitivity: 'base' } );

        return array.sort( ( a: T, b: T ) => {
            return sortAscending
                ? collator.compare( b[sortKey], a[sortKey] )
                : collator.compare( a[sortKey], b[sortKey] );
        } );
    }
}
