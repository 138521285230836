import { KsTimePickerDataObject, KsTimePickerService } from '@intergral/kaleidoscope';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import { UserSettings } from '../models/UserSettings';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

export abstract class SharedComponent {
    protected apiHandle: Subscription;
    protected ngUnsubscribe: Subject<any> = new Subject();
    private userSettingsTimeout: any;

    protected constructor(
        // tslint:disable-next-line:variable-name
        private readonly __authService: AuthService,
        // tslint:disable-next-line:variable-name
        private readonly __userService: UserService,
        // tslint:disable-next-line:variable-name
        private readonly __timePickerService: KsTimePickerService
    ) {
    }

    public get userSettings(): UserSettings {
        return this.__authService.loggedInUser.settings;
    }

    public onDestroy(): void {
        clearTimeout( this.userSettingsTimeout );

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        if ( this.apiHandle ) {
            this.apiHandle.unsubscribe();
        }
    }

    /**
     * disable the visibility of the support chat bubble
     */
    public hideSupportChat(): void {
        const widget: any = document.querySelectorAll( '.intercom-app,.intercom-launcher' )[0];

        if ( widget ) {
            widget.style.display = 'none';
        }
    }

    /**
     * Subscribe to the page visibility events to allow
     * the component to take action when the page becomes
     * hidden or visible
     * @param callback Function
     */
    public subscribeToVisibilityStateChange( callback: any ): void {
        fromEvent( document, 'visibilitychange' )
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( event: any ) => {
                callback( event );
            }, ( error ) => {
                console.error( 'Error handling visibility state change, ', error );
            } );
    }

    public subscribeToTimePicker(
        callback: any,
        skipAmount = 0
    ): void {
        this.__timePickerService.timeframeUpdated
            .pipe( skip( skipAmount ),
                debounceTime( 200 ),
                takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( timeObj: KsTimePickerDataObject ) => {
                callback( timeObj );
            } );
    }

    public subscribeToTimePickerLiveTick(
        callback: any,
        skipNum = 0
    ): void {
        this.__timePickerService.liveModeTick
            .pipe( skip( skipNum ),
                takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( timeObj: KsTimePickerDataObject ) => {
                callback( timeObj );
            } );
    }

    public subscribeToTimePickerPageRefreshTick(
        callback: any,
        skipNum = 0
    ): void {
        this.__timePickerService.pageRefreshTick
            .pipe( skip( skipNum ),
                takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( minutes: number ) => {
                callback( minutes );
            } );
    }

    public subscribeToUserTimePicker(
        callback: any,
        skipNum = 0
    ): void {
        this.__timePickerService.timeframeUpdatedByUser
            .pipe( skip( skipNum ),
                debounceTime( 200 ),
                takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( timeObj: KsTimePickerDataObject ) => {
                callback( timeObj );
            } );
    }

    /**
     * Send the user filter settings back to the DB
     */
    protected saveUserSettings(
        newSettings: any,
        key: string
    ): void {
        if ( !this.userSettings.isEnabled ) {
            return;
        }

        this.userSettings.update( newSettings, key );

        clearTimeout( this.userSettingsTimeout );

        this.userSettingsTimeout = setTimeout( () => {
            this.__userService.updateUserSettings()
                .subscribe();
        }, 3000 );
    }

    /**
     * Check if the logged in user has the setting present within their settings object
     * @param setting settings key
     */
    protected has( setting: string ): boolean {
        return this.userSettings.has( setting );
    }

    /**
     * Check to see if two filter objects are the same
     * @param current any
     * @param next any
     * @param fillNulls boolean replaces undefined with null
     */
    protected filterHasChanged( current: any, next: any, fillNulls: boolean = false ): boolean {
        const currentSorted = {};
        const nextSorted = {};

        Object.keys( current )
            .sort()
            .forEach( k => currentSorted[k] = !fillNulls ? current[k] : current[k] === undefined ? null : current[k] );

        Object.keys( next )
            .sort()
            .forEach( k => nextSorted[k] = !fillNulls ? next[k] : next[k] === undefined ? null : next[k] );

        return JSON.stringify( currentSorted ) !== JSON.stringify( nextSorted );
    }
}
