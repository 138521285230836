export class Session {
    private readonly _createdAt: number;
    private readonly _lastActive: number;
    private readonly _token: string;
    private readonly _isCurrent: boolean = false;

    constructor( private readonly data ) {
        this._createdAt = data.created_at;
        this._lastActive = data.last_active;
        this._token = data.token;
        this._isCurrent = !!data.isCurrent;

        this.data = null;
    }

    get id(): string {
        return this._token;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    get lastActive(): number {
        return this._lastActive;
    }

    get token(): string {
        return this._token;
    }

    get isCurrent(): boolean {
        return this._isCurrent;
    }
}
