import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AccountStatus } from '../models/AccountStatus';
import { AuthService } from '../services/auth.service';
import { evaluateOnPremPermission } from './evaluate-on-prem.permission';

/**
 * This guard is only intended for app.routing level pages. This is to block sections of cloud off from users who shouldn't have access.
 */
@Injectable( {
    providedIn: 'root'
} )
export class CloudNotExpiredGuard implements CanActivate {
    constructor(
      private readonly authService: AuthService,
      private readonly router: Router
    ) {
    }

    canActivate(): boolean | UrlTree {
        const account  = this.authService.loggedInUser?.account;
        if ( !account ) { // Reject until account details are loaded
            return false;
        }

        if ( account.status === AccountStatus.EXPIRED ) {
            // if there's on-prem premission
            if ( evaluateOnPremPermission( account ) ) {
                // Go to on-prem
                return this.router.createUrlTree( [ '/on-premise' ] );
            }
            // Redirect expired cloud-only accounts to cloud overview
            return this.router.createUrlTree( [ '/overview' ] );
        }
        // Pass the guard check and proceed as normal for valid cloud permissions
        return true;
    }
}
