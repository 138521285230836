import { Component, HostBinding, Input, OnInit } from '@angular/core';

/**
 * In order to know how to highlight the number
 * we need to convert this back to ms.
 */
export function formattedDurationToNumber( val: string | number ): number {
    if ( val === undefined || val === null ) {
        return 0;
    }

    if ( typeof val === 'number' ) {
        return val;
    }

    // If val is string, do the following
    if ( !Number.isNaN( +val ) ) {
        return +val;
    } else if ( val.includes( ' ' ) ) {
        const parts = val.split( ' ' );
        let n: number = Number.parseInt( parts[0].replace( ',', '' ), 10 );

        switch ( parts[1] ) {
            case 's':
                n *= 1000;
                break;
            case 'm':
                n *= 1000 * 1000;
                break;
            case 'h':
                n *= 1000 * 1000 * 1000;
                break;
            default: {
                console.warn( `Duration key ${ parts[1] } not recognised` );
            }
        }
        return n;
    }

    return +val; // we should assume it's a number.
}

@Component( {
    selector: 'app-transaction-duration',
    template: `
        {{value | ksDurationPipe }}{{value | ksDurationPipe:true}}
    `,
    styleUrls: [ './transaction-duration.component.scss' ]
} )
export class TransactionDurationComponent implements OnInit {
    @Input( 'value' )
    set _value( val ) {
        this.value = formattedDurationToNumber( val );
    }

    @Input() public warningThreshold = 8000;

    @Input() public dangerThreshold = 15000;

    public value: any;

    @HostBinding( 'class.danger-color' )
    get _warning(): boolean {
        return this.value >= this.warningThreshold;
    }

    @HostBinding( 'class.text-strong' )
    get _danger(): boolean {
        return this.value >= this.dangerThreshold;
    }

    public ngOnInit(): void {
        this.value = formattedDurationToNumber( this.value || 0 );
    }
}
