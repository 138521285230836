export class SavedStackTrace {
    private _counts: {
        blocked: number,
        deadlocked: number,
        runnable: number,
        running: number,
        waiting: number,
    };

    private readonly _users: { id: string, name: string }[];
    private readonly _description: string;
    private readonly _timestamp: number;
    private readonly _buttonStates: {
        unsave: string,
    };

    constructor( private readonly data: any ) {
        this.setCount( data.counts );
        this._users = data.users.map( ( u: any ) =>
            ( {
                id  : u._id,
                name: u.first
            } ) );
        this._description = data.description || '';
        this._timestamp = data.time;
        this._buttonStates = {
            unsave: ''
        };

        this.data = null;
    }

    private setCount( count: any ): void {
        this._counts = {
            blocked   : count.BLOCKED >= 0 ? count.BLOCKED : count.blocked,
            deadlocked: count.DEADLOCKED >= 0 ? count.DEADLOCKED : count.deadlocked,
            runnable  : count.RUNNABLE >= 0 ? count.RUNNABLE : count.runnable,
            running   : count.RUNNING >= 0 ? count.RUNNING : count.running,
            waiting   : count.WAITING >= 0 ? count.WAITING : count.waiting
        };
    }

    get counts(): { blocked: number; deadlocked: number; runnable: number; running: number; waiting: number } {
        return this._counts;
    }

    get timestamp(): number {
        return this._timestamp;
    }

    get description(): string {
        return this._description;
    }

    get users(): { id: string; name: string }[] {
        return this._users;
    }

    get buttonStates(): { unsave: string } {
        return this._buttonStates;
    }
}
