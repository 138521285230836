import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Event } from '@bugsnag/core';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';
import { LaunchDarklyService } from './core/services/launchdarkly.service';

@Injectable( {
    providedIn: 'root'
} )
export class CustomErrorHandler extends BugsnagErrorHandler implements ErrorHandler {
    constructor( private readonly injector: Injector, private readonly _ldService: LaunchDarklyService ) {
        super();

        Bugsnag.start( {
            apiKey              : environment.bugsnag.apiKey,
            releaseStage        : environment.bugsnag.releaseStage,
            enabledReleaseStages: [ 'production', 'staging', 'local' ],
            autoTrackSessions   : false,
            trackInlineScripts  : false,
            onError             : ( event: Event ) => {
                event.addMetadata( 'rawError', { error: event.originalError } );
            }
        } );
    }

    public handleError( error: any ): void {
        const realError = this.checkAndConvertError( error );

        if ( realError.message && this.isBlacklisted( realError.message ) ) {
            return;
        }

        this.addUser();

        if ( this.shouldReportError() ) {
            Bugsnag.notify( realError );
        }

        console.warn( realError, error );
    }

    // tslint:disable-next-line:prefer-function-over-method
    private checkAndConvertError( error: any ): Error {
        if ( Array.isArray( error ) ) {
            // tslint:disable-next-line:no-parameter-reassignment
            error = error[0];
        }

        if ( error instanceof Error ) {
            return error;
        }

        if ( error instanceof HttpErrorResponse ) {
            return new Error( error.message );
        }

        // Our custom Error
        if ( typeof error === 'object' ) {
            if ( error.errors ) {
                const message = typeof error.errors === 'object' ? error.errors[0] : error.errors;

                return new Error( message );
            }
        }

        return error;
    }

    private addUser(): void {
        const _authService: AuthService = this.injector.get( AuthService );

        if ( !_authService || !_authService.loggedInUser ) {
            return;
        }

        Bugsnag.setUser( _authService.loggedInUser.id, _authService.loggedInUser.email, _authService.loggedInUser.name );

        Bugsnag.addMetadata( 'user', {
            id     : _authService.loggedInUser.id,
            name   : _authService.loggedInUser.name,
            email  : _authService.loggedInUser.email,
            account: {
                id  : _authService.loggedInUser.account.id,
                name: _authService.loggedInUser.account.name
            }
        } );
    }

    // tslint:disable-next-line:prefer-function-over-method
    private shouldReportError(): boolean {
        const user: any = Bugsnag.getUser();

        if ( !user || !user.email ) {
            return true;
        }

        const isTestUser = user.email.startsWith( 'intergral.cloud.test.user' );

        return !isTestUser;
    }

    /**
     * Check if error message is blacklisted
     * @param error Error message
     */
    // tslint:disable-next-line:prefer-function-over-method
    private isBlacklisted( error: string ): boolean {
        if ( typeof error !== 'string' ) {
            return false;
        }

        const ldErrorBlacklist: string[] = this._ldService.getFeature( 'fusion-reactor-cloud-ui-error-blacklist', [] )

        return ldErrorBlacklist.includes( error );
    }
}
