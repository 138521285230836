import { NgModule } from '@angular/core';
import { HighlightDirective } from './highlight.directive';
import { PlanFeatureFlagElementDirective } from './plan-feature-flag-element.directive';
import { PlanFeatureFlagPageDirective } from './plan-feature-flag-page.directive';

@NgModule( {
    declarations: [
        PlanFeatureFlagPageDirective,
        PlanFeatureFlagElementDirective,
        HighlightDirective
    ],
    imports  : [],
    providers: [],
    exports  : [
        PlanFeatureFlagPageDirective,
        PlanFeatureFlagElementDirective,
        HighlightDirective
    ]
} )
export class SharedDirectivesCoreModule {
}
