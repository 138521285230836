import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableColumnSortNumericPipe } from './table-column-sort-numeric.pipe';
import { TableColumnSortAlphabeticalPipe } from './table-column-sort-alphabetical.pipe';
import { TableColumnSortDurationPipe } from './table-column-sort-duration.pipe';

const PIPES = [
    TableColumnSortNumericPipe,
    TableColumnSortAlphabeticalPipe,
    TableColumnSortDurationPipe
];

@NgModule( {
    declarations: PIPES,
    exports     : PIPES,
    imports     : [
        CommonModule
    ]
} )
export class TablePipesModule {
}
