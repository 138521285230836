export const environment = {
    production: true,
    urls      : {
        base    : 'https://api.staging.fusionreactor.io',
        sls_base: 'https://api.staging.fusionreactor.io',
        grafana : 'https://explore.staging.fusionreactor.io/',
        grafana2: 'https://explore2.staging.fusionreactor.io/',
        api     : {
            metrics       : 'https://api.staging.fusionreactor.io/metrics/opentsdb',
            logging       : 'https://api.staging.fusionreactor.io/logs',
            prometheus    : 'https://api.staging.fusionreactor.io/metrics-api-query/',
            prometheus_old: 'https://api.staging.fusionreactor.io/metrics/',
            spans         : 'https://api.staging.fusionreactor.io/span-query-service/',
            ui_tunnel     : 'https://api.staging.fusionreactor.io/fr-ui-ws-tunnel/',
            coms          : {
                log         : 'https://api.staging.fusionreactor.io/coms-log-service/',
                account     : 'https://api.staging.fusionreactor.io/coms-account-service/',
                notification: 'https://api.staging.fusionreactor.io/coms-notification-service/',
                threadtrace : 'https://api.staging.fusionreactor.io/coms-threadtrace-service/',
                profiler    : 'https://api.staging.fusionreactor.io/coms-profiler-service/',
                auth        : 'https://api.staging.fusionreactor.io/coms-auth-service/',
                instance    : 'https://api.staging.fusionreactor.io/coms-instance-service/',
                alerting    : 'https://api.staging.fusionreactor.io/coms-alerting-service/',
                alertmanager: 'https://api.staging.fusionreactor.io/coms-alertmanager-service/',
                transactions: 'https://api.staging.fusionreactor.io/coms-transaction-service/',
                application : 'https://api.staging.fusionreactor.io/coms-application-service/',
                metric      : 'https://api.staging.fusionreactor.io/coms-metric-service/',
                changelog   : 'https://api.staging.fusionreactor.io/coms-changelog-service/',
                stripe      : 'https://api.staging.fusionreactor.io/coms-stripe-billing-service/',
                admin       : 'https://api.staging.fusionreactor.io/coms-admin-service/',
                license     : 'https://api.staging.fusionreactor.io/coms-license-service/'

            },
            cdms: {
                apikey    : 'https://api.staging.fusionreactor.io/apikey/',
                clients_go: 'https://api.staging.fusionreactor.io/clientsgo/',
                groups    : 'https://api.staging.fusionreactor.io/group/',
                uls       : 'https://api.staging.fusionreactor.io/licensing-api/'
            }
        },
        docs: 'https://docs.fusionreactor.io',
        app : 'https://app.staging.fusionreactor.io'
    },
    launchdarkly: {
        apiKey: '6059aabb1d47040c103478ef'
    },
    bugsnag: {
        apiKey      : 'a3695711632c40fb913b8d716a66dc44',
        releaseStage: 'staging'
    },
    cookies: {
        domain: '.staging.fusionreactor.io',
        secure: true,
        prefix: 'fr-staging_'
    },
    liveMode: {
        wrap: 60
    },
    emails: {
        feedback: 'support@fusionreactor.io'
    },
    google: {
        client_id: '791431594952-e03fmhtogfrk8p42c54m1f120f8n79j4.apps.googleusercontent.com'
    },
    slack: {
        client_id: '16916675153.7185720562916'
    },
    chat: {
        app_id: 'nydlp6wj'
    },
    opspilot: {
        assistant: 'https://api.staging.fusionreactor.io/opspilot-assistant/conversation/blank',
        ui       : 'https://opspilot.staging.fusionreactor.io/'
    },
    alertingLabels: {
        prefix      : '__fr',
        separator   : '_',
        subscription: 'subscriptionId'
    },
    grafusion: {
        dashboardsDashboardId: 'fr-dashboards/fusionreactor-dashboards',
        logDashboardsId      : 'fr-log-dashboards/log-dashboards',
        logEventsId          : 'fr-log-browser/log-browser'
    }
};
