import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable( {
    providedIn: 'root'
} )
export class AuthGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly _authService: AuthService
    ) {
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if ( !this._authService.loggedIn ) {
            this._authService.previousUrl.url = state.url;
        }

        return this._authService.createSession();
    }
}
