import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LaunchDarklyService } from '../services/launchdarkly.service';

const SERVERLESS_SERVICES: string[] = [
    'coms-notification-service',
    'coms-profiler-service',
    'coms-instance-service',
    'coms-changelog-service',
    'coms-application-service',
    'coms-transaction-service',
    'coms-log-service',
    'coms-metric-service',
    'coms-threadtrace-service',
    'coms-alerting-service'
];

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    constructor( private readonly _ldService: LaunchDarklyService ) {
    }

    public intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        const changedReq = req.clone( { url: this.processURL( req.url ) } );

        return next.handle( changedReq );
    }

    /**
     * Handle the URL provided and replace the base domain with 'sls'
     * in the event is a sls supported service
     *
     * @param url The url to process
     */
    private processURL( url: string ): string {
        const shouldUseSls = this._ldService.getFeature( 'fusion-reactor-cloud-ui-use-serverless', true );

        const serviceIsAvailableInSlsForm = this._ldService.getFeature( 'fusion-reactor-cloud-ui-serverless-services', SERVERLESS_SERVICES )
            .some( ( service: string ) => url.includes( service ) );

        const isCOMSUrl = Object.values( environment.urls.api.coms )
            .some( ( comsUrl: string ) => url.includes( comsUrl ) );

        if ( shouldUseSls && isCOMSUrl && serviceIsAvailableInSlsForm ) {
            url = url.replace( environment.urls.base, environment.urls.sls_base );
        }

        if ( !this._ldService.getFeature( 'use-metrics-go-v1', false ) ) {
            url = url.replace( environment.urls.api.prometheus, environment.urls.api.prometheus_old );
        }

        return url;
    }
}
