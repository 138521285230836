import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'alphabeticalSortFilter',
    pure: true
} )

export class AlphabeticalSortPipe<T = any> implements PipeTransform {
    public transform( items: T[], key: string, reverse = false ): T[] {
        if ( !items.length || !key ) {
            return items;
        }

        if ( reverse ) {
            return items.sort( ( first: any, next: any ) => {
                if ( typeof first[key] === 'string' ) {
                    return first[key].toLowerCase() > next[key].toLowerCase() ? -1 : 1;
                }

                return first[key] > next[key] ? -1 : 1;
            } );
        }

        return items.sort( ( first: any, next: any ) => {
            if ( typeof first[key] === 'string' ) {
                return first[key].toLowerCase() < next[key].toLowerCase() ? -1 : 1;
            }

            return first[key] < next[key] ? -1 : 1;
        } );
    }
}
