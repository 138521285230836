import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Product extends ServerExtra {
    private readonly _productBuild: string;

    private readonly _productVersion: string;

    private readonly _productMajorVersion: string;

    private readonly _productMicroVersion: string;

    private readonly _productMinorVersion: string;

    private readonly _link: string;

    constructor( private readonly data ) {
        super( ServerExtraType.PRODUCT );

        this._productBuild = data.product_build;
        this._productVersion = data.product_version;

        if ( data.product_major_version ) {
            this._productMajorVersion = data.product_major_version;
            this._productMicroVersion = data.product_micro_version;
            this._productMinorVersion = data.product_minor_version;
        } else {
            const versionBits = this._productVersion.split( '.' );
            this._productMajorVersion = versionBits[0];
            this._productMicroVersion = versionBits[1];
            this._productMinorVersion = versionBits[2];
        }

        this._link = data.link;

        this.data = null;
    }

    get productBuild(): string {
        return this._productBuild;
    }

    get productMajorVersion(): string {
        return this._productMajorVersion;
    }

    get productMicroVersion(): string {
        return this._productMicroVersion;
    }

    get productMinorVersion(): string {
        return this._productMinorVersion;
    }

    /**
     * @deprecated Use productVersion() instead.
     */
    get versionAsString(): string {
        return this._productVersion;
    }

    get productVersion(): string {
        return this._productVersion;
    }

    get link(): string | null {
        return this._link;
    }

    public get details(): any {
        return {
            version: this.productVersion
        };
    }

    public toJson(): any {
        return {
            product_build        : this.productBuild,
            product_major_version: this.productMajorVersion,
            product_minor_version: this.productMinorVersion,
            product_micro_version: this.productMicroVersion,
            product_version      : this.productVersion
        };
    }
}
