import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GrafExplorerComponent } from './graf-explorer.component';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';

@Component( {
    selector   : 'app-graf-dashboard',
    templateUrl: './graf-explorer.component.html',
    styleUrls  : [ './graf-explorer.component.scss' ]
} )
export class GrafDashboardComponent extends GrafExplorerComponent implements OnInit {
    protected defaultGrafanaPath = window.location.pathname;
    private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
    public ngOnInit(): void {
        // Responds to changes in the URL to change dashboards without reloading the component

        this.route.url
            .pipe(
                takeUntil( this.ngUnsubscribe ), // prevent memory leak
                distinctUntilChanged( ( x, y ) => x[1] === y[1] ), // compare after g/d/
                skip( 1 ) // skip first value that's set when subscribing
            ).subscribe( ( value ) => {
                this.defaultGrafanaPath = '/g/' + value.join( '/' );
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                    this.constructGrafanaHost( this.defaultGrafanaPath )
                );
            } );

        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        super.ngOnDestroy();
    }
}
