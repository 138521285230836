import * as moment from 'moment';
import { IAccount } from '../interfaces/IAccount';
import { QuickLink } from './QuickLink';
import { UserConfiguration } from './UserConfiguration';
import { UserGroup, UserGroupLevel } from './UserGroup';
import { UserSettings } from './UserSettings';

export class User {
    // Database User Model Attributes
    private _account: any;
    private readonly _accountOwner: boolean;
    private readonly _avatar: any;
    private _configuration: UserConfiguration;
    private readonly _confirmed: boolean = null;
    private readonly _createdAt: number;
    private _decompile: boolean;
    private _email: string;
    private _enabled: boolean;
    private _first: string;
    private readonly _group: UserGroup;
    private readonly _hasPassword: boolean;
    private readonly _id: string;
    private readonly _impersonating: boolean = false;
    private readonly _impersonatingExpires: number;
    private _last: string;
    private readonly _lastActive: number;
    private _quickLinks: QuickLink[] = [];
    private _settings: UserSettings;

    // UI User Model Attributes
    private _isLoggedInUser = false;
    private _password: string;
    private _passwordConfirm: string;

    constructor( private readonly data: any ) {
        this._group = new UserGroup( data.group );
        this._first = data.first;
        this._last = data.last;
        this._account = data.account;
        this._avatar = data.avatar;
        this._createdAt = data.created_at;
        this._enabled = !data.disabled;
        this._email = data.email;
        this._accountOwner = data.account_owner;
        this._id = data.id;
        this._impersonating = data.impersonating;
        this._impersonatingExpires = data.impersonating_expires;
        this._lastActive = data.last_active;
        this._settings = new UserSettings( data.settings );
        this._configuration = new UserConfiguration( data.configuration );
        this._password = data.password;
        this._passwordConfirm = data.password_confirm;
        this._confirmed = data.confirmed !== undefined ? data.confirmed : null;
        this._quickLinks = data.quick_links ? data.quick_links.map( ( ql: any ) => new QuickLink( ql ) ) : [];
        this._decompile = data.decompile !== null;
        this._hasPassword = data.has_password;

        this.data = null;
    }

    get group(): UserGroup {
        return this._group;
    }

    get decompile(): boolean {
        return this._decompile;
    }

    set decompile( value: boolean ) {
        this._decompile = value;
    }

    get quickLinks(): QuickLink[] {
        return this._quickLinks;
    }

    set quickLinks( value: QuickLink[] ) {
        this._quickLinks = value;
    }

    get isLoggedInUser(): boolean {
        return this._isLoggedInUser;
    }

    set isLoggedInUser( value: boolean ) {
        this._isLoggedInUser = value;
    }

    get id(): string {
        return this._id;
    }

    set first( value: string ) {
        this._first = value;
    }

    get first(): string {
        return this._first;
    }

    get accountOwner(): boolean {
        return this._accountOwner;
    }

    set last( value: string ) {
        this._last = value;
    }

    get last(): string {
        return this._last;
    }

    get account(): IAccount {
        return this._account;
    }

    set account( value: IAccount ) {
        this._account = value;
    }

    get avatar(): any {
        return this._avatar;
    }

    set enabled( value: boolean ) {
        this._enabled = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get impersonating(): boolean {
        return this._impersonating;
    }

    get impersonatingExpires(): number {
        return this._impersonatingExpires;
    }

    set settings( value: UserSettings ) {
        this._settings = value;
    }

    get settings(): UserSettings {
        return this._settings;
    }

    get lastActive(): number {
        return this._lastActive;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    set email( value: string ) {
        this._email = value;
    }

    get email(): string {
        return this._email;
    }

    set passwordConfirm( value: string ) {
        this._passwordConfirm = value;
    }

    set password( value: string ) {
        this._password = value;
    }

    get confirmed(): boolean {
        return this._confirmed;
    }

    get configuration(): UserConfiguration {
        return this._configuration;
    }

    set configuration( value: UserConfiguration ) {
        this._configuration = value;
    }

    /**
     * Get first and last concatenated
     */
    get name(): string {
        return `${ this._first } ${ this._last }`;
    }

    get hasPassword(): boolean {
        return this._hasPassword;
    }

    public roleIsGreater( group: string ): boolean {
        const level = UserGroupLevel[group.toUpperCase()
            .replace( ' ', '_' )];

        return level <= this.group.level;
    }

    /**
     * Check if the user was created before a given date
     * @param timestamp Date to compare user creation time against in milliseconds
     */
    public isOlderThan( timestamp: number ): boolean {
        const dateToCheck = moment( timestamp );
        const userCreated = moment( this._createdAt );

        return userCreated.isBefore( dateToCheck );
    }

    public toJson(): Object {
        return {
            first                : this.first,
            last                 : this.last,
            account              : this.account,
            created_at           : this.createdAt,
            disabled             : !this.enabled,
            email                : this.email,
            group                : this.group.toJson(),
            id                   : this.id,
            password             : this._password,
            password_confirmation: this._passwordConfirm,
            impersonating        : this.impersonating,
            impersonating_expires: this.impersonatingExpires,
            last_active          : this.lastActive,
            settings             : this.settings,
            confirmed            : this.confirmed
        };
    }
}
