<ks-drawer-container-top>

    <div drawer-header>
        <h1>Updates</h1>
    </div>

    <div drawer-body
         fxLayout="column"
         fxFlexFill
         fxFill
         class="full-width"
         fxFlex="100">

        <virtual-scroller
            #scroller
            fxFlex="100"
            [enableUnequalChildrenSizes]="true"
            (vsUpdate)="visibleChangeLogList = $event"
            [items]="changeLogList">

            <ks-section *ngFor="let changeLog of visibleChangeLogList; trackBy:trackByIdFn; let i = index;"
                        [padding]="'0 1em 1em 1em'"
                        [sectionCollapsed]="!(i === 0 && unreadCount === 0) && changeLog.seen"
                        [sectionCollapsible]="true"
                        [entireBarClickable]="true"
                        class="change-log-item alt"
                        [class.new]="!changeLog.seen"
                        fxLayout="row wrap">

                <ks-section-header>
                    <div fxFlex="100"
                         fxLayout="row"
                         fxLayoutAlign="space-between center">
                        <h2 fxFlex="1 1 auto">{{changeLog.name}}</h2>

                        <span fxFlex="0 0 auto"
                              class="changelog-date"><i>{{changeLog.date | amTimeAgo}}
                            - {{ changeLog.date | date:'MMM d, y'}}</i></span>
                    </div>
                </ks-section-header>

                <div fxFlex="100"
                     fxFlexFill
                     class="change-log-item"
                     fxLayout="row wrap"
                     *ngFor="let changeType of ChangeLogChangeType | ksIterableMap; trackBy:trackByKeyFn"
                     [hidden]="changeLog.changesGrouped[changeType.value].length === 0">

                    <div class="change-log-line-item"
                         fxFlex="100"
                         fxFlexFill
                         fxLayoutAlign="start start"
                         fxLayout="column">

                        <div fxLayout="column"
                             fxLayoutAlign="start start"
                             fxFlex="0 0 auto">
                            <div fxLayout="row"
                                 fxLayoutAlign="start start"
                                 class="full-width">
                                <ng-container [ngSwitch]="changeType.value">
                                    <span class="tag ok changelog-tag"
                                          fxFlex="0 0 auto"
                                          *ngSwitchCase="ChangeLogChangeType.IMPROVEMENT">Improvement</span>

                                    <span class="tag default changelog-tag"
                                          fxFlex="0 0 auto"
                                          *ngSwitchCase="ChangeLogChangeType.BUGFIX">Bugfix</span>

                                    <span class="tag unknown changelog-tag"
                                          fxFlex="0 0 auto"
                                          *ngSwitchCase="ChangeLogChangeType.NEW_FEATURE">New feature</span>
                                </ng-container>
                            </div>
                        </div>

                        <div fxLayout="column"
                             class="change-log-list"
                             fxFlex="0 0 auto">
                            <ul fxFlex class="full-width">
                                <li *ngFor="let lineItem of changeLog.changesGrouped[changeType.value]; trackBy:trackByKeyFn">{{lineItem.log}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ks-section>

        </virtual-scroller>

    </div>

</ks-drawer-container-top>
