export enum ServerExtraType {
    DEFAULT = 'default',
    DOCKER = 'docker',
    HOST = 'host',
    INSTANCE = 'instance',
    JVM = 'jvm',
    NETWORK = 'network',
    OS = 'os',
    ENV = 'env',
    PRODUCT = 'product',
    SERVER = 'server',
    TRANSIT = 'transit',
}
