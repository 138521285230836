import { Component, Input } from '@angular/core';
import { KsNotificationService, KsNotificationStyleType } from '@intergral/kaleidoscope';

@Component( {
    selector   : 'app-code-snippet',
    templateUrl: './code-snippet.component.html',
    styleUrls  : [ './code-snippet.component.scss' ]
} )
export class CodeSnippetComponent {
    @Input()
    public codeContent: string;

    constructor( private readonly notificationService: KsNotificationService ) {

    }

    public onCopy(): void {
        this.notificationService.create( {
            body     : 'Content copied to your clipboard',
            styleType: KsNotificationStyleType.success
        } );
    }
}
