import { KsPermissionService } from '@intergral/kaleidoscope';
import { IAccount } from '../interfaces/IAccount';

export function evaluateCloudPermission( permissionService: KsPermissionService, account: IAccount ): boolean {
    const platforms = account?.platforms;
    if ( platforms === undefined || platforms === null ) {
        return false;
    }
    return permissionService.has( [ 'cloud.all' ] ) && platforms.includes( 'cloud' );
}
