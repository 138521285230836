import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from '../../../app.component';
import { ModalComponent } from './modal.component';

@NgModule( {
    declarations: [
        ModalComponent
    ],
    exports: [ ModalComponent ],
    imports: [
        BrowserModule
    ],
    providers: [],
    bootstrap: [ AppComponent ]
} )
export class Modal { }
