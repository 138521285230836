import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';

export type DecompilePermissions = {
    accepted_at: number,
    restricted: string[],
    errors?: string[]
};

export interface IDecompileResponse {
    class: string;
    decompile: string[];
    line: number;
    lines: Record<number, string>;
    method: any;
}

@Injectable( {
    providedIn: 'root'
} )
export class DecompileService extends BaseService {
    public canDecompile: DecompilePermissions = {
        accepted_at: null,
        restricted : [ '*' ],
        errors     : [ 'Decompile permissions not loaded. Please contact support.' ]
    };

    constructor( private readonly http: HttpClient ) {
        super();
    }

    public decompile(
        gruid: string,
        _class: string,
        method: string = null,
        filename: string = null
    ): Observable<IDecompileResponse> {
        let url = `${ environment.urls.api.coms.threadtrace }v1/decompile/${ gruid }/${ _class }`;

        if ( method ) {
            url += `/${ method }`;
        }

        if ( filename ) {
            const encodedFilename = encodeURIComponent( filename );
            url += `?fn=${ encodedFilename }`;
        }

        return this.http.get( url )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * Get the user decompile t/c status and list of restricted decompile classes
     */
    public requestAndSetDecompilePermissions(): Observable<boolean> {
        const decompilePermission = this.canDecompile;
        if ( decompilePermission && !decompilePermission.errors?.length ) {
            return of( true );
        }

        return this.http.get<DecompilePermissions>( `${ environment.urls.api.coms.threadtrace }v1/decompile` )
            .pipe(
                catchError( () => of( {
                    accepted_at: null,
                    restricted : [ '*' ],
                    errors     : [ 'Unable to get decompile info.' ]
                } ) ),
                map( ( dp ) => {
                    this.canDecompile = dp;
                    return true;
                } )
            );
    }

    /**
     * Get the method code from a given decompiled class
     *
     * @param decompiledCode the already decompiled code returned from decompile
     * @param methodName the name of the method to get the code for
     */
    public getDecompiledMethod( decompiledCode: string[], methodName: string ): string[] {
        const methodCode: string[] = [];
        let insideMethod = false;
        let nestingLevel = 0;

        for ( const line of decompiledCode ) {
            if ( insideMethod ) {
                methodCode.push( line );
                for ( const char of line ) {
                    if ( char === '{' ) {
                        nestingLevel++;
                    } else if ( char === '}' ) {
                        nestingLevel--;
                        if ( nestingLevel === 0 ) {
                            insideMethod = false;
                            break;
                        }
                    }
                }
            } else if ( line.includes( `${ methodName }(` ) && !line.includes( ';' ) ) {
                methodCode.push( line );
                insideMethod = true;
                nestingLevel = 1;
            }
        }

        if ( methodCode.length === 0 ) {
            return decompiledCode;
        }

        return methodCode;
    }
}
