export enum ChangeLogChangeType {
    NEW_FEATURE = 'new_feature',
    IMPROVEMENT = 'improvement',
    BUGFIX = 'bug_fix',
}

export class ChangeLogChange {
    private _type: ChangeLogChangeType;
    private readonly _log: string;

    constructor( private readonly data ) {
        this._log = data.log;

        Object.keys( ChangeLogChangeType )
            .forEach( ( key: string ) => {
                if ( ChangeLogChangeType[key] === data.type ) {
                    this._type = ChangeLogChangeType[key];
                }
            } );

        this.data = null;
    }

    get type(): ChangeLogChangeType {
        return this._type;
    }

    get log(): string {
        return this._log;
    }
}

export class ChangeLog {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _visible: boolean;
    private readonly _desc: string;
    private readonly _date: number;
    private readonly _changes: ChangeLogChange[] = [];
    private readonly _changesGrouped: { [key: string]: ChangeLogChange[] } = {
        bug_fix    : [],
        improvement: [],
        new_feature: []
    };

    private readonly _seenBy: string[] = [];
    private _seen: boolean;

    constructor( private readonly data ) {
        this._id = data._id;
        this._name = data.name;
        this._visible = data.visible;
        this._desc = data.desc;
        this._date = data.date;
        this._seenBy = data.seen_by;
        this._changes = data.changes.map( ( change: any ) => new ChangeLogChange( change ) );

        this._changes.forEach( ( change: ChangeLogChange ) => this._changesGrouped[change.type].push( change ) );

        this.data = null;
    }

    get changesGrouped(): { [p: string]: ChangeLogChange[] } {
        return this._changesGrouped;
    }

    get id(): string {
        return this._id;
    }

    get changes(): ChangeLogChange[] {
        return this._changes;
    }

    get date(): number {
        return this._date;
    }

    get desc(): string {
        return this._desc;
    }

    get visible(): boolean {
        return this._visible;
    }

    get name(): string {
        return this._name;
    }

    get seen(): boolean {
        return this._seen;
    }

    set seen( value: boolean ) {
        this._seen = value;
    }

    get seenBy(): string[] {
        return this._seenBy;
    }

    /**
     * Set the seen state based on if the user's id is in the seenBy list
     * @param userId the user_id to check for
     */
    public setInitialSeenState( userId: string ): void {
        this._seen = this._seenBy.includes( userId );
    }
}
