import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KsAlertService, KsTimePickerService } from '@intergral/kaleidoscope';
import { timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SharedComponent } from '../../../../../core/components/shared-component';
import { IAccount } from '../../../../../core/interfaces/IAccount';
import { AccountService } from '../../../../../core/services/account.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { StatusService } from '../../../../../core/services/status.service';
import { UserService } from '../../../../../core/services/user.service';
import { trackByIdFn } from '../../../../../core/trackByFunctions/track-by-id';

enum Onboarding {
    INITIAL,
    DOWNLOAD,
    CONFIGURATION,
    CONNECTION,
}

export interface ILanguage {
    niceName: string;
    logo: string;
    instanceNameExample: string;
    docs: string;
}

export interface IPlatform extends ILanguage {
    baseDir: string;
    libName: string;
    invertedDir?: string;
    config: { mkdir: string, cd: string };
}

@Component( {
    selector   : 'app-onboarding-agent-installation',
    templateUrl: './onboarding-agent-installation.component.html',
    styleUrls  : [ './onboarding-agent-installation.component.scss' ]
} )
export class OnboardingAgentInstallationComponent extends SharedComponent implements OnInit, OnDestroy {
    public onboardingSteps = [
        Onboarding.INITIAL,
        Onboarding.DOWNLOAD,
        Onboarding.CONFIGURATION,
        Onboarding.CONNECTION
    ];

    @Output()
    public readonly finished = new EventEmitter<boolean>();

    public selectedLanguage: ILanguage;
    public selectedPlatform: IPlatform;
    public languages: ILanguage[];
    public platforms: IPlatform[];
    public showRetryConnection: boolean = false;
    public connectionMade: boolean = false;
    public readonly downloadRoot: string = 'https://download.fusionreactor.io/FR/Latest/';
    public readonly licenseKey: string = this._authService.loggedInUser.account.license;
    public readonly trackByIdFn = trackByIdFn;
    public readonly Onboarding = Onboarding;

    constructor(
        private readonly _statusService: StatusService,
        private readonly _accountService: AccountService,
        private readonly _authService: AuthService,
        private readonly _userService: UserService,
        private readonly alertService: KsAlertService,
        private readonly timePickerService: KsTimePickerService,
        private readonly http: HttpClient
    ) {
        super( _authService, _userService, timePickerService );
    }

    public ngOnDestroy(): void {
        super.onDestroy();
    }

    public ngOnInit(): void {
        this.http.get( 'assets/onboarding-config.json' )
            .subscribe( ( {
                platforms,
                languages
            }: any ) => {
                this.platforms = platforms;
                this.languages = languages;
                this.selectedPlatform = this.platforms[0];
                this.selectedLanguage = this.languages[0];
            } );
    }

    public openSkipDialog(): void {
        if ( !this._authService.loggedInUser.account.onboarding.showInstallHelp ) {
            this.finished.emit( true );
            return;
        }

        this.alertService.confirm( {
            title  : 'Close Installation Guide',
            content: 'Are you sure you wish to close this guide?<br/>It is available again at any time from the help menu on the top right.'
        } )
            .subscribe( ( result: any ) => {
                if ( result.type !== 'ok' ) {
                    return;
                }
                this.finish();
            } );
    }

    public stepHandler( currentStep: { title: number } ): void {
        if ( currentStep.title !== 3 ) {
            this.ngUnsubscribe.next();

            return;
        }

        this.connectionMade = false;
        this.showRetryConnection = false;

        timer( 500, 2000 )
            .pipe( takeUntil( this.ngUnsubscribe ) )
            .subscribe( ( attempt: number ) => {
                this._statusService.getConnectedClientsCount()
                    .pipe( map( ( count: number ) => count > 0 ) )
                    .subscribe( ( connected: boolean ) => {
                        if ( connected ) {
                            this.connectionMade = true;
                            this.ngUnsubscribe.next();
                        } else if ( attempt === 4 ) {
                            this.showRetryConnection = true;
                            this.ngUnsubscribe.next();
                        }
                    }, () => {
                        if ( attempt === 4 ) {
                            this.showRetryConnection = true;
                            this.ngUnsubscribe.next();
                        }
                    } );
            } );
    }

    public async finish(): Promise<void> {
        const account: IAccount = this._authService.loggedInUser.account;

        if ( account.onboarding.showInstallHelp ) {
            account.onboarding.showInstallHelp = false;

            await this._accountService.updateAccount( account )
                .toPromise();
        }

        this.finished.emit( true );
    }

    public replaceInstanceName( main: string ): string {
        return main.replace( 'INSTANCE_NAME', this.selectedLanguage.instanceNameExample );
    }
}
