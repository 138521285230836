import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
    providedIn: 'root'
} )
export class ShowTimePickerService {
    public showTimePickerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( true );

    /**
     * Tracks how many components have set showTimePicker to false
     * so we can make it visible again once all those components are gone
     */
    public falseCount: number = 0;

    public set showTimePicker( value: boolean ) {
        this.falseCount += value ? -1 : 1;
        if ( this.falseCount < 0 ) {
            this.falseCount = 0;
        }

        // If falseCount == 0, set to true
        this.showTimePickerSubject.next( !this.falseCount );
    }

    public get showTimePicker(): boolean {
        return this.showTimePickerSubject.getValue();
    }
}
