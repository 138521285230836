import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Docker extends ServerExtra {
    private readonly _bootId: string;

    private readonly _containerIdTruncated: string;

    private readonly _containerIdUntruncated: string;

    private readonly _dockerEnvFileExists: boolean;

    private readonly _dockerType: boolean;

    private readonly _kernelInformation: string;

    private readonly _machineUuid: string;

    private readonly _sessionId: string;

    constructor( private readonly data ) {
        super( ServerExtraType.DOCKER );

        this._bootId = data.boot_id;
        this._containerIdTruncated = data.container_id_truncated;
        this._containerIdUntruncated = data.container_id_untruncated;
        this._dockerEnvFileExists = data.docker_env_file_exists;
        this._dockerType = data.docker_type;
        this._kernelInformation = data.kernel_information;
        this._machineUuid = data.machine_uuid;
        this._sessionId = data.session_id;

        this.data = null;
    }

    get bootId(): string {
        return this._bootId;
    }

    get containerIdTruncated(): string {
        return this._containerIdTruncated;
    }

    get containerIdUntruncated(): string {
        return this._containerIdUntruncated;
    }

    get dockerEnvFileExists(): boolean {
        return this._dockerEnvFileExists;
    }

    get dockerType(): boolean {
        return this._dockerType;
    }

    get kernelInformation(): string {
        return this._kernelInformation;
    }

    get machineUuid(): string {
        return this._machineUuid;
    }

    get sessionId(): string {
        return this._sessionId;
    }

    public toJson(): any {
        return {
            boot_id                 : this.bootId,
            container_id_truncated  : this.containerIdTruncated,
            container_id_untruncated: this.containerIdUntruncated,
            docker_env_file_exists  : this.dockerEnvFileExists,
            docker_type             : this.dockerType,
            kernel_information      : this.kernelInformation,
            machine_uuid            : this.machineUuid,
            session_id              : this.sessionId
        };
    }

    private _details: object;
    public get details() {
        this._details = {
            container: this.containerIdTruncated,
            session  : this.sessionId,
            type     : this.dockerType,
            machine  : this.machineUuid
        }
        return this._details;
    }
}
