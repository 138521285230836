import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class OpsPilotResizerService {
    constructor() {}
    get sidenavMinWidth(): number {
        return parseInt(
            getComputedStyle( document.getElementById( 'opspilot-side-drawer' ) ).getPropertyValue( 'min-width' ),
            10
        );
    }

    get sidenavMaxWidth(): number {
        return parseInt(
            getComputedStyle( document.getElementById( 'opspilot-side-drawer' ) ).getPropertyValue( 'max-width' ),
            10
        );
    }

    get sidenavWidth(): number {
        return parseInt(
            getComputedStyle( document.getElementById( 'opspilot-side-drawer' ) ).getPropertyValue( 'width' ),
            10
        );
    }

    /**
     * Sets the width of the sidenav to given number (clamped between a min and a max) as pixels.
     */
    setSidenavWidthClamped( width: number ) {
        const clampedWidth = Math.min(
            Math.max( width, this.sidenavMinWidth ),
            this.sidenavMaxWidth
        );

        document.getElementById( 'opspilot-side-drawer' ).style.width = `${ clampedWidth }px`;
    }

    setSidenavWidth( width: number ) {
        document.getElementById( 'opspilot-side-drawer' ).style.width = `${ width }px`;
    }

    setPointerEvent( value: string ) {
        document.querySelectorAll( 'iframe' ).forEach( v => { v.style.pointerEvents = value; } )
    }
}
