import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { KsPermissionService } from '@intergral/kaleidoscope';

type RouteData = {
    data: {
        permissions: {
            has: string[],
            any: string[]
        }
    }
}

@Injectable( {
    providedIn: 'root'
} )
export class PermissionsGuard implements CanActivate {
    private readonly redirectRoute: UrlTree;

    constructor( private readonly ksPermissionService: KsPermissionService, router: Router ) {
        this.redirectRoute = router.createUrlTree( [ '/overview' ] );
    }

    canActivate( route: ActivatedRouteSnapshot & RouteData ): boolean | UrlTree {
        let hasPermission = false;
        if ( route.data.permissions?.has?.length ) {
            hasPermission = this.ksPermissionService.has( route.data.permissions.has );
        }
        if ( !hasPermission && route.data.permissions?.any?.length ) {
            hasPermission = this.ksPermissionService.any( route.data.permissions.any );
        }
        // If they have permission, it will return true
        return hasPermission ? true : this.redirectRoute;
    }
}
