import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
    providedIn: 'root'
} )
export class ModalService {
  private showModalSubject = new BehaviorSubject<boolean>( false );
  private imgSubject = new BehaviorSubject<string>( '' );

  showModal$ = this.showModalSubject.asObservable();
  img$ = this.imgSubject.asObservable();

  constructor() { }

  showModal( url: string ) {
      this.showModalSubject.next( true );
      this.imgSubject.next( url );
  }

  closeModal() {
      this.showModalSubject.next( false );
  }
}
