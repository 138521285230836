import * as moment from 'moment-timezone';
import { Account } from './Account';
import { AccountStatus } from './AccountStatus';

export class Billing extends Account {
    private readonly _showBanner: boolean;
    private readonly _bannerObj: {
        name: string,
        icon: string,
        route: string,
        input: string,
        color: string,
    };

    constructor( private readonly accountData ) {
        super( accountData );
        this._showBanner = false;
        this._bannerObj = {
            name : '',
            icon : '',
            route: '/account/billing/details',
            input: 'Go to billing',
            color: 'warning'
        };
        this.buildBillingBanner();

        this.accountData = null;
    }

    get bannerObj(): { name: string; icon: string; route: string; input: string, color: string } {
        return this._bannerObj;
    }

    public isTrialExpiring(): boolean {
        const currentMillis = moment();
        const endTime = moment( this.expires );

        const diffInMillis = moment( endTime.diff( currentMillis ) );
        const diffInMonths = diffInMillis.month();

        return diffInMonths === 0 && this.status === AccountStatus.TRIAL;
    }

    public isAccountExpiring(): boolean {
        const now = moment();
        const exp = moment( this.expires );
        const diffDuration = exp.diff( now, 'days' );

        return diffDuration <= 14;
    }

    public calcDaysRemaining(): string {
        const now = moment( new Date() );
        const exp = moment( this.expires );
        const diffDuration = moment.duration( exp.diff( now ) );

        const months = diffDuration.months();

        let remainingTime: string = `${ diffDuration.days() } days, ${ diffDuration.hours() } hours, ${ diffDuration.minutes() } minutes`;

        if ( months ) {
            remainingTime = `${ months } months, ${ remainingTime }`;
        }

        return remainingTime;
    }

    public buildBillingBanner(): void {
        if ( this.checkAccountIsExpired() ) {
            this._bannerObj.icon = 'warning-filled';
            this._bannerObj.color = 'danger';
            // tslint:disable-next-line:prefer-conditional-expression
            if ( this.manual ) {
                this._bannerObj.name = 'FusionReactor Cloud Expired - Please contact sales to purchase FusionReactor Cloud to continue using this service ';
            } else {
                this._bannerObj.name = 'FusionReactor Cloud Expired - Please purchase FusionReactor Cloud to continue using this service';
            }
        } else if ( this.status === AccountStatus.OVERDUE ) {
            this._bannerObj.icon = 'warning-filled';
            this._bannerObj.color = 'danger';

            // tslint:disable-next-line:prefer-conditional-expression
            if ( this.manual ) {
                // tslint:disable-next-line:max-line-length
                this._bannerObj.name = `Your FusionReactor Cloud subscription has an overdue payment and will expire in ${ this.calcDaysRemaining() }. Please contact sales to confirm the payment`;
            } else {
                // tslint:disable-next-line:max-line-length
                this._bannerObj.name = `Your FusionReactor Cloud subscription has an overdue payment and will expire in ${ this.calcDaysRemaining() }. Please update your billing details to confirm the payment`;
            }
        } else if ( this.status === AccountStatus.CANCELLED ) {
            this._bannerObj.icon = 'warning-filled';
            this._bannerObj.color = 'warning';
            // tslint:disable-next-line:prefer-conditional-expression
            if ( !this.manual ) {
                // tslint:disable-next-line:max-line-length
                this._bannerObj.name = `Your FusionReactor Cloud subscription has been cancelled and will expire in ${ this.calcDaysRemaining() }.  Contact sales if you would like to renew your subscription`;
            } else {
                // tslint:disable-next-line:max-line-length
                this._bannerObj.name = `Your FusionReactor Cloud subscription has been cancelled and will expire in ${ this.calcDaysRemaining() }.  Please reactivate to continue using FusionReactor Cloud`;
            }
        } else if ( this.status === AccountStatus.TRIAL ) {
            if ( this.isTrialExpiring() ) {
                this._bannerObj.name = `Your trial runs out in ${ this.calcDaysRemaining() }`;
                this._bannerObj.icon = 'info-fulled';
                this._bannerObj.color = 'info';
                this._bannerObj.route = '/account/billing/purchase';
            }
        } else if ( this.manual && this.isAccountExpiring() ) {
            // tslint:disable-next-line:max-line-length
            this._bannerObj.name = `Your FusionReactor Cloud subscription ends in ${ this.calcDaysRemaining() }.  Contact sales if you would like to renew your subscription`;
            this._bannerObj.icon = 'warning-filled';
            this._bannerObj.color = 'warning';
        }
    }
}
