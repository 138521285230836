<div *ngIf="showSubNav" class="shadow-1" id="explore-navbar">
    <div class="content">
        <div [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="'active'"
             [routerLink]="['/g/explore']" class="nav-item">
            <span>Explore</span>
        </div>
        <div [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="'active'"
             [routerLink]="['/g/explore/metrics']" class="nav-item">
            <span>Metrics</span>
        </div>
    </div>
</div>

<div class="iframe-container" fxFlex>
    <iframe #GrafanaElement [src]="iframeUrl" allow="fullscreen; clipboard-read; clipboard-write" fxFlexFill
            id="grafana-frame"></iframe>
</div>

<ng-template #loader>
    <ks-atom-spinner fxFlex="100" fxLayoutAlign="center"></ks-atom-spinner>
</ng-template>
