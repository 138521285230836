import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TreeModule } from '@circlon/angular-tree-component';
import {
    KsBannerModule,
    KsButtonModule,
    KsCheckboxModule,
    KsDirectivesModule,
    KsDrawerModule,
    KsDropdownModule,
    KsFilterbarModule,
    KsInputModule,
    KsPipesModule,
    KsSectionModule,
    KsSpinnerModule,
    KsStepIndicatorModule,
    KsToolbarModule,
    KsTooltipModule,
    KsViewStateContainerModule
} from '@intergral/kaleidoscope';
import { NgxDatatableModule } from '@intergral/ngx-datatable';
import { AngularSplitModule } from 'angular-split';
import { ClipboardModule } from 'ngx-clipboard';
import { MomentModule } from 'ngx-moment';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SharedDirectivesCoreModule } from '../../directives/shared-directives-core.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { ChangelogViewerComponent } from './changelog-viewer/changelog-viewer.component';
import { DecompileTermsComponent } from './decompile-terms/decompile-terms.component';
import { DecompileOutputComponent } from './decompile/decompile-output.component';
import { ErrorListComponent } from './error-list/error-list.component';
import { EventSnapshotDetailsComponent } from './event-snapshot-details/event-snapshot-details.component';
import { EventSnapshotFramesComponent } from './event-snapshot-details/event-snapshot-frames/event-snapshot-frames.component';
import {
    EventSnapshotVariableComponent
} from './event-snapshot-details/event-snapshot-variables/event-snapshot-variable/event-snapshot-variable.component';
import {
    EventSnapshotVariablesComponent
} from './event-snapshot-details/event-snapshot-variables/event-snapshot-variables.component';
import { LogViewerComponent } from './legacy/log-viewer/log-viewer.component';
import { CodeSnippetComponent } from './onboarding/onboarding-installation-guide/code-snippet/code-snippet.component';
import {
    OnboardingAgentInstallationComponent
} from './onboarding/onboarding-installation-guide/onboarding-agent-installation.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PlanFeatureFlagDisabledComponent } from './plan-feature-flag/plan-feature-flag-disabled.component';
import { ProfilerDetailsComponent } from './profiler-details/profiler-details.component';
import { StacktraceLocComponent } from './stacktrace-loc/stacktrace-loc.component';
import { TextRadioButtonsComponent } from './text-radio-buttons/text-radio-buttons.component';
import { ThreadStateComponent } from './thread-state-component/thread-state.component';
import { TransactionDurationComponent } from './transaction-duration/transaction-duration.component';
import { MiniTimePickerComponent } from './mini-time-picker/mini-time-picker.component';
import { TransactionErrorsComponent } from './transaction-errors/errors.component';
import { GrafExplorerModule } from '../../../graf-explorer/graf-explorer.module';
import { HotkeyListComponent } from './hotkey-list/hotkey-list.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TablePipesModule } from '../../pipes/table-pipes/table-pipes.module';

@NgModule( {
    declarations: [
        LogViewerComponent,
        ChangelogViewerComponent,
        ErrorListComponent,
        EventSnapshotDetailsComponent,
        EventSnapshotVariablesComponent,
        EventSnapshotVariableComponent,
        EventSnapshotFramesComponent,
        PageNotFoundComponent,
        PlanFeatureFlagDisabledComponent,
        ProfilerDetailsComponent,
        TextRadioButtonsComponent,
        ThreadStateComponent,
        DecompileOutputComponent,
        DecompileTermsComponent,
        OnboardingAgentInstallationComponent,
        CodeSnippetComponent,
        StacktraceLocComponent,
        MiniTimePickerComponent,
        TransactionDurationComponent,
        TransactionErrorsComponent,
        HotkeyListComponent
    ],
    imports: [
        FlexModule,
        RouterModule,
        KsFilterbarModule,
        CommonModule,
        SharedPipesModule,
        KsTooltipModule,
        KsButtonModule,
        KsViewStateContainerModule,
        MonacoEditorModule,
        FormsModule,
        AngularSplitModule,
        KsInputModule,
        TreeModule,
        SharedDirectivesCoreModule,
        NgxDatatableModule,
        VirtualScrollerModule,
        KsDrawerModule,
        KsSectionModule,
        MomentModule,
        KsPipesModule,
        KsDirectivesModule,
        ClipboardModule,
        KsStepIndicatorModule,
        KsSpinnerModule,
        KsDropdownModule,
        KsCheckboxModule,
        KsToolbarModule,
        KsBannerModule,
        GrafExplorerModule,
        CdkTableModule,
        TablePipesModule
    ],
    exports: [
        LogViewerComponent,
        ChangelogViewerComponent,
        ErrorListComponent,
        EventSnapshotDetailsComponent,
        EventSnapshotVariablesComponent,
        EventSnapshotVariableComponent,
        EventSnapshotFramesComponent,
        PageNotFoundComponent,
        PlanFeatureFlagDisabledComponent,
        ProfilerDetailsComponent,
        TextRadioButtonsComponent,
        ThreadStateComponent,
        DecompileOutputComponent,
        DecompileTermsComponent,
        OnboardingAgentInstallationComponent,
        CodeSnippetComponent,
        StacktraceLocComponent,
        MiniTimePickerComponent,
        TransactionDurationComponent,
        TransactionErrorsComponent,
        HotkeyListComponent
    ]
} )
export class CommonComponentsModule {
}
