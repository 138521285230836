import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class OS extends ServerExtra {
    private readonly _osVersion: string;

    private readonly _osArch: string;

    private readonly _osTimezone: string;

    private readonly _osLocale: string;

    private readonly _osName: string;

    constructor( private readonly data ) {
        super( ServerExtraType.OS );

        this._osVersion = data.os_version;
        this._osArch = data.os_arch;
        this._osName = data.os_name;
        this._osLocale = data.os_locale;
        this._osTimezone = data.os_timezone;

        this.data = null;
    }

    get osVersion(): string {
        return this._osVersion;
    }

    get osArch(): string {
        return this._osArch;
    }

    get osLocale(): string {
        return this._osLocale;
    }

    get osTimezone(): string {
        return this._osTimezone;
    }

    get osName(): string {
        return this._osName;
    }

    public get details(): any {
        return {
            name        : this.osName,
            version     : this.osVersion,
            architecture: this.osArch,
            locale      : this.osLocale,
            timezone    : this.osTimezone
        };
    }

    public toJson(): any {
        return {
            os_version : this.osVersion,
            os_arch    : this.osArch,
            os_locale  : this.osLocale,
            os_timezone: this.osTimezone,
            os_name    : this.osName
        };
    }
}
