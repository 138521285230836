export enum UserGroupLevel {
    BUSINESS_CONTACT,
    ACCOUNT_OBSERVER,
    ACCOUNT_USER,
    ACCOUNT_MANAGER,
    ACCOUNT_ADMIN,
}

export class IUserGroupPermission {
    public readonly action: string;
    public readonly description: string;
    public readonly id: string;
}

export class UserGroup {
    private readonly _id: string;
    private _level: UserGroupLevel;
    private _name: string;
    private readonly _permissions: IUserGroupPermission[] = [];

    constructor( private readonly data: any ) {
        if ( !data ) {
            return;
        }

        this._id = data.id;
        this._name = data.name;
        this._permissions = ( data.permissions || [] );

        this.setLevel( this._name );

        this.data = null;
    }

    private setLevel( name: string ): void {
        this._level = UserGroupLevel[name.toUpperCase()
            .replace( ' ', '_' )];
    }

    get level(): UserGroupLevel {
        return this._level;
    }

    get permissions(): IUserGroupPermission[] {
        return this._permissions;
    }

    get name(): string {
        return this._name;
    }

    set name( value: string ) {
        this._name = value;
    }

    get id(): string {
        return this._id;
    }

    public toJson(): any {
        return {
            id         : this._id,
            name       : this._name,
            permissions: this._permissions
        };
    }
}
