import { Injectable } from '@angular/core';
import { KsDrawerService } from '@intergral/kaleidoscope';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { DecompileService } from './decompile.service';
import { LanguageDetectionService } from './language-detection.service';
import { LaunchDarklyService } from './launchdarkly.service';

const AssistantIntegrations = {
    log                : 'log',
    code               : 'code',
    jdbc               : 'jdbc',
    stacktrace         : 'stacktrace',
    sub_transaction    : 'sub_transaction',
    transaction_profile: 'transaction_profile'
} as const;

type AssistantIntegrationsKeys = keyof typeof AssistantIntegrations;

export interface Integration {
    content: string,
    content_type: AssistantIntegrationsKeys
}

export type OpsPilotEventType = 'open' | 'close' | 'resize';

@Injectable( {
    providedIn: 'root'
} )
export class OpspilotService extends BaseService {
    private _opsPilotFrame: HTMLIFrameElement;
    public toggleOpsPilotAssistant$: Subject<boolean | undefined> = new Subject<boolean | undefined>();
    public opsPilotAssistantEvent$: Subject<OpsPilotEventType> = new Subject<OpsPilotEventType>();

    public showPopup$: Observable<any> = new Observable( ( observer ) => {
        const listener = ( event: any ) => {
            if ( event.data === 'show-popup' ) {
                observer.next( event.data );
            }
        };
        window.addEventListener( 'message', listener );
        return () => window.removeEventListener( 'message', listener );
    } );

    constructor(
        private readonly drawerService: KsDrawerService,
        private readonly decompileService: DecompileService,
        private readonly languageDetectionService: LanguageDetectionService,
        private readonly ldService: LaunchDarklyService
    ) {
        super();
    }

    public get opsPilotFrame(): HTMLIFrameElement {
        return this._opsPilotFrame;
    }

    public set opsPilotFrame( value: HTMLIFrameElement ) {
        this._opsPilotFrame = value;
    }

    public sendMessage( message: any ) {
        if ( this.opsPilotFrame ) {
            this.opsPilotFrame.contentWindow.postMessage( message, '*' );
        } else {
            console.error( '[OpsPilot AI] OpsPilot frame does not exist.' );
        }
    }

    public passIntegrationToOpsPilot( integration : Integration ) {
        console.debug( '[OpsPilot AI] Sending integration to OpsPilot via iframe', integration )
        this.sendMessage( { type: 'opspilot-host.integration', integration } );
    }

    public navigateToActiveConversation() {
        this.sendMessage( { type: 'opspilot-host.navigateToActiveConversation' } );
    }

    public postOpsPilotCodeMessageToAssistant( prompt: string, language?: string ) {
        this.passIntegrationToOpsPilot( {
            content     : '```' + language + '\n' + prompt + '\n```',
            content_type: AssistantIntegrations.code
        } )
        this.toggleOpsPilotAssistant$.next( true );
        this.drawerService.destroyAll();
    }

    public postOpsPilotMessageToAssistant( prompt: string, type: AssistantIntegrationsKeys ) {
        this.passIntegrationToOpsPilot( {
            content     : prompt,
            content_type: type
        } )
        this.toggleOpsPilotAssistant$.next( true );
        this.drawerService.destroyAll();
    }

    public decompiler( code: string, filename: string ): void {
        if ( code ) {
            if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
                this.postOpsPilotCodeMessageToAssistant( code, this.languageDetectionService.getLanguageForExtension( filename ) );
            }
        }
    }

    public stacktrace( stack: string ): void {
        if ( stack?.length ) {
            if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
                this.postOpsPilotMessageToAssistant( stack, AssistantIntegrations.stacktrace );
            }
        }
    }

    public decompileMethod( gruid: string, className: string, methodName: string, filename: string ) {
        this.decompileService.decompile( gruid, className, methodName, filename ).subscribe(
            response => {
                response.decompile = this.decompileService.getDecompiledMethod( response.decompile, methodName );
                let methodCode = '';
                if ( Array.isArray( response.decompile ) || typeof response.decompile === 'object' ) {
                    if ( response.decompile.length ) {
                        response.decompile.forEach( ( loc: string ) => ( methodCode += `${ loc }\n` ) );
                        if ( methodCode ) {
                            if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
                                this.postOpsPilotCodeMessageToAssistant( methodCode,
                                    this.languageDetectionService.getLanguageForExtension( filename ) );
                            }
                        }
                    }
                }
            } );
    }

    public profiler( profile: string ) {
        if ( profile ) {
            if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
                this.postOpsPilotMessageToAssistant( profile, AssistantIntegrations.transaction_profile );
            }
        }
    }

    public subTransaction( transactionDescription: string ) {
        if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
            this.postOpsPilotMessageToAssistant( transactionDescription, AssistantIntegrations.sub_transaction );
        }
    }

    public jdbc( jdbcDescription: string ) {
        if ( this.ldService.getFeature( 'allow-ops-pilot', false ) ) {
            this.postOpsPilotMessageToAssistant( jdbcDescription, AssistantIntegrations.jdbc );
        }
    }
}
