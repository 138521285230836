import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Integration, OpspilotService } from '../core/services/opspilot.service';
import { Subscription } from 'rxjs';
import { LaunchDarklyService } from '../core/services/launchdarkly.service';
import { OpsPilotResizerService } from '../core/services/opspilot-resizer.service';
import { repositionSupportChat } from '../core/helper-functions/reposition-support-chat.function';
import { AuthService } from '../core/services/auth.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GrafanaService } from '../core/services/grafana.service';
import { KsTimePickerService } from '@intergral/kaleidoscope';
import { ModalService } from '../core/services/modal.service';

type OpsPilotEventType = 'opspilot-assistant.loaded' |
    'opspilot-assistant.loggedIn' |
    'opspilot-assistant.redirect' |
    'opspilot-assistant.getMetadata' |
    'opspilot-assistant.showModal' |
    'opspilot-slave.sendMetadata' |
    'opspilot-host.login' |
    'opspilot-host.sendMetadata' |
    'opspilot-host.error' |
    'opspilot-slave.sendIntegration';

interface BaseOpsPilotEvent {
    type: OpsPilotEventType;
}

interface RedirectOpsPilotEvent extends BaseOpsPilotEvent {
    value: string;
}

interface MetadataOpsPilotEvent extends BaseOpsPilotEvent {
    metadata: any;
}

interface IntegrationOpsPilotEvent extends BaseOpsPilotEvent {
    integration: Integration;
}

interface ModalOpsPilotEvent extends BaseOpsPilotEvent {
    img: string;
}
@Component( {
    selector   : 'app-ops-pilot',
    templateUrl: './ops-pilot.component.html',
    styleUrls  : [ './ops-pilot.component.scss' ]
} )

export class OpsPilotComponent implements OnInit {
    public assistantFeatureEnabled: boolean = false;
    public showDrawer: boolean = false;
    public isMobileScreen: boolean = false;
    private toggleSubject: Subscription;
    private ldSubject: Subscription;
    public opspilotUrl: SafeUrl;
    public opsPilotEvent: Subscription;

    resizingEvent = {
        // whether the user is currently resizing the sidenav
        isResizing: false,

        // the x coordinate of the mouse when the user started resizing
        startingCursorX: 0,

        // the width of the sidenav when the user started resizing
        startingWidth: 0
    };

    constructor(
        private readonly opsPilotService: OpspilotService,
        private readonly grafanaService: GrafanaService,
        private readonly ldService: LaunchDarklyService,
        private readonly resizer: OpsPilotResizerService,
        private readonly _authService: AuthService,
        private readonly ksTimePickerService: KsTimePickerService,
        private sanitizer: DomSanitizer,
        private readonly router: Router,
        private modalService: ModalService
    ) {
    }

    @HostListener( 'window:resize', [ '$event' ] )
    onResize() {
        this.checkMobileScreen();
    }

    @HostListener( 'window:message', [ '$event' ] )
    opsPilotCommsEventlistener( event: MessageEvent ) {
        if ( this.loginOpsPilot( event.data ) ) return;
        if ( this.opsPilotLoggedin( event.data ) ) return;
        if ( this.redirectRequest( event.data ) ) return;
        if ( this.getMetaData( event.data ) ) return;
        if ( this.sendMetaData( event.data ) ) return;
        if ( this.forwardIntegration( event.data ) ) return;
        if ( this.showModal( event.data ) ) return;
        return false;
    }

    public loginOpsPilot( data: BaseOpsPilotEvent ) {
        if ( data.type === 'opspilot-assistant.loaded' ) {
            if ( !this.opsPilotService.opsPilotFrame ) {
                this.opsPilotService.opsPilotFrame = document.getElementById( 'opspilot-side-container' ) as HTMLIFrameElement;
            }
            console.debug( '[OpsPilot AI] Sending login details' );
            this.opsPilotService.sendMessage( {
                type     : 'opspilot-host.login',
                firstName: this._authService.loggedInUser.first,
                lastName : this._authService.loggedInUser.last,
                email    : this._authService.loggedInUser.email,
                userToken: this._authService.priorityToken
            } );
            return true;
        }
        return false;
    }

    public opsPilotLoggedin( data: BaseOpsPilotEvent ) {
        if ( data.type === 'opspilot-assistant.loggedIn' ) {
            console.debug( '[OpsPilot AI] Logged in successfully' );
            return true;
        }
        return false;
    }

    public showModal( data: ModalOpsPilotEvent ) {
        if ( data.type === 'opspilot-assistant.showModal' ) {
            this.modalService.showModal( data.img )
            return true;
        }
        return false;
    }

    public redirectRequest( data: RedirectOpsPilotEvent ) {
        if ( data.type === 'opspilot-assistant.redirect' ) {
            console.debug( `[OpsPilot AI] Assistant is navigating the user to ${ data.value }.` );
            this.router.navigateByUrl( data.value );
            if ( window.innerWidth <= 900 ) {
                this.opsPilotService.toggleOpsPilotAssistant$.next( false );
            }
            return true;
        }
        return false;
    }

    public getMetaData( data: BaseOpsPilotEvent ) {
        if ( data.type === 'opspilot-assistant.getMetadata' ) {
            console.debug( '[OpsPilot AI] Assistant is requesting metadata.' );
            if ( this.grafanaService.GrafanaFrame ) {
                this.grafanaService.GrafanaFrame.contentWindow.postMessage(
                    { type: 'opspilot-host.getMetadata' },
                    '*'
                );
            } else {
                console.info( '[OpsPilot AI] Grafana iFrame not found' );
                const metadata = {
                    hostUrl  : window.location.pathname,
                    timeStart: this.ksTimePickerService.timeframe.start,
                    timeEnd  : this.ksTimePickerService.timeframe.end,
                    timezone : this.ksTimePickerService.timezone.tz()
                }
                this.opsPilotService.sendMessage( {
                    type    : 'opspilot-host.sendMetadata',
                    metadata: { metadata }
                } );
            }
            return true;
        }
        return false;
    }

    public sendMetaData( data: MetadataOpsPilotEvent ) {
        if ( data.type === 'opspilot-slave.sendMetadata' ) {
            data.metadata.hostUrl = window.location.pathname;
            this.opsPilotService.sendMessage( { type: 'opspilot-host.sendMetadata', metadata: data.metadata  } );
            console.debug( '[OpsPilot AI] Sending metadata to Opspilot, ', data.metadata );
            return true;
        } else {
            return false;
        }
    }

    public forwardIntegration( data: IntegrationOpsPilotEvent ) {
        if ( data.type === 'opspilot-slave.sendIntegration' ) {
            console.debug( '[OpsPilot AI] Received integration from Slave', data.integration );
            this.triggerToggleEvent( true );
            this.opsPilotService.passIntegrationToOpsPilot( data.integration );
            return true;
        }
        return false;
    }

    checkMobileScreen() {
        this.isMobileScreen = window.innerWidth <= 900;
    }

    public triggerToggleEvent( show?: boolean ) {
        this.opsPilotService.toggleOpsPilotAssistant$.next( show );
    }

    startResizing( event: MouseEvent ): void {
        this.resizingEvent = {
            isResizing     : true,
            startingCursorX: event.clientX,
            startingWidth  : this.resizer.sidenavWidth
        };
        this.resizer.setPointerEvent( 'none' );
        this.opsPilotService.opsPilotAssistantEvent$.next( 'resize' );
    }

    ngOnInit(): void {
        this.opspilotUrl = this.sanitizer.bypassSecurityTrustResourceUrl( `${ environment.opspilot.assistant }?version=${ Math.random().toString( 36 ).substring( 2 ) }` );
        // Retrieve the showDrawer state from localstorage
        const savedShowDrawer = window.localStorage.getItem( 'showOpsPilotAssistant' );
        if ( savedShowDrawer ) {
            this.showDrawer = JSON.parse( savedShowDrawer );
            this.moveIntercom();
        }
        this.toggleSubject = this.opsPilotService.toggleOpsPilotAssistant$.subscribe( show => {
            this.toggleDrawer( show );
        } );

        // Login and Logout will cause this subscription to fire, so no explicit subscriptions for login/logout were made
        this.ldSubject = this.ldService.flagChange.subscribe( () => {
            const isFeatureAllowed: boolean = this.ldService.getFeature( 'allow-ops-pilot', false );
            const isStatusExpired: boolean = this._authService.loggedInUser?.account?.status === 'expired';
            this.assistantFeatureEnabled = isFeatureAllowed && !isStatusExpired;
        } );

        this.checkMobileScreen(); // Add this line
    }

    ngOnDestroy(): void {
        this.toggleSubject.unsubscribe();
        this.ldSubject.unsubscribe();
        this.opsPilotService.opsPilotFrame = undefined;
    }

    @HostBinding( 'class.resizing' )
    get isResizing(): boolean {
        return this.resizingEvent.isResizing;
    }

    private toggleDrawer( show?: boolean ) {
        if ( show === null || show === undefined ) {
            this.showDrawer = !this.showDrawer;
        } else {
            this.showDrawer = show;
        }

        // Save the showDrawer state to localstorage
        window.localStorage.setItem( 'showOpsPilotAssistant', this.showDrawer.toString() );

        if ( this.showDrawer ) {
            this.resizer.setSidenavWidth( this.resizer.sidenavMinWidth );
        } else {
            this.resizer.setSidenavWidth( 0 );
        }

        this.opsPilotService.opsPilotAssistantEvent$.next( this.showDrawer ? 'open' : 'close' );
        this.moveIntercom();
    }

    private moveIntercom() {
        if ( this.showDrawer ) {
            repositionSupportChat( 0 );
        } else {
            repositionSupportChat( 0 );
        }
    }

    @HostListener( 'window:mousemove', [ '$event' ] )
    updateSidenavWidth( event: MouseEvent ) {
        if ( !this.resizingEvent.isResizing ) {
            return;
        }
        const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;
        const newWidth = this.resizingEvent.startingWidth - cursorDeltaX;
        this.resizer.setSidenavWidthClamped( newWidth );
    }

    @HostListener( 'window:mouseup' )
    stopResizing() {
        this.resizingEvent.isResizing = false;
        this.resizer.setPointerEvent( 'auto' );
    }
}
