import { Injectable } from '@angular/core';
import { initialize, LDClient, LDContext, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/User';

@Injectable( {
    providedIn: 'root'
} )
export class LaunchDarklyService {
    public readonly flagChange: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>( {} );
    private readonly _ldClient: LDClient;
    private _flags: LDFlagSet;
    private readonly _anonymousContext: LDContext = {
        kind: 'multi',
        user: {
            kind     : 'user',
            anonymous: true
        },
        account: {
            kind     : 'account',
            anonymous: true
        }
    };

    constructor() {
        this._flags = {};
        this._ldClient = initialize( environment.launchdarkly.apiKey, this._anonymousContext, undefined );

        this._ldClient.on( 'ready', ( value: LDFlagSet ) => this._setFlags( value ) );
        this._ldClient.on( 'change', ( value: LDFlagSet ) => this._setFlags( value ) );
        this._ldClient.on( 'error', ( error: any ) => this.flagChange.error( error ) );
    }

    public isFeatureOn( feature: string ): boolean {
        return this._flags?.[feature]?.current ?? false;
    }

    public getFeature( feature: string, defaultValue: any ): any {
        return this._ldClient.variation( feature, defaultValue );
    }

    public async changeUser( user: User ): Promise<void> {
        if ( user === null ) {
            this._ldClient.identify( this._anonymousContext );

            return;
        }

        const userContext: LDContext = {
            kind         : 'user',
            key          : user.id,
            firstName    : user.first,
            lastName     : user.last,
            name         : user.name,
            email        : user.email,
            account_id   : user.account.id,
            account_name : user.account.name,
            license      : user.account.license,
            impersonating: user.impersonating,
            role_name    : user.group.name,
            _meta        : { privateAttributes: [ 'license' ] },
            anonymous    : false
        };

        const accountContext: LDContext = {
            kind        : 'account',
            key         : user.account.id,
            account_name: user.account.name,
            license     : user.account.license,
            plan_code   : user.account.plan_code,
            provider    : user.account.provider,
            platforms   : user.account.platforms,
            _meta       : { privateAttributes: [ 'license' ] }
        };

        const multiContext: LDContext = {
            kind   : 'multi',
            user   : userContext,
            account: accountContext
        };

        this._flags = await this._ldClient.identify( multiContext ) || {};
    }

    private _setFlags( flags: any ): void {
        this._flags = flags;
        this.flagChange.next( this._flags );
    }
}
