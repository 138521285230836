import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Session } from '../models/Session';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';

@Injectable( {
    providedIn: 'root'
} )
export class UserService extends BaseService {
    constructor( private readonly http: HttpClient,
                 private readonly _authService: AuthService ) {
        super();
    }

    /**
     * /v1/user
     * Fetch the currently logged in user.
     */
    public getCurrentUser(): Observable<User> {
        return of( this._authService.loggedInUser );
    }

    /**
     * Update a user
     * @param user any
     */
    public async updateSelectedUser( user: User ): Promise<User> {
        const userJson: any = user.toJson();

        return this.http.put<{ data: any }>( `${ environment.urls.api.coms.account }v1/user/${ userJson.id }`, {
            first                : userJson.first,
            last                 : userJson.last,
            email                : userJson.email,
            password             : userJson.password,
            password_confirmation: userJson.password_confirmation,
            role                 : userJson.group.name,
            disabled             : userJson.disabled
        } )
            .pipe( map( ( response: any ) => new User( response.data ) ) )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    public updateUserSettings(): any {
        if ( !this._authService.loggedInUser ) {
            return of();
        }

        return this.http.put(
            `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/settings`,
            this._authService.loggedInUser.settings.toJson() )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    public updateUserConfiguration(): any {
        return this.http.put(
            `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/configuration`,
            this._authService.loggedInUser.configuration.toJson()
        )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * Update the currently logged in user
     * @param data any
     */
    public updateUserDetails( data: any ): Observable<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/details`, data )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * @param data any
     */
    public updateUserEmail( data: any ): Observable<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/email`, data )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     *
     * @param data any
     */
    public updateUserPassword( data: any ): Observable<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/password`, data )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * Delete a user
     * @param user any
     */
    public async deleteUser( user: any ): Promise<HttpResponse<any>> {
        return this.http.delete( `${ environment.urls.api.coms.account }v1/user/${ user.id }` )
            .toPromise()
            .then( ( response: HttpResponse<any> ) => response )
            .catch( this.handleErrorPromise );
    }

    /**
     * Add a new user to the account
     * @param user any
     */
    public async addUser( user: any ): Promise<any> {
        return this.http.post<{ data: any }>( `${ environment.urls.api.coms.account }v1/user`, user )
            .pipe( map( ( response: any ) => new User( response.data ) ) )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Get the logged in users sessions
     */
    public getSessions(): Observable<Session[]> {
        return this.http.get<{ data: any[] }>( `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/sessions` )
            .pipe(
                map( ( response: any ) =>
                    response.data.map( ( session: any ) => {
                    // noinspection JSAnnotator
                        session.isCurrent = this._authService.priorityToken === session.token;

                        return new Session( session );
                    } ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Delete a session
     * @param token string
     */
    public removeSession( token?: string ): Observable<any> {
        return this.http.delete( `${ environment.urls.api.coms.account }v1/user/${ this._authService.loggedInUser.id }/sessions/${ token || '' }` )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * Resend a users activation email
     * @param user User
     * @param isLegacy boolean
     */
    public resendEmail( user: User, isLegacy = false ): Observable<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/user/${ user.id }/resend`, { legacy: isLegacy } )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }
}
