import { environment } from '../../../../environments/environment';
import { ThreadState } from './ThreadState';

export class Thread {
    public initial = {
        user   : 0,
        wait   : 0,
        blocked: 0,
        cpu    : 0,
        cpuMs  : 0
    };

    private readonly _id: number;
    private readonly _alive: boolean;
    private _group: string;
    private readonly _name: string;
    private _state: ThreadState;
    private readonly _transaction: boolean;
    private _profiling: boolean;
    private _profileState: string;
    private _pid: ( number | string )[];
    private readonly _txnId: number;
    private _cpuTime: number;
    private readonly _cpuTimeMs: number;
    private _cpuPercentage: number;
    private _userTime: number;
    private _waitTime: number;
    private _blockedTime: number;

    constructor( private readonly data: any ) {
        this._id = data.id;
        this._alive = data.alive;
        this._group = data.group;
        this._name = data.name;
        this._transaction = data.transaction;
        this._pid = data.pid;
        this._txnId = data.txnid;
        this._cpuTime = data.cpu_time;
        this._cpuTimeMs = Math.round( data.cpu_time / 1000000 );
        this._cpuPercentage = data.cpu_percent;
        this._userTime = Math.round( data.user_time / 1000000 );
        this._waitTime = data.wait_time;
        this._blockedTime = data.blocked_time;
        this._profiling = data.pid && data.pid.length > 0;

        this.setState( data.transaction ? 'RUNNING' : data.state );

        this.data = null;
    }

    get blocked_time_diff(): number {
        return this._blockedTime - this.initial.blocked;
    }

    get wait_time_diff(): number {
        return this._waitTime - this.initial.wait;
    }

    get cpuTime(): number {
        return this._cpuTime;
    }

    set cpuTime( value: number ) {
        this._cpuTime = value;
    }

    get user_time_diff(): number {
        return this._userTime - this.initial.user;
    }

    get cpu_time_diff(): number {
        return this._cpuTime - this.initial.cpu;
    }

    get cpu_time_delta_diff(): number {
        return this._cpuTimeMs - this.initial.cpuMs;
    }

    get cpuPercentage(): number {
        return this._cpuPercentage;
    }

    set cpuPercentage( value: number ) {
        this._cpuPercentage = value;
    }

    set waitTime( value: number ) {
        this._waitTime = value;
    }

    get waitTime(): number {
        return this._waitTime;
    }

    set userTime( value: number ) {
        this._userTime = value;
    }

    get userTime(): number {
        return this._userTime;
    }

    get blockedTime(): number {
        return this._blockedTime;
    }

    set blockedTime( value: number ) {
        this._blockedTime = value;
    }

    get pid(): ( number | string )[] {
        if ( !this._pid ) {
            return [];
        }

        return this._pid.map( ( id: number | string ) => {
            if ( typeof id === 'string' ) {
                /**
                 * Handle the 7.3.0+ format of profile ids
                 * e.g a1014189-0216-47f5-891b-c3ff0538033b/a66a232f-33ab-435e-9be4-652656225b13/12
                 */
                return id.replace( /\//g, '_' );
            }

            return id;
        } );
    }

    set pid( value: ( number | string )[] ) {
        this._pid = value;
    }

    get txnId(): number {
        return this._txnId;
    }

    get profileState(): string {
        return this._profileState;
    }

    set profileState( value: string ) {
        this._profileState = value;
    }

    get profiling(): boolean {
        return this._profiling;
    }

    set profiling( value: boolean ) {
        this._profiling = value;
    }

    get group(): string {
        return this._group;
    }

    set group( value: string ) {
        this._group = value;
        this._sortName = this._name + this._group;
    }

    get transaction(): boolean {
        return this._transaction;
    }

    get state(): ThreadState {
        return this._state;
    }

    get name(): string {
        return this._name;
    }

    // Memory reference for efficiency
    private _sortName: string;
    get sortName(): string {
        this._sortName = this._name + this._group;
        return this._sortName;
    }

    get alive(): boolean {
        return this._alive;
    }

    get id(): number {
        return this._id;
    }

    public setInitial(): void {
        this.initial = {
            blocked: this._blockedTime,
            cpu    : this._cpuTime,
            cpuMs  : this._cpuTimeMs,
            user   : this._userTime,
            wait   : this._waitTime
        };
    }

    public toJson(): any {
        return {
            id          : this.id,
            alive       : this.alive,
            group       : this.group,
            name        : this.name,
            state       : this.state,
            transaction : this.transaction,
            profiling   : this.profiling,
            profileState: this.profileState,
            pid         : this.pid,
            txnid       : this.txnId,
            cpu_time    : this.cpuTime,
            cpu_percent : this.cpuPercentage,
            user_time   : this._userTime,
            wait_time   : this._waitTime,
            blocked_time: this._blockedTime,
            initial     : this.initial
        };
    }

    private setState( state: string ): void {
        switch ( state ) {
            case 'RUNNABLE': {
                this._state = ThreadState.RUNNABLE;
                break;
            }
            case 'RUNNING': {
                this._state = ThreadState.RUNNING;
                break;
            }
            case 'DEADLOCKED': {
                this._state = ThreadState.DEADLOCKED;
                break;
            }
            case 'TIMED_WAITING': {
                this._state = ThreadState.TIMED_WAITING;
                break;
            }
            case 'WAITING': {
                this._state = ThreadState.WAITING;
                break;
            }
            case 'BLOCKED': {
                this._state = ThreadState.BLOCKED;
                break;
            }
            case 'TERMINATED': {
                this._state = ThreadState.TERMINATED;
                break;
            }
            default: {
                if ( !environment.production ) {
                    console.error( `ThreadState ${ state } not found` );
                }
            }
        }
    }
}
