import { stackVariableSort } from '../../../helper-functions/stack-variable-sort';
import { EventSnapshotStacktraceVariable, SnapshotVariableReference } from './EventSnapshotStacktraceVariable';

export class EventSnapshotStacktrace {
    private readonly _id: number;
    private readonly _class: string;
    private readonly _method: string;
    private readonly _line: number;
    private readonly _file: string;
    private readonly _depth: number;
    private readonly _hidden: boolean;
    private readonly _fileOverride: string;
    private readonly _variables: EventSnapshotStacktraceVariable[];

    constructor( private readonly data: any, private readonly snapshotId: string, private readonly _variableRef: SnapshotVariableReference ) {
        this._id = data.id;
        this._class = data.classname;
        this._method = data.methodname;
        this._line = data.linenumber;
        this._file = data.filename;
        this._depth = data.depth;
        this._hidden = data.hidden;
        this._fileOverride = data.file_override;

        ( data.variables || [] ).forEach( variable => {
            // Convert fields to children for the tree library
            if ( variable.fields ) {
                variable.children = [ ...variable.fields ];
            }

            return this._variableRef.addToFoundVariables( variable );
        } );

        this._variables = ( data.variables || [] )
            .map( variable => this._variableRef.getVariable( variable, this._class, snapshotId ) )
            .sort( stackVariableSort );
    }

    get id(): number {
        return this._id;
    }

    get class(): string {
        return this._class;
    }

    get method(): string {
        return this._method;
    }

    get line(): number {
        return this._line;
    }

    get file(): string {
        return this._file;
    }

    get depth(): number {
        return this._depth;
    }

    get hidden(): boolean {
        return this._hidden;
    }

    get fileOverride(): string {
        return this._fileOverride;
    }

    get variables(): EventSnapshotStacktraceVariable[] {
        return this._variables;
    }

    public toJson(): any {
        const variablesJson = this._variables.map( v => v.toJSON() );

        return {
            class        : this._class,
            method       : this._method,
            line         : this._line,
            file         : this._file,
            depth        : this._depth,
            hidden       : this._hidden,
            file_override: this._fileOverride,
            variables    : variablesJson
        };
    }
}
