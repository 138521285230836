<div fxLayout="row"
     fxLayoutAlign="center center">
    <div fxLayout="column"
         fxLayoutAlign="center center"
         fxFlexFill>
        <span style="font-size:60px;">404</span>

        <img style="width:400px;" src="assets/programmer.svg" alt=""/>

        <h2><strong>Whoops!</strong></h2>

        <h3>That page could not be found!</h3>

        <a class="btn btn-primary" [routerLink]="url">Back to Overview</a>
    </div>
</div>
