export class ProfileDataRecord {
    private readonly _applicationName: string;
    private readonly _duration: number;
    private readonly _txnDuration: number;
    private readonly _descriptionA: string;
    private readonly _descriptionB: string;
    private readonly _id: number | string; // number if < FR7.2.3 else >= 7.3.0 then string
    private readonly _pid: number | string; // number if < FR7.2.3 else >= 7.3.0 then string
    private readonly _rootNode: ProfileDataRecordRootNode;
    private readonly _sampleCount: number;
    private readonly _timestamp: number;
    private readonly _threadId: number;
    private readonly _threadName: string;
    private readonly _transactionName: string;
    private readonly _transactionId: string;
    private _saved = false;
    private _saving = 'active';
    private readonly _state: string;

    public static calculateTimePercentage(
        totalTime: number,
        timeInMethod: number
    ): number {
        const newTimeInMethod = parseFloat( ( timeInMethod / 1000000000 ).toFixed( 6 ) );
        const tmpTotal = totalTime / 1000000000;

        if ( totalTime > 0 && newTimeInMethod > 0 ) {
            return parseFloat( ( ( newTimeInMethod / tmpTotal ) * 100 ).toFixed( 2 ) );
        }

        return 0;
    }

    constructor( private readonly data: any ) {
        this._applicationName = data.an ? data.an : data.app_name;
        this._duration = data.d ? Math.floor( data.d / 1000000 ) : Math.floor( data.duration / 1000000 );
        this._txnDuration = data.txnd ? data.txnd : this._duration; // 7.3.0+ will provide txnd else fall back to profile
        this._descriptionA = data.dsa ? data.dsa : data.desc_a;
        this._descriptionB = data.dsb ? data.dsb : data.desc_b;
        this._id = data.id ? data.id : data.pid; // unique id for saved
        this._pid = data.pid ? data.pid : data.id; // normal pid else id if coming from IR

        if ( data.root || data.profile ) {
            this._rootNode = new ProfileDataRecordRootNode( data.root ? data.root : data.profile.root, data.d
                ? data.d
                : data.duration );
        } else {
            this._rootNode = new ProfileDataRecordRootNode( null, 100 );
        }

        this._sampleCount = data.sc ? data.sc : data.sample_count;
        this._state = data.s;
        this._timestamp = data.t ? data.t : data.start_time;
        this._threadId = data.tid ? data.tid : data.thread_id;
        this._threadName = data.tn ? data.tn : data.thread_name;
        this._transactionName = data.txn ? data.txn : data.transaction_name;
        this._transactionId = data.txnid ? data.txnid : data.transaction_id;
        this._saved = data.saved;

        if ( !this.transactionId ) {
            this._transactionId = '';
        }
    }

    get state(): string {
        return this._state;
    }

    get saving(): string {
        return this._saving;
    }

    set saving( value: string ) {
        this._saving = value;
    }

    get txnDuration(): number {
        return this._txnDuration;
    }

    get saved(): boolean {
        return this._saved;
    }

    set saved( value: boolean ) {
        this._saved = value;
    }

    get pid(): number | string {
        if ( typeof this._pid === 'string' ) {
            /**
             * Handle the 7.3.0+ format of profile ids
             * e.g a1014189-0216-47f5-891b-c3ff0538033b/a66a232f-33ab-435e-9be4-652656225b13/12
             */
            return this._pid.replace( /\//g, '_' );
        }

        return this._pid;
    }

    get applicationName(): string {
        return this._applicationName;
    }

    get transactionName(): string {
        return this._transactionName;
    }

    get sortName(): string {
        return this.transactionName + this.descriptionA + this.descriptionB;
    }

    get descriptionA(): string {
        return this._descriptionA;
    }

    get descriptionB(): string {
        return this._descriptionB;
    }

    get rootNode(): ProfileDataRecordRootNode {
        return this._rootNode;
    }

    get threadName(): string {
        return this._threadName;
    }

    get threadId(): number {
        return this._threadId;
    }

    get sampleCount(): number {
        return this._sampleCount;
    }

    get duration(): number {
        return this._duration;
    }

    get timestamp(): number {
        return this._timestamp;
    }

    get id(): number | string {
        if ( typeof this._id === 'string' ) {
            return this._id.replace( /\//g, '_' );
        }

        return this._id;
    }

    get transactionId(): string {
        return this._transactionId;
    }

    public toJson(): any {
        return JSON.stringify( this.data );
    }

    public toMetaJson(): any {
        return {
            application_name: this._applicationName,
            description     : this.descriptionA,
            duration        : this.data.d,
            pid             : this.id,
            query           : this.descriptionB ? this.descriptionB : null,
            sample_count    : this.sampleCount,
            thread_id       : this.threadId,
            thread_name     : this.threadName,
            time            : this.timestamp,
            transaction_id  : this.transactionId ? this.transactionId : null,
            transaction_name: this.transactionName ? this.transactionName : null
        };
    }
}

export class ProfileDataRecordRootNode {
    private readonly _childNodes: ProfileDataRecordChildNode[];
    private readonly _duration: number;
    private readonly _id: number;
    private readonly _source: ProfileDataRecordNodeSource;
    private readonly _selfTime: boolean;
    private readonly _time: {
        time_in_method: number,
        time_percentage: number,
    };

    constructor( private readonly rootNodeData: any, private readonly totalExecTime: number ) {
        if ( !rootNodeData ) {
            this._childNodes = [];
            this._duration = 0;
            this._id = 0;
            this._source = new ProfileDataRecordNodeSource( null );
            this._selfTime = false;
            this._time = {
                time_in_method : parseFloat( ( this._duration / 1000000000 ).toFixed( 6 ) ),
                time_percentage: 100
            };
        } else {
            this._childNodes = rootNodeData.c ? rootNodeData.c.map( ( c: any ) => new ProfileDataRecordChildNode( c, totalExecTime ) ) : [];
            this._duration = rootNodeData.d;
            this._id = rootNodeData.id;
            this._source = new ProfileDataRecordNodeSource( rootNodeData.s );
            this._selfTime = rootNodeData.st;
            this._time = {
                time_in_method : parseFloat( ( this._duration / 1000000000 ).toFixed( 6 ) ),
                time_percentage: ProfileDataRecord.calculateTimePercentage( totalExecTime, this._duration )
            };
        }

        this.totalExecTime = null;
        this.rootNodeData = null;
    }

    get time(): { time_in_method: number; time_percentage: number } {
        return this._time;
    }

    get source(): ProfileDataRecordNodeSource {
        return this._source;
    }

    get selfTime(): boolean {
        return this._selfTime;
    }

    get duration(): number {
        return this._duration;
    }

    get id(): number {
        return this._id;
    }

    get childNodes(): ProfileDataRecordChildNode[] {
        return this._childNodes;
    }

    public generateNodeLabel(): string {
        return `${ this._time.time_percentage }% ${ this._source.class_name }.${ this._source.method_name }(${ this._source.source_file })`;
    }
}

export class ProfileDataRecordChildNode extends ProfileDataRecordRootNode {
    constructor( private readonly childNodeData: any, private readonly totalExecTimeChild: number ) {
        super( childNodeData, totalExecTimeChild );

        this.totalExecTimeChild = null;
        this.childNodeData = null;
    }
}

export class ProfileDataRecordNodeSource {
    private readonly _className: string = '';
    private readonly _lineNumber: number;
    private readonly _methodName: string = '';
    private readonly _native: boolean;
    private readonly _sourceFile: string;
    private readonly _hashCode: number;
    private readonly _isInvalid: boolean = false;

    constructor( private readonly sourceData: any ) {
        if ( !this.sourceData ) {
            this._isInvalid = true;
        } else {
            this._className = sourceData.cn;
            this._lineNumber = sourceData.ln;
            this._methodName = sourceData.mn;
            this._native = sourceData.n;
            this._hashCode = sourceData.h;
            this._sourceFile = ( sourceData.mn && sourceData.sf ) ? sourceData.sf : 'Unknown Source';

            this.sourceData = null;
        }
    }

    get isInvalid(): boolean {
        return this._isInvalid;
    }

    get hash_code(): number {
        return this._hashCode;
    }

    get hash_code_as_hex(): string {
        return `0x${ this._hashCode.toString( 16 ) }`;
    }

    get source_file(): string {
        return this._sourceFile;
    }

    get native(): boolean {
        return this._native;
    }

    get method_name(): string {
        return this._methodName;
    }

    get line_number(): number {
        return this._lineNumber;
    }

    get class_name(): string {
        return this._className;
    }
}
