import { Injectable } from '@angular/core';

const extensionToLanguage: Record<string, string> = {
    js   : 'JavaScript',
    ts   : 'TypeScript',
    py   : 'Python',
    java : 'Java',
    c    : 'C',
    cpp  : 'C++',
    cs   : 'C#',
    rb   : 'Ruby',
    php  : 'PHP',
    go   : 'Go',
    swift: 'Swift',
    rs   : 'Rust',
    pl   : 'Perl',
    cfm  : 'ColdFusion',
    cfc  : 'ColdFusion',
    cfml : 'ColdFusion',
    dbm  : 'ColdFusion',
    ddd  : 'ColdFusion',
    html : 'HTML',
    css  : 'CSS',
    scss : 'SCSS',
    sass : 'Sass',
    jsx  : 'JSX',
    tsx  : 'TSX',
    json : 'JSON',
    yaml : 'YAML',
    md   : 'Markdown',
    sql  : 'SQL'
};

@Injectable( {
    providedIn: 'root'
} )
export class LanguageDetectionService {
    public getLanguageForExtension( filename: string ): string | null {
        const extension = filename.split( '.' ).pop()?.toLowerCase();

        if ( !extension ) {
            return null;
        }

        const language = extensionToLanguage[extension];

        if ( language ) {
            return language;
        }

        return null;
    }
}
