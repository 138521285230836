import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../core/services/modal.service';

@Component( {
    selector   : 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls  : [ './modal.component.scss' ]
} )
export class ModalComponent implements OnDestroy {
  showModal: boolean = false;
  img: string = '';
  private subscriptions = new Subscription();

  constructor( private modalService: ModalService ) {
      this.subscriptions.add( this.modalService.showModal$.subscribe( show => this.showModal = show ) );
      this.subscriptions.add( this.modalService.img$.subscribe( img => this.img = img ) );
  }

  closeModal() {
      this.modalService.closeModal();
  }

  ngOnDestroy() {
      this.subscriptions.unsubscribe();
  }
}
