import { GroupType } from './GroupType';
import { Server } from './Server';

export class Group {
    public readonly routerLink: { url: string[], queryParams: any };
    /**
     * @deprecated No longer supplied by the group-service
     */
    private readonly _created: any;

    constructor( data: any ) {
        const {
            type,
            name,
            description,
            group_id,
            _id,
            servers,
            client_ids
        } = data;

        this._id = _id || group_id;
        this._description = description;
        this._name = name;
        if ( client_ids ) {
            this._servers = client_ids.map( ( svr: string ) => new Server( { client_id: svr } ) );
        } else if ( servers ) {
            this._servers = servers.map( ( svr: Server ) => new Server( svr ) );
        } else {
            this._servers = [];
        }

        this.setGroupType( type );

        this.routerLink = {
            url        : [ '/servers', 'detailed' ],
            queryParams: { group: this.id }
        };

        data = undefined;
    }

    private _id: string;

    get id(): string {
        return this._id;
    }

    set id( id: string ) {
        this._id = id;
    }

    private _description: string;

    get description(): string {
        return this._description;
    }

    set description( value: string ) {
        this._description = value;
    }

    private _counterLabel: string; // label for QSP online/offline count

    get counterLabel(): string {
        return this._counterLabel;
    }

    set counterLabel( value: string ) {
        this._counterLabel = value;
    }

    private _name: string;

    get name(): string {
        return this._name;
    }

    set name( value: string ) {
        this._name = value;
    }

    private _servers: Server[] = [];

    get servers(): Server[] {
        return this._servers;
    }

    set servers( servers: Server[] ) {
        this._servers = servers;
    }

    private _type: GroupType;

    get type(): GroupType {
        return GroupType.ACCOUNT;
    }

    set type( value: GroupType ) {
        this._type = value;
    }

    private _onlineCount = 0;

    get onlineCount(): number {
        return this._onlineCount;
    }

    set onlineCount( value: number ) {
        this._onlineCount = value;
    }

    public setGroupType( type: string ): void {
        switch ( type ) {
            case 'account':
            case 'ACCOUNT': {
                this._type = GroupType.ACCOUNT;
                break;
            }
            default: {
                this._type = GroupType.ACCOUNT;
            }
        }
    }

    public toJson(): any {
        return {
            group_id   : this.id,
            name       : this.name,
            description: this.description ? this.description : '',
            client_ids : this.servers.map( ( server: Server ) => server.clientId ),
            type       : this.type
        };
    }

    /**
     * This is to sent up the client_ids as objects for the coms-instance-service for backward compatability
     * This should be removed when we move to group-service
     */
    public toJsonOld(): any {
        return {
            group_id   : this.id,
            name       : this.name,
            description: this.description ? this.description : '',
            client_ids : this.servers.map( ( server: Server ) => server.clientId ),
            type       : this.type
        };
    }
}
