/**
 *
 * @param v1 SnapshotStacktraceVariable
 * @param v2 SnapshotStacktraceVariable
 */
export function stackVariableSort( v1, v2 ): number {
    // 'this' should always come first
    if ( ( v1.name || v1.expression ) === 'this' ) {
        return -1;
    } else if ( ( v2.name || v2.expression ) === 'this' ) {
        return 1;
    }

    // then sort by if they have children to expand to
    if ( v1.hasChildren && !v2.hasChildren ) {
        return -1;
    } else if ( v2.hasChildren && !v1.hasChildren ) {
        return 1;
    }

    // then sort by if there is a value (variables)
    if ( v1.value && !v2.value ) {
        return -1;
    } else if ( v2.value && !v1.value ) {
        return 1;
    }

    // then sort by name
    const nameA = ( v1.name || v1.expression ).toLowerCase();
    const nameB = ( v2.name || v2.expression ).toLowerCase();

    if ( nameA < nameB ) {
        return -1;
    }

    return nameA > nameB ? 1 : 0;
}
