export enum ThreadState {
    RUNNING = 'RUNNING',
    RUNNABLE = 'RUNNABLE',
    DEADLOCKED = 'DEADLOCKED',
    WAITING = 'WAITING',
    TIMED_WAITING = 'TIMED_WAITING',
    TERMINATED = 'TERMINATED',
    BLOCKED = 'BLOCKED',
    SAVED = 'SAVED',
}
