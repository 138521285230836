import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Network extends ServerExtra {
    private readonly _allMacAddresses: string[];

    private readonly _macAddress: string;

    private readonly _hostIpAddress: string;

    constructor( private readonly data ) {
        super( ServerExtraType.NETWORK );

        this._allMacAddresses = data.all_mac_addresses;
        this._macAddress = data.mac_address;
        this._hostIpAddress = data.host_ip_address;

        this.data = null;
    }

    get allMacAddresses(): string[] {
        return this._allMacAddresses;
    }

    get macAddress(): string {
        return this._macAddress;
    }

    get hostIpAddress(): string {
        return this._hostIpAddress;
    }

    public toJson(): any {
        return {
            all_mac_addresses: this.allMacAddresses,
            mac_address      : this.macAddress,
            host_ip_address  : this.hostIpAddress
        };
    }
}
