export class LogFile {
    private _id: string;
    private _name: string;
    private _content: string;

    constructor( private readonly data: { n: string, t: string, l: string | string[] } ) {
        this._id = data.n;
        this._name = data.t;

        this.setContent( data.l );

        this.data = null;
    }

    get content(): string {
        return this._content;
    }

    private setContent( value: string | string[] ): void {
        if ( !value ) {
            this._content = '';

            return;
        }

        this._content = ( value instanceof Array ) ? value.join( '\n' ) : value;
    }

    get name(): string {
        return this._name;
    }

    set name( value: string ) {
        this._name = value;
    }

    get id(): string {
        return this._id;
    }

    set id( value: string ) {
        this._id = value;
    }
}
