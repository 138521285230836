import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'tableColumnSortNumeric'
} )
export class TableColumnSortNumericPipe<T extends Record<string, number> & any> implements PipeTransform {
    transform( value: T[], sortKey: string, sortAscending: boolean ): T[] {
        if ( !sortKey ) {
            return value;
        }
        return [
            ...value.sort( ( a, b ) => {
                const x = a[sortKey];
                const y = b[sortKey];
                if ( x === y ) {
                    return 0;
                } else if ( x < y ) {
                    return sortAscending ? -1 : 1;
                } else if ( x > y ) {
                    return sortAscending ? 1 : -1;
                }

                return 0;
            } )
        ];
    }
}
