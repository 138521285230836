import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { KsPermissionService } from '@intergral/kaleidoscope';
import { evaluateCloudPermission } from './evaluate-cloud.permission';
import { evaluateOnPremPermission } from './evaluate-on-prem.permission';

@Injectable( {
    providedIn: 'root'
} )
export class CloudOnlyGuard implements CanActivate {
    constructor(
        private readonly _authService: AuthService,
        private readonly _ksPermissionService: KsPermissionService,
        private readonly router: Router
    ) {
    }

    public canActivate(): UrlTree | boolean | Observable<boolean | UrlTree> {
        if ( !this._authService.loggedIn ) {
            return false;
        }

        const account = this._authService.loggedInUser.account;

        // Ensure the account has cloud permission
        if ( evaluateCloudPermission( this._ksPermissionService, account ) ) {
            return true;
        }

        // If cloud permission is not granted and the account has On-Premise permission
        if ( evaluateOnPremPermission( account ) ) {
            // Go to on-prem
            return this.router.createUrlTree( [ '/on-premise' ] );
        }

        // Default route for users without necessary permissions or conditions met
        return this.router.createUrlTree( [ '/account' ] );
    }
}
