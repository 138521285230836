import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Env extends ServerExtra {
    private readonly _properties: { [Key: string]: any } = {};

    /**
     * Constructs an Env object.
     *
     * @param data - An array containing objects with Key-value pairs to be stored.
     */
    constructor( private readonly data: { Key: string, Value: any }[] ) {
        super( ServerExtraType.ENV );
        // Initialize _properties with Key-Value pairs from data
        this.data?.forEach( ( { Key, Value } ) => {
            this._properties[Key] = Value;
        } );
    }

    /**
     * Getter for the details.
     *
     * @returns The _properties object.
     */
    public get details(): any {
        return this._properties;
    }

    /**
     * Converts the object to JSON.
     *
     * @returns The _properties object.
     */
    public toJson(): any {
        return this._properties;
    }
}
