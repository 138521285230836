import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ChangeLog } from '../models/ChangeLog';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';

@Injectable( {
    providedIn: 'root'
} )
export class ChangeLogService extends BaseService {
    private readonly changeLogList: BehaviorSubject<ChangeLog[]> = new BehaviorSubject<ChangeLog[]>( null );

    constructor( private readonly http: HttpClient,
                 private readonly _authService: AuthService ) {
        super();
    }

    /**
     * Get a list of change logs
     */
    public getChangeLogs( forceRefresh: boolean = false ): Observable<ChangeLog[]> {
        if ( this.changeLogList.getValue() && !forceRefresh ) {
            return this.changeLogList;
        }

        if ( this.apiHandle ) {
            return this.apiHandle;
        }

        this.apiHandle = this.http.get( `${ environment.urls.api.coms.changelog }v1/changelog?product_id=fusionreactor_cloud&visible=1` )
            .pipe(
                tap( () => this.apiHandle = null ),
                map( ( response: any ) => response.data.map( ( cl: any ) => new ChangeLog( cl ) ) ),
                map( ( changeLogList: ChangeLog[] ) => {
                    changeLogList.sort( ( cl1: ChangeLog, cl2: ChangeLog ) => cl2.date - cl1.date );

                    this.changeLogList.next( changeLogList );

                    return changeLogList;
                } )
            );

        return this.apiHandle;
    }

    /**
     * Fire an API call to coms-changelog-service to mark changelogs as read
     */
    public markUnreadRead(): void {
        const unreadIds = this.changeLogList.getValue()
            .filter( ( cl: ChangeLog ) => !cl.seen )
            .map( ( cl: ChangeLog ) => cl.id );

        // Only call the API to touch these changeLogs if there are new ones to update
        if ( unreadIds.length ) {
            this.http.patch( `${ environment.urls.api.coms.changelog }v1/changelog`, { ids: unreadIds } )
                .subscribe();
        }
    }

    /**
     * Update all changelogs to ensure they are now read
     */
    public updateReadState(): void {
        const modifiedList = this.changeLogList.getValue()
            .map( ( cl: ChangeLog ) => {
                cl.seen = true;
                cl.seenBy.push( this._authService.loggedInUser.id );

                return cl;
            } );

        this.changeLogList.next( modifiedList );
    }
}
