import { Observable, Subscription, throwError } from 'rxjs';

export interface IServiceErrorResponse {
    code: number;
    errors: string[];
    original_error?: any;
    ir_error_code?: string;
}

export abstract class BaseService {
    protected apiHandle: any | Subscription;

    public handleErrorObservableWithExtra( err: any ): Observable<any> {
        if ( err.statusText === '' ) { // 500 errors from undefined behaviour
            return throwError( {
                errors: [ 'Internal server error' ],
                code  : 500
            } );
        }

        if ( err.error && err.statusText !== 'Unknown Error' ) {
            const errJson = err.error;
            const errorList: string[] = [];

            try {
                if ( typeof errJson === 'string' ) {
                    errorList.push( errJson );
                } else if ( typeof errJson === 'object' ) {
                    if ( errJson.errors ) {
                        if ( typeof errJson.errors === 'object' ) {
                            Object.keys( errJson.errors )
                                .forEach( ( key: any ) => {
                                    const value = errJson.errors[key];

                                    if ( Array.isArray( value ) ) {
                                        value.forEach( ( e: string ) => errorList.push( e ) );
                                    } else {
                                        errorList.push( value );
                                    }
                                } );
                        } else {
                            errJson.errors.forEach( ( errMsg: string ) => errorList.push( errMsg ) );
                        }
                    } else {
                        Object.keys( errJson )
                            .forEach( ( key: string ) => {
                                if ( typeof errJson[key] !== 'string' ) {
                                    errJson[key].forEach( ( e: string ) => errorList.push( e ) );
                                } else {
                                    errorList.push( errJson[key] );
                                }
                            } );
                    }
                }
            } catch ( e ) {
                console.error( e );
            }

            return throwError( {
                errors        : errorList,
                code          : err.status,
                original_error: err.error,
                ir_error_code : err.error.ir_error_code
            } );
        }

        return throwError( {
            errors: [ err.statusText ? err.statusText : 'Internal server error' ],
            code  : 500
        } );
    }

    /**
     * Handle API errors
     * @param err any
     */
    public async handleErrorPromise( err: any ): Promise<any> {
        if ( err.statusText === '' ) { // 500 errors from undefined behaviour
            return Promise.reject( {
                errors: [ 'Internal server error' ],
                code  : 500
            } );
        }

        if ( err.error ) {
            const errJson = err.error;
            const errorList: string[] = [];

            try {
                if ( typeof errJson === 'string' ) {
                    errorList.push( errJson );
                } else if ( typeof errJson === 'object' ) {
                    if ( errJson.errors ) {
                        if ( typeof errJson.errors === 'object' ) {
                            Object.keys( errJson.errors )
                                .forEach( ( key: any ) => {
                                    const value = errJson.errors[key];

                                    if ( Array.isArray( value ) ) {
                                        value.forEach( ( e: any ) => errorList.push( e ) );
                                    } else {
                                        errorList.push( value );
                                    }
                                } );
                        } else {
                            errJson.errors.forEach( ( errMsg: any ) => errorList.push( errMsg ) );
                        }
                    } else {
                        Object.keys( errJson )
                            .forEach( ( key: any ) => {
                                errJson[key].forEach( ( e: any ) => errorList.push( e ) );
                            } );
                    }
                }
            } catch ( e ) {
                console.error( e );
            }

            return Promise.reject( {
                errors: errorList,
                code  : err.status
            } );
        }

        console.error( err );

        return Promise.reject( {
            errors: [ 'Something went wrong' ],
            code  : 500
        } );
    }
}
