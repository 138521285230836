import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/common/page-not-found/page-not-found.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { CloudOnlyGuard } from './core/guards/cloud-only.guard';
import { CloudNotExpiredGuard } from './core/guards/cloud-not-expired.guard';

const routes: Routes = [
    {
        path      : '',
        pathMatch : 'full',
        redirectTo: 'overview'
    },
    {
        path        : 'overview',
        canActivate : [ AuthGuard, CloudOnlyGuard ],
        loadChildren: () => import( 'app/dashboard/dashboard.module' )
            .then( m => m.DashboardModule )
    },
    {
        path        : 'account',
        canActivate : [ AuthGuard ],
        loadChildren: () => import( 'app/account/account.module' )
            .then( m => m.AccountModule )
    },
    {
        path        : 'profile', // User details
        canActivate : [ AuthGuard ],
        loadChildren: () => import( 'app/profile/profile.module' )
            .then( m => m.ProfileModule )
    },
    {
        path      : 'licenses',
        pathMatch : 'prefix',
        redirectTo: 'on-premise/licenses'
    },
    {
        path        : 'on-premise', // On-Prem Portal area
        canActivate : [ AuthGuard ],
        loadChildren: () => import( 'app/on-premise/on-premise.module' )
            .then( m => m.OnPremiseModule )
    },
    {
        path        : 'about', // copyright and contacts
        canActivate : [ AuthGuard ],
        loadChildren: () => import( 'app/about/about.module' )
            .then( m => m.AboutModule )
    },
    {
        path       : 'logging',
        canActivate: [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],
        data       : {
        },
        loadChildren: () => import( 'app/logging/logging.module' )
            .then( m => m.LoggingModule )
    },
    {
        path        : 'notifications',
        canActivate : [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],
        loadChildren: () => import( 'app/notifications/notification-list.module' )
            .then( m => m.NotificationListModule )
    },
    {
        path        : 'alerting',
        canActivate : [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],
        loadChildren: () => import( 'app/alerting/alerting.module' )
            .then( m => m.AlertingModule )
    },
    {
        path        : 'auth',
        loadChildren: () => import( 'app/auth/auth.module' )
            .then( m => m.AuthModule )
    },
    {
        path        : 'servers',
        canActivate : [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],
        loadChildren: () => import( 'app/servers/server-list.module' )
            .then( m => m.ServerListModule )
    },
    {
        path       : 'configuration',
        canActivate: [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard, PermissionsGuard ],
        data       : {
            permissions: { any: [ 'services.update', 'servers.update', 'logging.update' ] }
        },
        loadChildren: () => import( 'app/configuration/configuration.module' )
            .then( m => m.ConfigurationModule )
    },
    {
        path       : 'g',
        canActivate: [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],

        loadChildren: () => import( 'app/graf-explorer/graf-explorer.module' )
            .then( m => m.GrafExplorerModule )
    },
    {
        path        : 'applications',
        canActivate : [ AuthGuard, CloudOnlyGuard, CloudNotExpiredGuard ],
        loadChildren: () => import( 'app/applications/application-list.module' )
            .then( m => m.ApplicationListModule )
    },
    {
        path       : '**',
        canActivate: [ AuthGuard ],
        component  : PageNotFoundComponent
    }
];

@NgModule( {
    imports: [
        RouterModule.forRoot( routes, {
            preloadingStrategy : PreloadAllModules,
            onSameUrlNavigation: 'reload'
        } )
    ],
    exports: [
        RouterModule
    ],
    providers: []
} )

export class AppRoutingModule {
}
