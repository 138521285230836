import { Default } from './Default';
import { Docker } from './Docker';
import { Env } from './Env';
import { Host } from './Host';
import { Instance } from './Instance';
import { IServerExtraListType } from './IServerExtraListType';
import { JVM } from './JVM';
import { Network } from './Network';
import { OS } from './OS';
import { Product } from './Product';
import { ServerE } from './ServerE';
import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';
import { Transit } from './Transit';

const types = {
    [ServerExtraType.DOCKER]  : Docker,
    [ServerExtraType.HOST]    : Host,
    [ServerExtraType.INSTANCE]: Instance,
    [ServerExtraType.JVM]     : JVM,
    [ServerExtraType.NETWORK] : Network,
    [ServerExtraType.OS]      : OS,
    [ServerExtraType.ENV]     : Env,
    [ServerExtraType.PRODUCT] : Product,
    [ServerExtraType.SERVER]  : ServerE,
    [ServerExtraType.TRANSIT] : Transit
};

export function convertToServerExtras( rawExtras: any = {} ): IServerExtraListType {
    const extras = {};

    Object.entries( rawExtras )
        .forEach( ( [ key, extra ] ) => {
            extras[key] = convertToServerExtra( key, extra );
        } );

    return extras;
}

export function convertToServerExtra( key: any, rawExtra?: any ): ServerExtra {
    let rawExtraCopy = rawExtra;
    let keyCopy = key;

    if ( typeof keyCopy !== 'string' ) {
        rawExtraCopy = keyCopy;
        keyCopy = rawExtraCopy.type;
    }

    const constructor: new( extra: any ) => ServerExtra = types[keyCopy] || Default;

    const extra = new constructor( rawExtraCopy );

    if ( extra instanceof Default ) {
        // tslint:disable-next-line:no-console
        console.error( `Server info type "${ keyCopy }" not found, please contact support.` );
    }

    return extra;
}
