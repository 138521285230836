import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
    KsBannerModule,
    KsDirectivesModule,
    KsDropdownModule,
    KsHotkeyModule,
    KsIconModule,
    KsLibModule,
    KsLoadingModule,
    KsLoadingService,
    KsNagModule,
    KsNotificationModule,
    KsServicesModule,
    KsSpinnerModule,
    KsToolbarModule
} from '@intergral/kaleidoscope';
import { promLanguageDefinition } from 'monaco-promql';
import { CookieService } from 'ngx-cookie-service';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { ApiUrlInterceptor } from './core/interceptors/api-url.interceptor';
import { AuthHeadersInterceptor } from './core/interceptors/auth-headers.interceptor';
import { LaunchDarklyService } from './core/services/launchdarkly.service';
import { CustomErrorHandler } from './error-handler';
import { CommonComponentsModule } from './shared/components/common/common-components.module';
import { OpsPilotModule } from './ops-pilot/ops-pilot.module';
import { Modal } from './shared/components/modal/modal.module';

@NgModule( {
    declarations: [ AppComponent ],
    imports     : [
        Modal,
        AppRoutingModule,
        CommonModule,
        BrowserModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        KsLibModule.forRoot(),
        OpsPilotModule,
        KsServicesModule.forRoot(),
        KsBannerModule,
        KsToolbarModule,
        KsNotificationModule,
        KsHotkeyModule,
        KsLoadingModule,
        KsIconModule,
        KsNagModule,
        KsSpinnerModule,
        HttpClientModule,
        KsDropdownModule,
        KsDirectivesModule,
        MonacoEditorModule.forRoot( {
            onMonacoLoad: () => {
                const languageId = promLanguageDefinition.id;
                const monaco: any = ( <any>window ).monaco;

                monaco.editor.defineTheme( 'intergral-night', {
                    base   : 'vs-dark',
                    inherit: true,
                    rules  : [ { background: '#111217' } ],
                    colors : {
                        'editor.background': '#111217'
                    }
                } );

                monaco.editor.defineTheme( 'intergral-cool', {
                    base   : 'vs',
                    inherit: true,
                    rules  : [ { background: '#FFFFFF' } ],
                    colors : {
                        'editor.background': '#FFFFFF'
                    }
                } );

                monaco.languages.register( promLanguageDefinition );
                monaco.languages.onLanguage( languageId, () => {
                    promLanguageDefinition.loader()
                        .then( ( mod: any ) => {
                            monaco.languages.setMonarchTokensProvider( languageId, mod.language );
                            monaco.languages.setLanguageConfiguration( languageId, mod.languageConfiguration );
                            monaco.languages.registerCompletionItemProvider( languageId, mod.completionItemProvider );
                        } );
                } );
            }
        } ), CommonComponentsModule, OpsPilotModule
    ],
    providers: [
        CookieService,
        {
            provide : ErrorHandler,
            useClass: CustomErrorHandler,
            deps    : [ Injector, LaunchDarklyService ]
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi   : true
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthHeadersInterceptor,
            multi   : true,
            deps    : [ Router, KsLoadingService, Injector ]
        }
    ],
    bootstrap: [ AppComponent ]
} )
export class AppModule {
}
