import moment from 'moment';
import { EventSnapshotStacktrace } from './EventSnapshotStacktrace';
import { SnapshotVariableReference } from './EventSnapshotStacktraceVariable';

export interface IEventSnapshotSource {
    type: string;
    message: string;
    class: string;
}

export interface IEventSnapshot {
    id: string;
    ts: number;
    source: IEventSnapshotSource;
    stacktrace: any[];
}

export class EventSnapshotRecord {
    private readonly _id: string;
    private readonly _source: IEventSnapshotSource;
    private readonly _timestamp: number;
    readonly _stacktrace: EventSnapshotStacktrace[];
    private readonly _timestampFormatted: string;
    private readonly _timestampFormattedFixed: string;
    private readonly _variableRef: SnapshotVariableReference = new SnapshotVariableReference();

    constructor( data: IEventSnapshot ) {
        this._id = data.id;
        this._timestamp = data.ts;
        this._source = data.source;

        const momentObj = moment( this._timestamp );

        this._timestampFormatted = momentObj.format( '[on] MMM Do [at] LTS' );
        this._timestampFormattedFixed = momentObj.format( 'll LTS' );

        this._stacktrace = ( data.stacktrace || [] ).map( ( trace: any, index: number ) => new EventSnapshotStacktrace( {
            ...trace,
            id: index
        }, this._id, this._variableRef ) );
    }

    get stacktrace(): EventSnapshotStacktrace[] {
        return this._stacktrace;
    }
}
