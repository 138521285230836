import { formattedDurationToNumber } from '../../../shared/components/common/transaction-duration/transaction-duration.component';
import { IDate } from '../IDate';
import { FLAVOR_CONFIG, FLAVOR_GROUPS } from './FlavorTypeUtils';
import { SubTransaction } from './SubTransaction';
import { IFlavorStat, IGroupedFlavor } from './Transaction';

export enum TransactionReasonCode {
    ERROR = 'ERR',
    SLOW = 'SLO',
    LONG = 'LON',
}

export type ThrowableTraceLine = {
    class_name: string,
    native: boolean,
    method_name: string,
    source_file: string,
    line_number: number
}

type throwable = {
    message: string,
    type: string,
    typeShort: string,
    stack: ThrowableTraceLine[]
}

export interface IWebRequestProperties {
    amf_methods: string;
    client_address: string;
    full_url: string;
    jdbc: {
        execution_avg: string;
        execution_max: string;
        execution_min: string;
        execution_total: string;
        queries_run: string;
        row_count_avg: string;
        row_count_max: string;
        row_count_min: string;
        row_count_total: string;
        total_avg: string;
        total_max: string;
        total_min: string;
        total_total: string
    };
    method: string;
    query_string: string;
    request_length: number;
    status: string;
    status_code: string;
    stream_metrics?: {
        buffer_size: string;
        bytes: string;
        flushes: string;
        rate: string;
        start: IDate;
        stop: IDate;
        time_open: string;
        time_to_first_byte: string;
        time_to_last_byte: string
    };
    thread_cpu: string;
    url: string;
    user_agent: string;
}

export class TransactionDetail {
    private readonly _applicationName: string;
    private readonly _applicationSource: string;
    private readonly _bufferSize: number;
    private readonly _characterEncoding: string;
    private readonly _committed: string;
    private readonly _compressed: boolean;
    private readonly _contentType: string;
    private readonly _contextPath: string;
    private readonly _cpExcluded: boolean;
    private readonly _cullCount: number;
    private readonly _description: string;
    private readonly _duration: string;
    private readonly _explanation: string;
    private readonly _flavor: string;
    private readonly _flavorId: number;
    private readonly _clientId: string;
    private readonly _headerStatus: string;
    private readonly _id: string;
    private readonly _jdbcProperties: {
        execution_time: string;
        name: string;
        prepared_statement: boolean;
        row_count: string;
        rows_limited: boolean;
        statement: string;
        total: number;
    };

    private readonly _jdbcSubs: any[];
    private readonly _masterTransaction: string;
    private readonly _memoryEnd: {
        free: string;
        max: string;
        percent_used: string;
        total: string;
        total_free: string;
        used: string;
    };

    private readonly _memoryStart: {
        free: string;
        max: string;
        percent_used: string;
        total: string;
        total_free: string;
        used: string;
    };

    private readonly _parentSpanId: string;
    private readonly _parentTransaction: string;
    private readonly _profile: string;
    private readonly _eventSnapshotId: string;
    private readonly _protocol: string;
    private readonly _reason: string;
    private readonly _reasons: string[] = [];
    private readonly _rejected: string;
    private readonly _requestSessionId: string;
    private readonly _requestHeaders: [ string, string ][];
    private readonly _responseHeaders: [ string, string ][];
    private readonly _saved: {
        owner: boolean;
        other: boolean;
    };

    private readonly _secure: string;
    private readonly _softKill: boolean;
    private readonly _spanId: string;
    private readonly _startTimestamp: number;
    private readonly _status: string;
    private readonly _statusCode: number;
    private readonly _stopTimestamp: number | string;
    private readonly _stopped: boolean;
    private readonly _subFlavor: string;
    private readonly _subs: SubTransaction[];
    private readonly _threadAllocatedBytes: string;
    private readonly _threadId: number;
    private readonly _threadName: string;
    private readonly _throwables: throwable[];
    private readonly _time: string;
    private readonly _transactionId: string;
    private readonly _traceId: string;
    private readonly _transactionName: string;
    private readonly _transactionSource: string;
    private readonly _webRequestProperties: IWebRequestProperties;
    private readonly _throType: string;
    private readonly _class: any = {
        tag : null,
        icon: null
    };

    private readonly _groupedFlavors: IGroupedFlavor[] = [];
    private readonly _hasReasonError: boolean = false;
    private readonly _hasReasonSlow: boolean = false;
    private readonly _hasReasonLong: boolean = false;

    constructor( private readonly data: any ) {
        this._applicationName = data.application_name;
        this._applicationSource = data.application_source;
        this._bufferSize = data.buffer_size;
        this._characterEncoding = data.character_encoding;
        this._committed = data.committed;
        this._compressed = data.compressed;
        this._contentType = data.content_type;
        this._contextPath = data.context_path;
        this._cpExcluded = data.cp_excluded;
        this._cullCount = data.cull_count;
        this._description = data.description;
        this._duration = data.duration;
        this._explanation = data.explanation;
        this._flavor = data.flavor;
        this._flavorId = data.flavor_id;
        this._flavorStats = data.flavor_stats;
        this._clientId = data.client_id ? data.client_id : data.gruid;
        this._headerStatus = data.header_status;
        this._id = data.id;
        this._jdbcProperties = data.jdbc_properties;
        this._jdbcSubs = ( data.subs || [] ).filter( ( sub: any ) => sub.flavor === 'JDBCRequest' );
        this._masterTransaction = data.master_transaction;
        this._memoryEnd = data.memory_end;
        this._memoryStart = data.memory_start;
        this._parentSpanId = data.parentSpanId;
        this._parentTransaction = data.parent_transaction;
        this._pid = data.pid ? data.pid : [];
        this._eventSnapshotId = data.event_snapshot_id;
        this._profile = data.profile;
        this._protocol = data.protocol;
        this._reason = data.reason;
        this._reasons = data.reasons || [];
        this._hasReasonError = this._reasons.includes( 'ERR' );
        this._hasReasonLong = this._reasons.includes( 'LON' );
        this._hasReasonSlow = this._reasons.includes( 'SLO' );
        this._rejected = data.rejected;
        this._requestSessionId = data.request_session_id;
        this._requestHeaders = data.request_headers ? Object.entries( data.request_headers ) : [];
        this._responseHeaders = data.response_headers ? Object.entries( data.response_headers ) : [];
        this._saved = data.saved;
        this._secure = data.secure;
        this._softKill = data.soft_kill;
        this._spanId = data.spanId;
        this._stopped = data.stopped;
        this._startTimestamp = data.start;
        this._stopTimestamp = data.stop;
        this._status = data.status;
        this._subFlavor = data.sub_flavor;
        this._subs = data.subs ? data.subs.map( ( sub: any ) => new SubTransaction( sub ) ) : [];
        this._threadAllocatedBytes = data.thread_allocated_bytes;
        this._threadId = data.thread_id;
        this._threadName = data.thread_name;
        this._throwables = data.throwables || [];
        this._time = data.time;
        this._transactionId = data.transaction_id;
        this._transactionName = data.transaction_name;
        this._traceId = data.trace_id;
        this._transactionSource = data.transaction_source;

        this._webRequestProperties = data.web_request_properties;

        if ( this._webRequestProperties ) { // if properties are available
            const jdbc = this._webRequestProperties.jdbc;

            Object.keys( jdbc )
                .forEach( ( obj: any ) => {
                    let val = jdbc[obj] as string;

                    if ( typeof val === 'string' ) { // replace ',' with '' for number parseing > 1000
                        val = val.replace( /,/g, '' );
                    }

                    jdbc[obj] = parseInt( val, 10 ) || 0; // protect against null
                } );
        }
        if ( data.properties ) {
            this._statusCode = data.properties.rs;
        }

        this._throType = data.throType;

        this.prioritizeReason();

        if ( data.web_request_properties && data.web_request_properties.stream_metrics ) {
            this._webRequestProperties.stream_metrics.start = data.web_request_properties.stream_metrics.start;
            this._webRequestProperties.stream_metrics.stop = data.web_request_properties.stream_metrics.stop;
        }

        this._flavorStats = ( data.flavor_stats || [] ).map( ( flv: IFlavorStat ) => {
            const parts = flv.id.split( '/' );

            return {
                ...flv,
                flavor   : parts[0],
                subflavor: parts.slice( 1 )
                    .join( '/' )
            };
        } );

        this.flavorStats.forEach( ( stat: IFlavorStat ) => {
            if ( FLAVOR_GROUPS.has( stat.flavor ) ) {
                const config = FLAVOR_CONFIG.get( FLAVOR_GROUPS.get( stat.flavor ) );
                const existing = this._groupedFlavors.find( ( a: IGroupedFlavor ) => a.name === config.name );

                if ( existing ) {
                    existing.value += stat.aggregate.count;
                    existing.duration += stat.aggregate.sum;
                } else {
                    this._groupedFlavors.push( {
                        ...config,
                        value   : stat.aggregate.count,
                        duration: stat.aggregate.sum
                    } );
                }
            }
        } );

        Array.from( FLAVOR_CONFIG.values() )
            .forEach( ( config: any ) => {
                const existing = this._groupedFlavors.find( ( a: any ) => a.icon === config.icon );

                if ( !existing ) {
                    this._groupedFlavors.push( {
                        ...config,
                        value   : 0,
                        duration: 0
                    } );
                }
            } );

        this.data = null;
    }

    private _pid: string[];

    get pid(): string[] {
        if ( !this._pid ) {
            return this._pid;
        }

        return this._pid.map( ( id: string ) => {
            return id.replace( /\//g, '_' );
        } );
    }

    set pid( value: string[] ) {
        this._pid = value;
    }

    private _flavorStats: IFlavorStat[] = [];

    get flavorStats(): IFlavorStat[] {
        return this._flavorStats;
    }

    set flavorStats( value: IFlavorStat[] ) {
        this._flavorStats = value;
    }

    get hasReasonLong(): boolean {
        return this._hasReasonLong;
    }

    get hasReasonSlow(): boolean {
        return this._hasReasonSlow;
    }

    get hasReasonError(): boolean {
        return this._hasReasonError;
    }

    get eventSnapshotId(): string {
        if ( !this._eventSnapshotId ) {
            return this._eventSnapshotId;
        }

        return this._eventSnapshotId.replace( /\//g, '_' );
    }

    get class(): any {
        return this._class;
    }

    get throType(): string {
        return this._throType;
    }

    get traceId(): string {
        return this._traceId;
    }

    get applicationName(): string {
        return this._applicationName;
    }

    get applicationSource(): string {
        return this._applicationSource;
    }

    get cullCount(): number {
        return this._cullCount;
    }

    get bufferSize(): number {
        return this._bufferSize;
    }

    get characterEncoding(): string {
        return this._characterEncoding;
    }

    get statusCode(): number {
        return this._statusCode;
    }

    get committed(): string {
        return this._committed;
    }

    get compressed(): boolean {
        return this._compressed;
    }

    get contentType(): string {
        return this._contentType;
    }

    get contextPath(): string {
        return this._contextPath;
    }

    get cpExcluded(): boolean {
        return this._cpExcluded;
    }

    get description(): string {
        return this._description;
    }

    get duration(): string {
        return this._duration;
    }

    get calculatedStop(): number | string {
        return ( ( this._stopTimestamp as any ) === 'Unknown' )
            ? +new Date( this._startTimestamp ) + formattedDurationToNumber( this._duration )
            : this._stopTimestamp;
    }

    get explanation(): string {
        return this._explanation;
    }

    get flavor(): string {
        return this._flavor;
    }

    get flavorId(): number {
        return this._flavorId;
    }

    /**
     * @deprecated Use clientId instead.
     */
    get gruid(): string {
        return this._clientId;
    }

    get clientId(): string {
        return this._clientId;
    }

    get headerStatus(): string {
        return this._headerStatus;
    }

    get id(): string {
        return this._id;
    }

    get jdbcProperties(): {
        execution_time: string;
        name: string;
        prepared_statement: boolean;
        row_count: string;
        rows_limited: boolean;
        statement: string;
        total: number
        } {
        return this._jdbcProperties;
    }

    get jdbcSubs(): any[] {
        return this._jdbcSubs;
    }

    get masterTransaction(): string {
        return this._masterTransaction;
    }

    get memoryEnd(): { free: string; max: string; percent_used: string; total: string; total_free: string; used: string } {
        return this._memoryEnd;
    }

    get memoryStart(): { free: string; max: string; percent_used: string; total: string; total_free: string; used: string } {
        return this._memoryStart;
    }

    get parentTransaction(): string {
        return this._parentTransaction;
    }

    get parentSpanId(): string {
        return this._parentSpanId;
    }

    get profile(): string {
        return this._profile;
    }

    get protocol(): string {
        return this._protocol;
    }

    get reason(): string {
        return this._reason;
    }

    get reasons(): string[] {
        return this._reasons;
    }

    get rejected(): string {
        return this._rejected;
    }

    get requestSessionId(): string {
        return this._requestSessionId;
    }

    get requestHeaders(): [ string, string ][] {
        return this._requestHeaders;
    }

    get responseHeaders(): [ string, string ][] {
        return this._responseHeaders;
    }

    get saved(): { owner: boolean; other: boolean } {
        return this._saved;
    }

    get secure(): string {
        return this._secure;
    }

    get softKill(): boolean {
        return this._softKill;
    }

    get spanId(): string {
        return this._spanId;
    }

    get startTimestamp(): number {
        return this._startTimestamp;
    }

    get status(): string {
        return this._status;
    }

    get stopTimestamp(): number | string {
        return this._stopTimestamp;
    }

    get stopped(): boolean {
        return this._stopped;
    }

    get subFlavor(): string {
        return this._subFlavor;
    }

    get subs(): SubTransaction[] {
        return this._subs;
    }

    get threadAllocatedBytes(): string {
        return this._threadAllocatedBytes;
    }

    get threadId(): number {
        return this._threadId;
    }

    get threadName(): string {
        return this._threadName;
    }

    get throwables(): any[] {
        return this._throwables;
    }

    get time(): string {
        return this._time;
    }

    get transactionId(): string {
        return this._transactionId;
    }

    get transactionName(): string {
        return this._transactionName;
    }

    get transactionSource(): string {
        return this._transactionSource;
    }

    get webRequestProperties(): IWebRequestProperties {
        return this._webRequestProperties;
    }

    get groupedFlavors(): IGroupedFlavor[] {
        return this._groupedFlavors;
    }

    private accumulatedStrings: string = '';

    get traceString() {
        this.accumulatedStrings = this.throwables.reduce( ( previousLines: string, currentValue: throwable ) => {
            const newThrowableLines = `${ currentValue.type }: ${ currentValue.message }\n`;
            const stackTrace = currentValue.stack.reduce( ( stackLines, stack ) => {
                return `${ stackLines }\n   ${ stack.class_name }: ${ stack.method_name }`;
            }, newThrowableLines );
            return stackTrace;
        }, this.accumulatedStrings );
        return this.accumulatedStrings;
    }

    public save( isSaved: boolean ): void {
        if ( !isSaved ) {
            this.saved.owner = false;
            this.saved.other = false;
        } else {
            this.saved.owner = true;
        }
    }

    public getTransformedId(): string {
        return this.id.replace( /\//g, '_' );
    }

    /**
     * Set the class and icon used to represent the type of transaction
     */
    protected prioritizeReason(): void {
        if ( this.hasReasonError ) {
            this._class.tag = 'error';
            this._class.label = 'Error';

            if ( this.throType && this.throType !== 'Unknown' ) {
                this._class.tag = 'error text-bold';
                this._class.icon = 'icon-warning-filled';
                this._class.label = this.throType;
            } else {
                this._class.icon = 'icon-tree-expand';
            }
        } else if ( this.hasReasonLong && !this.hasReasonSlow ) {
            this._class.tag = 'slow';
            this._class.label = 'Slow';
            this._class.icon = 'icon-stopwatch';
        } else if ( this.hasReasonSlow ) {
            this._class.tag = 'very-slow';
            this._class.label = 'Very slow';
            this._class.icon = 'icon-stopwatch';
        } else {
            this._class.tag = '';
            this._class.label = '';
        }
    }
}
