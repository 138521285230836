<div class="onboarding-guide" fxLayout="column" *ngIf="selectedLanguage">
    <button class="onboarding-guide-close" (click)="openSkipDialog()">
        <span class="icon-x"></span>
    </button>
    <div fxLayoutAlign="center start">
        <h1 class="onboarding-header">Welcome to FusionReactor Cloud!</h1>
    </div>
    <div fxFlex fxLayout="column">
        <div class="onboarding-guide-indicator" fxLayout="row">
            <ks-step-indicator #stepIndicator (select)="stepHandler($event)" [steps]="onboardingSteps"></ks-step-indicator>
        </div>
        <div class="onboarding-guide-steps" [ngSwitch]="stepIndicator.currentActiveTab">
            <div *ngSwitchCase="Onboarding.INITIAL">
                <ng-container *ngTemplateOutlet="initial"></ng-container>
            </div>
            <div *ngSwitchCase="Onboarding.DOWNLOAD">
                <ng-container *ngTemplateOutlet="download"></ng-container>
            </div>
            <div *ngSwitchCase="Onboarding.CONFIGURATION">
                <ng-container *ngTemplateOutlet="configuration"></ng-container>
            </div>
            <div *ngSwitchCase="Onboarding.CONNECTION">
                <ng-container *ngTemplateOutlet="connection"></ng-container>
            </div>
        </div>
        <span class="onboarding-doc-link">
            Further installation information can be found in the
            <a class="link"
               [href]="selectedLanguage.docs"
               target="_blank">
                FusionReactor Documentation ({{ selectedLanguage.niceName }})</a>.
        </span>
        <div fxLayout="row" fxLayoutAlign="end start">
            <button class="btn" (click)="stepIndicator.prev()"
                    [disabled]="stepIndicator.currentActiveTab === 0"
                    *ngIf="!(stepIndicator.currentActiveTab === Onboarding.CONNECTION && selectedLanguage.niceName == 'Docker')">
                Previous
            </button>
            <button class="btn" (click)="stepIndicator.next()"
                    *ngIf="stepIndicator.currentActiveTab !== Onboarding.CONNECTION && !(stepIndicator.currentActiveTab === Onboarding.DOWNLOAD && selectedLanguage.niceName == 'Docker')">
                Next
            </button>

            <!-- Docker Move Back 2 -->
            <button class="btn" (click)="stepIndicator.move(-2)"
                    [disabled]="stepIndicator.currentActiveTab === 0"
                    *ngIf="stepIndicator.currentActiveTab === Onboarding.CONNECTION && selectedLanguage.niceName == 'Docker'">
                Previous
            </button>
            <!-- Docker Move Forward 2 -->
            <button class="btn" (click)="stepIndicator.move(2)"
                    *ngIf="stepIndicator.currentActiveTab === Onboarding.DOWNLOAD && selectedLanguage.niceName == 'Docker'">
                Next
            </button>
            <button class="btn" (click)="finish()"
                    [disabled]="!connectionMade"
                    *ngIf="stepIndicator.currentActiveTab === Onboarding.CONNECTION">
                Finish
            </button>
        </div>
    </div>
</div>

<ng-template #initial>
    <div fxLayout="column">
        <div fxFlex="50">
            <h2>Select a server</h2>
            <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="flex-start">
                <div fxFlex="0 1 calc(20% - 20px)" fxLayout="column" fxLayoutAlign="start center"
                     class="onboarding-platform-selector clickable"
                     *ngFor="let language of languages; trackBy:trackByIdFn"
                     (click)="selectedLanguage = language"
                     [class.onboarding-platform-selected]="selectedLanguage === language">
                    <h3>{{language.niceName}}</h3>
                    <img class="onboarding-language-logo" src="{{language.logo}}" alt="Logo">
                </div>
            </div>
        </div>

        <div fxFlex="50" *ngIf="!['CommandBox', 'Docker'].includes(selectedLanguage.niceName)">
            <h2>Select an operating system</h2>
            <div fxLayout="row">
                <div *ngFor="let platform of platforms; trackBy:trackByIdFn"
                     fxFlex="33" fxLayout="column" fxLayoutAlign="start center"
                     class="onboarding-platform-selector clickable"
                     (click)="selectedPlatform = platform"
                     [class.onboarding-platform-selected]="selectedPlatform === platform">
                    <h3>{{platform.niceName}}</h3>
                    <img class="onboarding-platform-logo" src="{{platform.logo}}" alt="Logo">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #download>
    <div *ngIf="!['CommandBox', 'Docker'].includes(selectedLanguage.niceName)">
        <h2>Download</h2>
        <div fxLayout="column" fxLayoutGap="0.5em">
            <span>Run the commands below in CMD prompt/powershell on windows or in terminal on MacOS and Linux.</span>
            <div>
            <span>
                Run the following commands to create and navigate into a FusionReactor Directory:
            </span>
            </div>
            <app-code-snippet [codeContent]=replaceInstanceName(selectedPlatform.config.mkdir)></app-code-snippet>
            <app-code-snippet [codeContent]=replaceInstanceName(selectedPlatform.config.cd)></app-code-snippet>
            <span>
            Run the following commands to download FusionReactor and the native library into the FusionReactor directory:
    </span>
            <app-code-snippet [codeContent]="'curl '+downloadRoot+'fusionreactor.jar -o fusionreactor.jar'"></app-code-snippet>
            <app-code-snippet
                    [codeContent]="'curl '+downloadRoot+selectedPlatform.libName +' -o ' + selectedPlatform.libName"></app-code-snippet>
            <div>
            <span>
                You can always access a download link for the latest version of FusionReactor and the debug libraries on
                your account settings page.
            </span>
            </div>
        </div>
    </div>
    <div *ngIf="selectedLanguage.niceName === 'CommandBox'">
        <h2>Download</h2>
        <span>Inside CommandBox run:</span>
        <div fxLayout="column" fxLayoutGap="0.5em">
            <app-code-snippet [codeContent]="'install commandbox-fusionreactor'"></app-code-snippet>
        </div>
    </div>
    <div *ngIf="selectedLanguage.niceName === 'Docker'">
        <h2>Install FusionReactor in Docker</h2>
        <p>In the <a class="link" target="_blank"
                     href="https://github.com/intergral/fusionreactor-docker">fusionreactor-docker</a> GitHub repository <a
                class="link" target="_blank" href="https://github.com/intergral/fusionreactor-docker">https://github.com/intergral/fusionreactor-docker</a>
            we have example Dockerfiles for:</p>
        <ul>
            <li><a class="link" target="_blank" href="https://github.com/intergral/fusionreactor-docker/tree/master/coldfusion">ColdFusion</a>
            </li>
            <li><a class="link" target="_blank" href="https://github.com/intergral/fusionreactor-docker/tree/master/commandbox">CommandBox</a>
            </li>
            <li><a class="link" target="_blank"
                   href="https://github.com/intergral/fusionreactor-docker/tree/master/lucee">Lucee</a></li>
            <li><a class="link" target="_blank"
                   href="https://github.com/intergral/fusionreactor-docker/tree/master/tomcat">Tomcat</a></li>
            <li><a class="link" target="_blank"
                   href="https://github.com/intergral/fusionreactor-docker/tree/master/jetty">Jetty</a></li>
        </ul>
        <p>You can take these resources and use these as examples for your own Dockerfiles.</p>
    </div>
</ng-template>

<ng-template #configuration>
    <div fxLayout="column" fxLayoutGap="0.5em" *ngIf="selectedLanguage.niceName !== 'CommandBox'">
        <h2>Configuration</h2>
        <span *ngIf="selectedLanguage.niceName !== 'ColdFusion'">
            1. Ensure your application is stopped.
            <br>2. Add the following into the JVM arguments for the application (see <a class="link" [href]="selectedLanguage.docs" target="_blank">docs</a> for an example).
        </span>
        <span *ngIf="selectedLanguage.niceName === 'ColdFusion'">
            1. Ensure your ColdFusion server is stopped.
            <br>2. Add the following into your jvm.config located in the ColdFusion cfusion/bin directory (see <a class="link" [href]="selectedLanguage.docs" target="_blank">docs</a> for an example).
        </span>
        <app-code-snippet
                [codeContent]="replaceInstanceName('-javaagent:'+selectedPlatform.baseDir + 'fusionreactor.jar=address=8088\n') + replaceInstanceName('-agentpath:'+selectedPlatform.baseDir+selectedPlatform.libName+'\n-Dfrlicense=' + licenseKey + '\n-Dfradminpassword=ADMIN_PASSWORD')">
        </app-code-snippet>

        <div fxLayout="column" fxLayoutGap="0.5em">
            <span>3. (optional) Servers can be placed into groups manually in the cloud user interface or by using the option below:</span>
            <app-code-snippet [codeContent]="'-Dfr.cloud.group=Application1'"></app-code-snippet>
        </div>

        <div>
            <span>4. Now start your {{ selectedLanguage.niceName }} server as normal.</span>
            <br>
        </div>
    </div>
    <div fxLayout="column" fxLayoutGap="0.5em" *ngIf="selectedLanguage.niceName === 'CommandBox'">
        <h2>Configuration</h2>
        <span>Inside CommandBox run:</span>
        <div fxLayout="column" fxLayoutGap="0.5em">
            <app-code-snippet [codeContent]="'fusionreactor register '+ licenseKey"></app-code-snippet>
        </div>
    </div>
</ng-template>

<ng-template #connection>
    <div class="onboarding-connection" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">

        <ng-container *ngIf="!showRetryConnection else manual;">
            <ng-container *ngIf="!connectionMade else connected">
                <ks-spinner></ks-spinner>
                <span>Checking connection...</span>
            </ng-container>

            <ng-template #connected>
                <i class="icon icon-check-filled" fxFlex="100px"></i>
                <span>Connected!</span>
            </ng-template>

        </ng-container>
    </div>

    <ng-template #manual>

        <div class="onboarding-retry-connection" fxFlex="130px" fxLayout="column" fxLayoutAlign="center center">

            <div fxFlex="auto" fxLayout="column" fxLayoutAlign="space-around center">
                <button class="btn"
                        (click)="stepHandler( { title : 3 } )">
                    Retry connection
                </button>
            </div>

            <span>
                A connection could not be automatically established
            </span>

        </div>

    </ng-template>

</ng-template>
