import { stackVariableSort } from '../../../helper-functions/stack-variable-sort';

export class SnapshotVariableReference {
    public static readonly NO_REFERENCE_FOUND = 'No variable reference found';

    public readonly variables: { [key: number]: any } = {};
    private _className: string;

    // tslint:disable
    public getVariable( raw: any, className: string, snapshotId: string, iteration?: number ): EventSnapshotStacktraceVariable {
        const existing: any = this.variables[raw.id || raw.rawId];

        this._className = className;

        raw = { ...raw };

        if ( raw.id && existing ) {
            if ( existing.children && existing.children.length && raw.children === undefined && ( !iteration || iteration < 4 ) ) {
                raw.children = existing.children;
                raw.referenced = true;

                if ( iteration ) {
                    iteration++;
                } else {
                    iteration = 1;
                }
            }

            if ( existing.hash ) {
                raw.hash = existing.hash;
            }

            if ( existing.type && !raw.type ) {
                raw.type = existing.type;
            }

            if ( 'value' in existing ) {
                raw.value = existing.value;
            }

            if ( existing.size ) {
                raw.size = existing.size;
            }

            return new EventSnapshotStacktraceVariable( raw, snapshotId, this, iteration );
        }

        // if this code is reached, then an error has occurred
        console.error( `No reference to variable with id ${ raw.id }, snapshot: ${ snapshotId }` );

        raw.value = SnapshotVariableReference.NO_REFERENCE_FOUND;

        return new EventSnapshotStacktraceVariable( raw, snapshotId, this );
    }

    // tslint:enable

    get className(): string {
        return this._className;
    }

    public addToFoundVariables( data: any, references: any[] = [] ): { raw: any, references: any[] } {
        if ( !data ) {
            return {
                references,
                raw: ''
            };
        }

        const reference = this.variables[data.id];

        /**
         * If the variable has already been added
         */
        if ( reference !== undefined ) {
            return {
                references,
                raw: `${ data.name }${ reference.rawString }`
            };
        }

        /**
         * if the variable needs to be referenced
         */
        if ( !( 'value' in data ) ) {
            references.push( {
                id  : data.id,
                name: data.name
            } );

            return {
                references,
                raw: data.name
            };
        } else if ( data.value === null ) {
            // this shows the value on the UI - otherwise nothing appears for the value
            data.value = 'null';
        }

        let rawString = '';

        if ( 'map' in data && data.map != null ) {
            data.children = [
                ...data.map.map( ( item: { key: any, value: any } ) => {
                    if ( !item.key ) {
                        return item;
                    }

                    return {
                        name : item.key.value,
                        value: item.value.value,
                        hash : item.value.hash,
                        type : item.value.type,
                        id   : item.value.id
                    };
                } )
            ];
        }

        if ( 'collection' in data && data.collection != null ) {
            data.children = [ ...data.collection ];
        }

        if ( 'array' in data && data.array != null ) {
            data.children = [ ...data.array ];
        }

        if ( data.children ) {
            data.children.forEach( ( child: any ) => {
                // Convert fields to children for the tree library
                if ( child.fields ) {
                    child.children = [ ...child.fields ];
                }

                const childRefs = this.addToFoundVariables( child );

                if ( childRefs ) {
                    if ( childRefs.references ) {
                        // tslint:disable-next-line:no-parameter-reassignment
                        references = childRefs.references.concat( references );
                    }

                    if ( childRefs.raw ) {
                        rawString += `${ childRefs.raw }`;
                    }
                }
            } );
        }

        const copy = {
            ...data
        };

        delete copy.id;
        delete copy.referenced;
        delete copy.children;
        delete copy.name;

        if ( data.children ) {
            delete copy.value;
        }

        const details = Object.values( copy );

        if ( references.length > 0 ) {
            data.references = references;
        }

        data.rawString = `${ rawString }${ details.toString() }`;

        this.variables[data.id] = data;

        return {
            raw: `${ data.rawString }${ data.name }`,
            references
        };
    }
}

interface IEventSnapshotStacktraceVariable {
    readonly var_id: number;
    readonly hasChildren: boolean;
    readonly iteration: number;
    readonly referenced: boolean;
    readonly rawChildren: any[];
    readonly rawId: number;
    readonly name: string;
    readonly type: string;
    readonly value: string;
    readonly hash: string;
    children: any[];
    readonly class: string;
    readonly namespace: string;
    readonly size: number;
    matched: boolean;
}

export class EventSnapshotStacktraceVariable implements IEventSnapshotStacktraceVariable {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _type: string;
    private readonly _value: string;
    private readonly _hash: string;
    private readonly _class: string;
    private readonly _namespace: string;
    private readonly _size: number;
    private readonly _rawChildren: IEventSnapshotStacktraceVariable[];
    private readonly _referenced: boolean = false;
    private readonly _rawData: string;
    private readonly _iteration: number;
    private _children: IEventSnapshotStacktraceVariable[];
    private _matched: boolean = false;

    constructor(
        private readonly data: any,
        private readonly snapshotId: string,
        private readonly _variableRef: SnapshotVariableReference,
        iteration?: number ) {
        this._id = data.id;
        this._name = data.name;
        this._type = data.type;
        this._hash = data.hash;
        this._size = data.size;
        this._rawChildren = data.children || [];
        this._referenced = data.referenced;
        this._iteration = iteration;

        const fullNamespace = ( data.type || '' ).split( '.' );

        this._class = fullNamespace.pop();
        this._namespace = fullNamespace.join( '.' );
        this._value = data.value;

        if ( data.references ) {
            data.references.forEach( ( value: { id: number, name: string } ) => {
                data.rawString += this.appendRawString( value );
            } );
        }

        if ( this._referenced ) {
            const resp: any = _variableRef.variables[this._id];

            data.rawString = resp.rawString;

            const loopedReference = ( resp.references || [] ).filter( ( reference: any ) => reference.id === this._id ).length !== 0;

            if ( loopedReference ) {
                data.rawString += resp.value;
            }
        }

        this._rawData = `${ data.rawString }${ data.name }` || '';
    }

    public get children(): IEventSnapshotStacktraceVariable[] {
        return this._children;
    }

    public get hasChildren(): boolean {
        return this._rawChildren.length > 0;
    }

    /**
     * Recursive search functions to retrieve matching snapshots
     * @param val value
     */
    public search( val: string ): number {
        if ( !val ) {
            return 0;
        }

        return this._rawData.toLowerCase()
            .split( val.toLowerCase() ).length - 1;
    }

    public get iteration(): number {
        return this._iteration;
    }

    public get referenced(): boolean {
        return this._referenced;
    }

    public getChildrenNodes(): EventSnapshotStacktraceVariable[] {
        // maps each rawChild to a SnapShotStackTraceVariable and orders them
        return this._rawChildren.map( ( child: any ) => this._variableRef.getVariable( child, this._variableRef.className,
            this.snapshotId, this._iteration ) )
            .sort( stackVariableSort );
    }

    public set children( value: IEventSnapshotStacktraceVariable[] ) {
        this._children = value;
    }

    public get var_id(): number {
        return this._id;
    }

    public get rawChildren(): IEventSnapshotStacktraceVariable[] {
        return this._rawChildren;
    }

    public get rawId(): number {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public get type(): string {
        return this._type;
    }

    public get value(): string {
        return this._value;
    }

    public get hash(): string {
        return this._hash;
    }

    public get class(): string {
        return this._class;
    }

    public get namespace(): string {
        return this._namespace;
    }

    public get size(): number {
        return this._size;
    }

    public contains( search: string ): boolean {
        // tslint:disable-next-line:no-parameter-reassignment
        search = search.toLowerCase();

        if ( this.name && this.name.toLowerCase()
            .includes( search ) ) {
            return true;
        }

        if ( this.class && this.class.toLowerCase()
            .includes( search ) ) {
            return true;
        }

        if ( this.hash && this.hash.toLowerCase()
            .includes( search ) ) {
            return true;
        }

        return this.value && this.value.toLowerCase()
            .includes( search );
    }

    public set matched( val: boolean ) {
        this._matched = val;
    }

    public get matched(): boolean {
        return this._matched;
    }

    public toJSON(): any {
        return {
            id      : this._id,
            name    : this._name,
            type    : this._type,
            value   : this._value,
            hash    : this._hash,
            size    : this._size,
            children: this.getChildrenNodes()
                .map( variable => variable.toJSON() )
        };
    }

    private appendRawString( value: { id: number, name: string } ): string {
        const resp: any = this._variableRef.variables[value.id];

        if ( !resp ) {
            return '';
        }

        return `${ resp.rawString }${ resp.value }`;
    }
}
