import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trackPrimitiveFn } from '../../../../core/trackByFunctions/track-primitive';

@Component( {
    selector   : 'app-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls  : [ 'page-not-found.component.scss' ]
} )
export class PageNotFoundComponent implements OnInit {
    public readonly trackPrimitiveFn = trackPrimitiveFn;

    public url: string[] = [ '', 'overview' ];

    constructor( private readonly route: ActivatedRoute ) {
    }

    public ngOnInit(): void {
        const isServerRoute = this.route.snapshot.url.some( seg => seg.path === 'servers' );
        const isAppRoute = this.route.snapshot.url.some( seg => seg.path === 'applications' );

        if ( isServerRoute ) {
            this.url = [ '', 'servers', 'detailed' ];
        } else if ( isAppRoute ) {
            this.url = [ '', 'applications' ];
        }
    }
}
