<!-- Link for close button styling-->
<div *ngIf="assistantFeatureEnabled" [class.popout]="showDrawer" id="opspilot-side-drawer">
    <span (click)="triggerToggleEvent()"
          [class.icon-arrow-left]="!showDrawer" [class.icon-arrow-right]="showDrawer"
          class="minimizer tag icon shadow-2 clickable">
       <!-- Arrow icon will be added by the class -->

        <!-- Image will render only when showDrawer is false -->
        <div *ngIf="!showDrawer" class="drawer-image">
            <img class="inner" src="assets/op_logo_inner.png"/>
            <img class="outer" src="assets/op_logo_outer.png"/>
        </div>
    </span>
    <div (mousedown)="startResizing($event)" *ngIf="showDrawer" class="resize-handle"></div>
    <!-- Button for close for mobiles -->
    <button (click)="triggerToggleEvent()"
            *ngIf="isMobileScreen && showDrawer"
            class="close custom-button">
        <span class="material-icons">
            close
        </span>
    </button>

         <iframe class="full-height"
         id="opspilot-side-container" name="opspilot" [src]="opspilotUrl" allow="clipboard-write; microphone; display-capture"></iframe>
</div>
