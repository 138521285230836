import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Host extends ServerExtra {
    private readonly _hostname: string;

    constructor( private readonly data ) {
        super( ServerExtraType.HOST );

        this._hostname = data.hostname;

        this.data = null;
    }

    get hostname(): string {
        return this._hostname;
    }

    public toJson(): any {
        return {
            hostname: this.hostname
        };
    }
}
