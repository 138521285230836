import { BillingProvider, IAccount } from '../interfaces/IAccount';
import { AccountPlanType } from './AccountPlanType';
import { AccountStatus } from './AccountStatus';
import { User } from './User';

export class AccountOnboarding {
    constructor( private readonly _data: any ) {
        this._showInstallHelp = _data.show_install_help;
        this._data = null;
    }

    private _showInstallHelp: boolean;

    get showInstallHelp(): boolean {
        return this._showInstallHelp;
    }

    set showInstallHelp( showInstallHelp ) {
        this._showInstallHelp = showInstallHelp;
    }

    public toJson(): any {
        return {
            show_install_help: this._showInstallHelp
        };
    }
}

export enum ACCOUNT_CONFIG_KEY {
    SERVER_CUBES = 'serverCubes',
}

export class AccountConfiguration {
    constructor( private readonly _data: any ) {
        this._data = null;
    }

    private _serverCubes: any;

    get serverCubes(): any {
        return this._serverCubes;
    }

    set serverCubes( value: any ) {
        this._serverCubes = value;
    }

    public toJson(): any {
        return {
            serverCubes: { ...this._serverCubes }
        };
    }
}

export class Account implements IAccount {
    private readonly _id: string;
    private readonly _license: string;
    private readonly _provider: BillingProvider;
    private readonly _users: User[];
    private readonly _expires: number;
    private readonly _manual: boolean;
    private readonly _closed: boolean;
    private readonly _plan: AccountPlanType;
    private readonly _platforms: string[];
    constructor( private readonly data: any ) {
        this._id = data.id;
        this._name = data.name;
        this._users = data.users ? data.users.map( ( user: any ) => new User( user ) ) : [];
        this._license = data.license && typeof data.license === 'object' ? data.license.license : data.license || '';
        this._provider = data.provider;
        this._expires = data.expires ? data.expires : null;
        this._manual = data.manual === true;
        this._closed = data.closed === true;
        this._status = getStatus( data.status );
        this._plan = data.plan_code ? getPlan( data.plan_code ) : null; // API account route does not return this data
        this._expired = this.checkAccountIsExpired();
        this._onboarding = new AccountOnboarding( data.onboarding || {} );
        this._configuration = new AccountConfiguration( data.configuration || {} );
        this._platforms = data.platforms;
        this.data = null;
    }

    private _name: string;

    get name(): string {
        return this._name;
    }

    set name( value: string ) {
        this._name = value;
    }

    get platforms(): string[] {
        return this._platforms;
    }

    private _status: AccountStatus;

    get status(): AccountStatus {
        return this._status;
    }

    set status( status: AccountStatus ) {
        this._status = status;
    }

    private _expired: boolean;

    /**
     * @deprecated please use expired instead
     */
    get locked(): boolean {
        return this._expired;
    }

    /**
     * @deprecated please use expired instead
     */
    set locked( value: boolean ) {
        this._expired = value;
    }

    get expired(): boolean {
        return this._expired;
    }

    /**
     * @deprecated please use expired instead
     */
    set expired( value: boolean ) {
        this._expired = value;
    }

    private _onboarding: AccountOnboarding;

    get onboarding(): AccountOnboarding {
        return this._onboarding;
    }

    set onboarding( value: AccountOnboarding ) {
        this._onboarding = value;
    }

    private _configuration: AccountConfiguration;

    get configuration(): AccountConfiguration {
        return this._configuration;
    }

    set configuration( value: AccountConfiguration ) {
        this._configuration = value;
    }

    get provider(): BillingProvider {
        return this._provider;
    }

    get closed(): boolean {
        return this._closed;
    }

    get plan(): AccountPlanType {
        return this._plan;
    }

    get plan_code(): AccountPlanType {
        return this._plan;
    }

    get id(): string {
        return this._id;
    }

    get expires(): number {
        return this._expires;
    }

    get manual(): boolean {
        return this._manual;
    }

    get license(): string {
        return this._license;
    }

    set license( value: string ) {

    }

    get users(): User[] {
        return this._users;
    }

    public checkAccountIsExpired(): boolean {
        return ( this.status === AccountStatus.EXPIRED );
    }

    public isEnterprise(): boolean {
        return this._plan === AccountPlanType.ENTERPRISE;
    }

    public isUltimate(): boolean {
        return this._plan === AccountPlanType.ULTIMATE;
    }

    public isTrial(): boolean {
        return this.status === AccountStatus.TRIAL;
    }

    public toJson(): IAccount {
        return {
            id           : this.id,
            name         : this.name,
            license      : this.license,
            users        : this.users.map( ( u: User ) => u.toJson() ),
            expires      : this.expires,
            manual       : this.manual,
            status       : this.status,
            plan_code    : this.plan_code,
            closed       : this.closed,
            locked       : this.locked,
            plan         : this.plan,
            onboarding   : this.onboarding.toJson(),
            configuration: this.configuration.toJson(),
            platforms    : this.platforms
        };
    }
}

function getPlan( accPlan: string ): AccountPlanType {
    if ( accPlan.search( 'ult' ) >= 0 ) {
        return AccountPlanType.ULTIMATE;
    } else if ( accPlan.search( 'ent' ) >= 0 ) {
        return AccountPlanType.ENTERPRISE;
    }
}

/**
 * Set the account status based on db entry. if Undefined default to TRIAL
 * @param status AccountStatus
 */
function getStatus( status: string ): AccountStatus {
    switch ( status ) {
        case 'trial': {
            return AccountStatus.TRIAL;
        }
        case 'active': {
            return AccountStatus.ACTIVE;
        }
        case 'expired': {
            return AccountStatus.EXPIRED;
        }
        case 'overdue': {
            return AccountStatus.OVERDUE;
        }
        case 'deleted': {
            return AccountStatus.DELETED;
        }
        case 'cancelled': {
            return AccountStatus.CANCELLED;
        }
        default: {
            return AccountStatus.TRIAL;
        }
    }
}
