import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IAccount } from '../interfaces/IAccount';
import { Account, ACCOUNT_CONFIG_KEY, AccountConfiguration } from '../models/Account';
import { Billing } from '../models/Billing';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';

@Injectable( {
    providedIn: 'root'
} )
export class AccountService extends BaseService {
    constructor( private readonly http: HttpClient,
                 private readonly _authService: AuthService ) {
        super();
    }

    /**
     * /v1/account
     * Get the loggedIn users account information
     */
    public getAuthAccount(): Observable<Account> {
        return this.http.get<{ data: any }>( `${ environment.urls.api.coms.account }v1/account` )
            .pipe(
                map( ( response: any ) => new Account( response.data ) ),
                map( ( account: Account ) => {
                    if ( this._authService.loggedInUser ) {
                        account.users.forEach( ( user: User ) => {
                            if ( user.id === this._authService.loggedInUser.id ) {
                                user.isLoggedInUser = true;
                            }
                        } );
                    }

                    return account;
                } ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Update the account
     * @param account Account
     */
    public updateAccount( account: IAccount ): Observable<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/account`, account.toJson() )
            .pipe(
                tap( () => this._authService.loggedInUser.account = account ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public deleteAccount( account: Account, password: any, email: any ): Observable<Billing> {
        return this.http.post( `${ environment.urls.api.coms.account }v1/account/close`, {
            password,
            email
        } )
            .pipe(
                map( ( response: any ) => new Billing( response.data ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Send a PATCH request to coms-account-service to update a particular configuration value
     * @param key Configuration key lookup
     * @param config Configuration value
     */
    public updateAccountConfiguration( key: ACCOUNT_CONFIG_KEY, config: any ): Observable<AccountConfiguration> {
        return this.http.patch( `${ environment.urls.api.coms.account }v1/account/configuration/${ key }`, config )
            .pipe(
                map( ( response: any ) => new AccountConfiguration( response.data ) ),
                tap( ( c: AccountConfiguration ) => this._authService.loggedInUser.account.configuration = c ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }
}
