import { Injectable, NgZone } from '@angular/core';
import { UserService } from './user.service';
import { AuthService } from './auth.service';

@Injectable( {
    providedIn: 'root'
} )
export class IntercomService {
    private userSettingsTimeout: any;

    constructor( private readonly ngZone: NgZone,
                 private readonly _userService: UserService,
                 private readonly _authService: AuthService ) {
    }

    public toggleSupportChat( show?: boolean ): void {
        const widget: any = document.querySelectorAll( '.intercom-app,.intercom-launcher' )[0];

        if ( widget ) {
            let showWidget = true;
            switch ( show + '' ) {
                case 'true':
                    showWidget = true;
                    break;
                case 'false':
                    showWidget = false;
                    break;
                default: // catches null and undefined
                    showWidget = widget.style.display === 'none';
                    break;
            }

            if ( !showWidget ) {
                ( window as any ).Intercom( 'hide' );
            }

            widget.style.display = showWidget ? 'block' : 'none';
            this.saveShowSetting( showWidget );
        }
    }

    public openSupportChat(): void {
        this.toggleSupportChat( true );
        this.ngZone.runOutsideAngular( () => {
            ( window as any ).Intercom( 'show' );
        } );
    }

    public openSupportChatWithMessager( message?: string ): void {
        this.toggleSupportChat( true );
        this.ngZone.runOutsideAngular( () => {
            const chat: any = ( window as any ).Intercom;
            if ( message ) {
                chat( 'showNewMessage', message );
            } else {
                chat( 'showNewMessage' );
            }
        } );
    }

    public update( obj: Record<string, any> ): void {
        this.ngZone.runOutsideAngular( () => {
            ( window as any ).Intercom( 'update', obj );
        } );
    }

    /**
     * Clears support chat of existing conversations and removes intercom from the UI
     */
    public shutdown(): void {
        this.ngZone.runOutsideAngular( () => {
            ( window as any ).Intercom( 'shutdown' );
        } );
    }

    private saveShowSetting( show: boolean ): void {
        this._authService.loggedInUser.settings.update( { show }, 'intercom' );

        clearTimeout( this.userSettingsTimeout );
        this.userSettingsTimeout = setTimeout( () => {
            this._userService
                .updateUserSettings()
                .subscribe();
        }, 1000 );
    }
}
