import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../core/guards/page-dirty.guard';
import { GrafExplorerComponent } from './graf-explorer.component';
import { GrafDashboardComponent } from './graf-dashboard.component';
import { environment } from '../../environments/environment';

const dashboardsDashboardId = environment.grafusion.dashboardsDashboardId;

const routes: Routes = [
    {
        path     : '',
        pathMatch: 'full',
        component: GrafExplorerComponent
    },
    {
        path     : 'goto/:shortcutId',
        component: GrafDashboardComponent
    },
    {
        path     : 'dashboards',
        component: GrafExplorerComponent,
        data     : {
            grafanaPath: `/g/d/${ dashboardsDashboardId }`
        }
    },
    {
        path     : 'explore',
        pathMatch: 'full',
        component: GrafExplorerComponent,
        data     : {
            grafanaPath: '/g/explore',
            showSubNav : true
        }
    },
    {
        path     : 'explore/metrics',
        component: GrafExplorerComponent,
        data     : {
            grafanaPath: '/g/explore/metrics',
            showSubNav : true
        }
    },
    {
        path     : '**',
        component: GrafDashboardComponent
    }
];

@NgModule( {
    imports: [
        RouterModule.forChild( routes )
    ],
    exports: [
        RouterModule
    ],
    providers: [
        CanDeactivateGuard
    ]
} )
export class GrafExplorerRoutingModule { }
