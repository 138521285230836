import { ServerExtraType } from './ServerExtraType';

export abstract class ServerExtra {
    protected constructor( private readonly _type: ServerExtraType ) {
    }

    get type(): ServerExtraType {
        return this._type;
    }

    public abstract toJson(): any;
}
