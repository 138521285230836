import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KsHotkeysService } from '@intergral/kaleidoscope';

type Hotkey = {
    description: string,
    status: string,
    visible: boolean,
    keys: string[],
    event: string,
    callback: () => any
}

@Component( {
    selector       : 'app-hotkey-list',
    styleUrls      : [ './hotkey-list.component.scss' ],
    templateUrl    : './hotkey-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
} )
export class HotkeyListComponent implements OnInit, OnDestroy {
    public hotkeys: Hotkey[] = [];
    private hotKeyServiceChangeEvent: Subscription;

    constructor( private hotkeysService: KsHotkeysService ) {
    }

    public trackByDescription = ( index: number, ctx: Hotkey ): string => {
        return ctx.description;
    };

    ngOnInit(): void {
        this.hotKeyServiceChangeEvent = this.hotkeysService.changeEvent
            .subscribe( this.updateHotkeys );

        this.updateHotkeys( this.hotkeysService.hotkeys );
    }

    ngOnDestroy(): void {
        this.hotKeyServiceChangeEvent.unsubscribe();
    }

    updateHotkeys( hotkeys: Record<string, Hotkey[]> ) {
        this.hotkeys = [];

        Object.values( hotkeys ).forEach( ( [ hotkey ] ) => {
            if ( hotkey?.visible && hotkey.status === 'active' ) {
                this.hotkeys.push( hotkey );
            }
        } );
    }
}
