import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Instance extends ServerExtra {
    private readonly _name: string;
    private _serverstartts: number;

    constructor( private readonly data ) {
        super( ServerExtraType.INSTANCE );

        this._name = data.name;

        this._serverstartts = data.serverstartts;

        this.data = null;
    }

    get name(): string {
        return this._name;
    }

    get serverstartts(): number {
        return this._serverstartts;
    }

    set serverstartts( value: number ) {
        this._serverstartts = value;
    }

    public toJson(): any {
        return {
            name         : this.name,
            serverstartts: this.serverstartts
        };
    }
}
