import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ChangeLog, ChangeLogChangeType } from '../../../../core/models/ChangeLog';
import { ChangeLogService } from '../../../../core/services/changelog.service';
import { trackByIdFn } from '../../../../core/trackByFunctions/track-by-id';
import { trackByKeyFn } from '../../../../core/trackByFunctions/track-by-key';

@Component( {
    selector   : 'app-changelog-viewer',
    templateUrl: './changelog-viewer.component.html',
    styleUrls  : [ './changelog-viewer.component.scss' ]
} )
export class ChangelogViewerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild( 'scroller', { static: true } )
    public scroller: VirtualScrollerComponent;

    public changeLogList: ChangeLog[] = [];
    public visibleChangeLogList: ChangeLog[] = [];

    public ChangeLogChangeType = ChangeLogChangeType;
    public trackByIdFn = trackByIdFn;
    public trackByKeyFn = trackByKeyFn;

    public unreadCount: number = 0;

    constructor( private readonly _changeLogService: ChangeLogService ) {
    }

    public ngOnInit(): void {
        this._changeLogService.getChangeLogs()
            .subscribe( ( changeLogList: ChangeLog[] ) => {
                this.changeLogList = [ ...changeLogList ];
                this.unreadCount = changeLogList.filter( cl => !cl.seen ).length;
            } );
    }

    public ngAfterViewInit(): void {
        this._changeLogService.markUnreadRead();
    }

    public ngOnDestroy(): void {
        this._changeLogService.updateReadState();
    }
}
