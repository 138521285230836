import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export interface ICanComponentDeactivate {
    canDeactivate(): Promise<boolean> | boolean;
}

@Injectable( {
    providedIn: 'root'
} )
export class CanDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
    public canDeactivate( component: any ): Promise<boolean> | boolean {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
