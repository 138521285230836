import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class ServerE extends ServerExtra {
    private readonly _extra: { key: string, value: string }[];

    private readonly _name: string;

    private readonly _version: string;

    private readonly _home: string;

    constructor( private readonly data ) {
        super( ServerExtraType.SERVER );
        // Do object fromEntries
        this._extra = data.extra;
        this._name = data.name;
        this._version = data.version;
        this._home = data.home;

        this.data = null;
    }

    get extra(): { key: string, value: string }[] {
        return this._extra;
    }

    get name(): string {
        return this._name;
    }

    get version(): string {
        return this._version;
    }

    get home(): string {
        return this._home;
    }

    public toJson(): any {
        return {
            extra  : this.extra,
            name   : this.name,
            version: this.version,
            home   : this.home
        };
    }

    public get details(): any {
        return {
            home   : this.home,
            type   : this.name,
            version: this.version
        };
    }
}
