import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class JVM extends ServerExtra {
    private readonly _javaVmInfo: string;

    private readonly _javaVmSpecificationName: string;

    private readonly _javaVmSpecificationVendor: string;

    private readonly _javaClassVersion: string;

    private readonly _javaVmVersion: string;

    private readonly _javaRuntimeVersion: string;

    private readonly _javaVersion: string;

    private readonly _javaRuntimeName: string;

    private readonly _javaVmVendor: string;

    private readonly _javaVmName: string;

    private readonly _javaVmSpecificationVersion: string;

    private readonly _javaArgs: string[];

    constructor( private readonly data ) {
        super( ServerExtraType.JVM );

        this._javaVmInfo = data.java_vm_info;
        this._javaVmSpecificationName = data.java_vm_specification_name;
        this._javaVmSpecificationVendor = data.java_vm_specification_vendor;
        this._javaClassVersion = data.java_class_version;
        this._javaVmVersion = data.java_vm_version;
        this._javaRuntimeVersion = data.java_runtime_version;
        this._javaVersion = data.java_version;
        this._javaRuntimeName = data.java_runtime_name;
        this._javaVmVendor = data.java_vm_vendor;
        this._javaVmName = data.java_vm_name;
        this._javaVmSpecificationVersion = data.java_vm_specification_version;
        this._javaArgs = data.java_args;

        this.data = null;
    }

    get javaVmInfo(): string {
        return this._javaVmInfo;
    }

    get javaVmSpecificationName(): string {
        return this._javaVmSpecificationName;
    }

    get javaVmSpecificationVendor(): string {
        return this._javaVmSpecificationVendor;
    }

    get javaClassVersion(): string {
        return this._javaClassVersion;
    }

    get javaVmVersion(): string {
        return this._javaVmVersion;
    }

    get javaRuntimeVersion(): string {
        return this._javaRuntimeVersion;
    }

    get javaVersion(): string {
        return this._javaVersion;
    }

    get javaRuntimeName(): string {
        return this._javaRuntimeName;
    }

    get javaVmVendor(): string {
        return this._javaVmVendor;
    }

    get javaVmName(): string {
        return this._javaVmName;
    }

    get javaVmSpecificationVersion(): string {
        return this._javaVmSpecificationVersion;
    }

    get javaArgs(): string[] {
        return this._javaArgs;
    }

    public toJson(): any {
        return {
            java_version                 : this.javaVersion,
            java_class_version           : this.javaClassVersion,
            java_runtime_name            : this.javaRuntimeName,
            java_runtime_version         : this.javaRuntimeVersion,
            java_vm_info                 : this.javaVmInfo,
            java_vm_name                 : this.javaVmName,
            java_vm_vendor               : this.javaVmVendor,
            java_vm_version              : this.javaVmVersion,
            java_vm_specification_name   : this.javaVmSpecificationName,
            java_vm_specification_vendor : this.javaVmSpecificationVendor,
            java_vm_specification_version: this.javaVmSpecificationVersion,
            java_args                    : this.javaArgs
        };
    }
}
