import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KsLoadingService } from '@intergral/kaleidoscope';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Account } from '../models/Account';
import { Billing } from '../models/Billing';
import { User } from '../models/User';
import { BaseService, IServiceErrorResponse } from './base.service';
import { LaunchDarklyService } from './launchdarkly.service';

@Injectable( {
    providedIn: 'root'
} )
export class AuthService extends BaseService {
    public readonly loginEvent: Subject<void> = new Subject();
    public readonly logOutEvent: Subject<void> = new Subject();
    public readonly accountUpdateEvent: Subject<Billing> = new Subject();

    private cookieOptions: CookieOptions = {
        path  : '/',
        domain: environment.cookies.domain,
        secure: environment.cookies.secure
    };

    constructor( private readonly http: HttpClient,
                 private readonly cookieService: CookieService,
                 private readonly router: Router,
                 private readonly route: ActivatedRoute,
                 private readonly ksLoadingService: KsLoadingService,
                 private readonly _ldService: LaunchDarklyService
    ) {
        super();

        const getToken = ( type?: string ) => {
            let token: string;
            const tokenType = type ? `_${ type }` : '';
            const prefix = environment.cookies.prefix;

            try {
                token = this.cookieService.get( `${ prefix }fr_cloud_token${ tokenType }` );
                if ( token !== undefined ) {
                    token = atob( decodeURIComponent( token ) ); // base64 to
                }
                // match
                // ng1.
            } catch ( e ) {
                token = this.cookieService.get( `${ prefix }fr_cloud_token${ tokenType }` );
            }

            if ( token ) {
                if ( tokenType === '_impersonate' ) {
                    this._impersonateToken = token;
                } else {
                    this._token = token;
                }
            }
        };

        getToken();
        getToken( 'impersonate' );
    }

    private static _requestHeaders: HttpHeaders = new HttpHeaders();

    static get requestHeaders(): HttpHeaders {
        return AuthService._requestHeaders;
    }

    private _loggedIn = false;

    get loggedIn(): boolean {
        return this._loggedIn;
    }

    private _loggedInUser: User;

    get loggedInUser(): User {
        return this._loggedInUser;
    }

    private _token: string;

    get token(): string {
        return this._token;
    }

    private _impersonateToken: string;

    get impersonateToken(): string {
        return this._impersonateToken;
    }

    get priorityToken(): string {
        return this.impersonateToken || this.token;
    }

    private _previousUrl: { url: string, params: any } = {
        url   : null,
        params: null
    };

    get previousUrl(): { url: string, params: any } {
        return this._previousUrl;
    }

    set previousUrl( value: { url: string, params: any } ) {
        this._previousUrl = value;
    }

    get impersonating(): boolean {
        return this.loggedIn && !!this.impersonateToken;
    }

    public googleLink( register: boolean = false ): string {
        const url = new URL( 'https://accounts.google.com/o/oauth2/auth' );
        url.searchParams.append( 'client_id', environment.google.client_id );
        url.searchParams.append( 'redirect_uri', `${ environment.urls.app }/auth/google/${ register ? 'register' : 'login' }` );
        url.searchParams.append( 'response_type', 'code' );
        url.searchParams.append( 'prompt', 'select_account' );

        return `${ url.href }&scope=openid+profile+email`;
    }

    /**
     * Update the account state and emit an event
     * @param account Account
     */
    public updateAccountState( account: Billing ): void {
        this.loggedInUser.account = account;
        this.accountUpdateEvent.next( account );
    }

    public updateUserDetails( firstname: string, lastname: string ): void {
        this._loggedInUser.first = firstname;
        this._loggedInUser.last = lastname;
    }

    /**
     * Reset a users password
     * @param email string
     * @param userObj any
     */
    public async resetPassword( params, userObj ): Promise<any> {
        return this.http.post( `${ environment.urls.api.coms.auth }v1/password/reset`, { ...params, ...userObj } )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Trigger a password reset email
     * @param email string
     */
    public async triggerPasswordResetToken( email: string ): Promise<any> {
        return this.http.post( `${ environment.urls.api.coms.auth }v1/password/request`, { email } )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    public register(
        email: string,
        first: string,
        last: string,
        accountName: string,
        password: string,
        passwordC: string,
        shareConfig: boolean,
        shareUsage: boolean,
        marketing: boolean
    ): Observable<any> {
        const reqData = {
            email,
            first,
            last,
            account              : accountName,
            password,
            password_confirmation: passwordC,
            share_config         : shareConfig,
            share_usage          : shareUsage,
            marketing,
            ...this.route.snapshot.queryParams
        };

        return this.http.post( `${ environment.urls.api.coms.auth }v1/register`, reqData )
            .pipe(
                map( ( response ) => response ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public async logout( navigate: boolean = true ): Promise<void> {
        if ( !this._token && !this._impersonateToken ) {
            if ( navigate ) {
                await this.router.navigate( [ 'auth', 'login' ] );
            }

            return Promise.resolve();
        }

        return this.http.post( `${ environment.urls.api.coms.auth }v1/logout`, {} )
            .toPromise()
            .catch( this.handleErrorPromise )
            .finally( () => {
                this._loggedIn = false;
                this.logOutEvent.next();

                this._token = null;
                this._impersonateToken = null;

                const prefix = environment.cookies.prefix;

                const {
                    path,
                    secure,
                    domain
                } = this.cookieOptions;

                this.cookieService.delete( `${ prefix }fr_cloud_token`, path, domain, secure );

                const impersonationCookie = `${ prefix }fr_cloud_token_impersonate`;
                if ( this.cookieService.check( impersonationCookie ) ) {
                    this.cookieService.delete( impersonationCookie, path, domain, secure );
                }

                AuthService._requestHeaders.delete( 'Authorization' );

                this._loggedInUser = null;

                if ( navigate ) {
                    this.router.navigate( [ 'auth', 'login' ] );
                }
            } );
    }

    public async login( email: string, password: string, remember: Boolean ): Promise<HttpResponse<any>> {
        const reqData = {
            email,
            password,
            remember,
            os     : '',
            browser: '',
            devices: ''
        };

        return this.http.post( `${ environment.urls.api.coms.auth }v1/login`, reqData )
            .toPromise()
            .then( ( response: any ) =>
                this.handleLogin( response ) )
            .catch( this.handleErrorPromise );
    }

    /**
     * Cancel a request for changing an email
     * @param email string
     * @param token string
     */
    public async cancelEmailChange( email: string, token: string ): Promise<any> {
        const reqData = {
            email,
            token
        };

        return this.http.post( `${ environment.urls.api.coms.account }v1/user/email/cancel_change`, reqData )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Accept a users new email address
     * @param email string
     * @param token string
     */
    public async acceptEmailChange( email: string, token: string ): Promise<any> {
        const reqData = {
            email,
            token
        };

        return this.http.post( `${ environment.urls.api.coms.account }v1/user/email/accept_change`, reqData )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Confirm a new user and set their password
     * @param email string
     * @param token string
     * @param password string
     * @param passwordC string
     */
    public async confirmUser(
        email: string,
        token: string,
        password: string,
        passwordC: string,
        improveC: string,
        marketing: boolean
    ): Promise<any> {
        const reqData = {
            email,
            token,
            password,
            password_confirmation: passwordC,
            improve_check        : improveC,
            marketing
        };

        return this.http.post( `${ environment.urls.api.coms.auth }v1/confirm/password`, reqData )
            .pipe(
                map( ( response: any ) => {
                    const t: string = encodeURIComponent( btoa( response.data.token ) );
                    this.setAuthCookie( t );

                    return response.data.token;
                } ),
                switchMap( ( authToken: string ) => this.createSession( authToken ) )
            )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    private setAuthCookie( token: string ) {
        this.cookieService.set( `${ environment.cookies.prefix }fr_cloud_token`, token, this.cookieOptions );
    }

    public async confirmRegistration( email: string, token: string ): Promise<any> {
        const reqData = {
            email,
            token,
            ...this.route.snapshot.queryParams
        };

        return this.http.post( `${ environment.urls.api.coms.auth }v1/register/confirm`, reqData )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * If user is unable to set their password initially, then send them an email with a new token
     * @param email string
     */
    public async resendEmail( email: string ): Promise<any> {
        return this.http.put( `${ environment.urls.api.coms.auth }v1/register/resend`,
            {
                email,
                ...this.route.snapshot.queryParams
            } )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Set authorization header for API requests,
     * and finally set loggedIn state
     * @param token string
     * @param force
     */
    public async createSession( token?: string, force = false ): Promise<boolean> {
        this._token = token || this.token;

        if ( token ) {
            this.setAuthorizationHeader( token );
        } else {
            this.setAuthorizationHeader( this.priorityToken );
        }

        if ( this.loggedInUser && !force ) {
            return Promise.resolve( true );
        }

        return this.http.get( `${ environment.urls.api.coms.account }v1/user` )
            .pipe(
                map( ( combData: any ) => {
                    const userData = combData.data;
                    const account = new Account( userData.account );

                    return new User( {
                        ...userData,
                        account
                    } );
                } )
            )
            .toPromise()
            .then( async( user: User ) => {
                this._loggedInUser = user;
                await this._ldService.changeUser( user );
                this._loggedIn = true;
                this.loginEvent.next();

                if ( !force ) {
                    this.ksLoadingService.complete();
                    this.redirect();
                }

                return true;
            } )
            .catch( this.handleErrorPromise )
            .catch( ( error: IServiceErrorResponse ) => {
                this._token = null;
                this.logout();

                console.error( error.errors );

                return false;
            } );
    }

    public redirect(): void {
        if ( !this.previousUrl.url ) {
            this.router.navigate( [ '/overview' ] );

            return;
        }

        this.router.navigateByUrl( this.previousUrl.url );

        this.previousUrl = {
            url   : null,
            params: {}
        };
    }

    public async extendImpersonation(): Promise<any> {
        return this.http.put( `${ environment.urls.api.coms.account }v1/user/impersonation/extend`, {} )
            .toPromise()
            .then( () => this.createSession( null, true ) )
            .catch( this.handleErrorPromise )
            .catch( ( error: IServiceErrorResponse ) => {
                console.error( error.errors );

                return false;
            } );
    }

    public signInWithGoogle( params: any ): Observable<any> {
        return this.http.post( `${ environment.urls.api.coms.auth }v1/google/login`, params )
            .pipe( map( ( response: any ) =>
                this.handleLogin( response ) ), catchError( this.handleErrorObservableWithExtra ) );
    }

    public registerWithGoogle( params: any ): Observable<any> {
        return this.http.post( `${ environment.urls.api.coms.auth }v1/google/register`, params )
            .pipe( map( ( response: any ) =>
                this.handleLogin( response ) ), catchError( this.handleErrorObservableWithExtra ) );
    }

    // tslint:disable-next-line:prefer-function-over-method
    private setAuthorizationHeader( token: string ): void {
        if ( !token ) {
            return;
        }

        AuthService._requestHeaders = AuthService._requestHeaders.set( 'Authorization', token );
    }

    private handleLogin( response: any ): any {
        let token;

        try {
            token = encodeURIComponent( window.btoa( response.data.token ) );
        } catch ( e ) {
            token = response.data.token;
        }

        this.setAuthCookie( token );
        this.createSession( response.data.token );

        return response;
    }
}
