import { environment } from '../../../environments/environment';
import { QuickLinkType } from './QuickLinkType';

export class QuickLink {
    private readonly _resource: QuickLinkType;
    private readonly _resourceId: string;
    private readonly _resourceName: string;
    private _routerLink: any[] = [];

    private _isDeleting = false;

    constructor( private readonly data ) {
        this._resource = data.resource;
        this._resourceId = data.resourceId;
        this._resourceName = data.resourceName;

        this.buildLink();

        this.data = null;
    }

    private buildLink(): void {
        switch ( this.resource ) {
            case 'server':
                this._routerLink = [ [ '/servers/', this.resourceId ], {} ];
                break;
            case 'application':
                this._routerLink = [ [ '/applications/', this.resourceId ], {} ];
                break;
            case 'group':
                this._routerLink = [ [ '/servers/detailed/' ], { group: this.resourceId } ];
                break;
            default: {
                if ( !environment.production ) {
                    console.error( `QuickLink resource ${ this.resource } not found` );
                }
            }
        }
    }

    get id(): string {
        return this._resourceId;
    }

    get routerLink(): any[] {
        return this._routerLink;
    }

    get resource(): QuickLinkType {
        return this._resource;
    }

    get resourceId(): string {
        return this._resourceId;
    }

    get resourceName(): string {
        return this._resourceName;
    }

    set isDeleting( value: boolean ) {
        this._isDeleting = value;
    }

    get isDeleting(): boolean {
        return this._isDeleting;
    }
}
