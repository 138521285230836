import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class GrafanaService {
  private _GrafanaFrame: HTMLIFrameElement;
  constructor() { }

  public get GrafanaFrame(): HTMLIFrameElement {
      return this._GrafanaFrame;
  }

  public set GrafanaFrame( value: HTMLIFrameElement ) {
      this._GrafanaFrame = value;
  }
}
