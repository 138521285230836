import { merge } from 'lodash';
import { environment } from '../../../environments/environment';

export class UserConfiguration {
    private _servers: {
        offline_exclusion: number,
    };

    constructor( private readonly data?: any ) {
        if ( data && !Array.isArray( data ) ) {
            this._servers = data.servers ? data.servers : this.getDefaultSettings( 'servers' );
        } else {
            this.setupDefaultSettings();
        }

        this.data = null;
    }

    get servers(): any {
        return this._servers;
    }

    public update(
        value: any,
        key: string
    ): void {
        switch ( key ) {
            case 'servers': {
                merge( this._servers, value );
                break;
            }
            default: {
                if ( !environment.production ) {
                    console.error( `Configuration key ${ key } is not valid` );
                }
            }
        }
    }

    public getDefaultSettings( key: string ): any {
        switch ( key ) {
            case 'servers': {
                return {
                    offline_exclusion: 7
                };
            }
            default: {
                if ( !environment.production ) {
                    console.error( `UserConfiguration ${ key } is not valid` );
                }
            }
        }
    }

    public toJson(): any {
        return {
            servers: this.servers
        };
    }

    private setupDefaultSettings(): void {
        this._servers = this.getDefaultSettings( 'servers' );
    }
}
