import { environment } from '../../../../environments/environment';
import { ThrowableTraceLine, TransactionDetail } from '../servers/TransactionDetail';
import { ThreadState } from './ThreadState';

export class StackTrace {
    private readonly _blocking: any[] | null;
    private readonly _daemon: boolean;
    private _description: boolean;
    private readonly _deadlocked: boolean;
    private readonly _group: string;
    private readonly _hashCode: number | string; // FR 7.3.0+ is a string
    private readonly _id: number;
    private readonly _lockOwnerId: number;
    private readonly _lockOwnerThreadName: string;
    private readonly _lockOwnerType: string;
    private readonly _lockOwnerTypeHash: string;
    private _locks: any;
    private readonly _name: string;
    private readonly _priority: number;
    private _state: ThreadState;
    private readonly _timestamp: any;
    private readonly _traces: ThrowableTraceLine[];
    private readonly _transaction: TransactionDetail;
    private readonly _users: any;
    private _saved: boolean;
    private _buttonStates: {
        save: string,
        unsave: string,
        saveDrwr: string,
    };

    constructor( private readonly data: any ) {
        this._blocking = data.blocking;
        this._daemon = data.daemon;
        this._deadlocked = data.deadlocked;
        this._group = data.group;
        this._hashCode = data.hash_code;
        this._id = data.id;
        this._lockOwnerId = data.lock_owner_id;
        this._lockOwnerThreadName = data.lock_owner_thread_name;
        this._lockOwnerType = data.lock_owner_type;
        this._lockOwnerTypeHash = data.lock_owner_type_hash;
        this._name = data.name;
        this._priority = data.priority;
        this._timestamp = data.time;
        this._traces = data.traces ? data.traces : [];
        this._transaction = data.transaction ? new TransactionDetail( data.transaction ) : null;
        this._users = data.users;
        this._saved = data.saved ? data.saved.owner : null;
        this._description = data.description;

        this.setLocks( data.locks ? data.locks : [] );
        this.setState( data.transaction ? 'RUNNING' : data.state );
        this._buttonStates = {
            save    : '',
            unsave  : '',
            saveDrwr: ''
        };

        this.data = null;
    }

    get lockOwnerThreadName(): string {
        return this._lockOwnerThreadName;
    }

    get lockOwnerType(): string {
        return this._lockOwnerType;
    }

    get lockOwnerTypeHash(): string {
        return this._lockOwnerTypeHash;
    }

    get blocking(): any[] | null {
        return this._blocking;
    }

    get lockOwnerId(): number {
        return this._lockOwnerId;
    }

    get description(): boolean {
        return this._description;
    }

    set description( value: boolean ) {
        this._description = value;
    }

    get buttonStates(): { save: string, unsave: string, saveDrwr: string } {
        return this._buttonStates;
    }

    set buttonStates( value: { save: string, unsave: string, saveDrwr: string } ) {
        this._buttonStates = value;
    }

    get transaction(): TransactionDetail {
        return this._transaction;
    }

    get locks(): any {
        return this._locks;
    }

    get id(): number {
        return this._id;
    }

    get idAsHex(): string {
        return `0x${ this._id.toString( 16 ) }`;
    }

    get timestamp(): number {
        return this._timestamp;
    }

    get state(): ThreadState {
        return this._state;
    }

    get priority(): number {
        return this._priority;
    }

    get name(): string {
        return this._name;
    }

    get hashCode(): number | string {
        return this._hashCode;
    }

    get hash_codeAsHex(): string {
        // FusionReactor 7.3.0 now sends up hash codes as strings already formatted
        if ( typeof this._hashCode === 'string' ) {
            return `0x${ this._hashCode }`;
        }

        return `0x${ this._hashCode.toString( 16 ) }`;
    }

    get hash_codeAsHexWithOriginalHashValue(): string {
        // FusionReactor 7.3.0 now sends up hash codes as strings already formatted
        if ( typeof this._hashCode === 'string' ) {
            return this._hashCode;
        }

        return `0x${ this._hashCode.toString( 16 ) } (${ this.hashCode })`;
    }

    get daemon(): boolean {
        return this._daemon;
    }

    get traces(): any {
        return this._traces;
    }

    get saved(): boolean {
        return this._saved;
    }

    set saved( saved: boolean ) {
        this._saved = saved;
    }

    public toJson(): any {
        return {
            id                    : this.id,
            blocking              : this._blocking,
            deadlocked            : this._deadlocked,
            daemon                : this._daemon,
            group                 : this._group,
            hash_code             : this._hashCode,
            lock_owner_id         : this._lockOwnerId,
            lock_owner_thread_name: this._lockOwnerThreadName,
            lock_owner_type       : this._lockOwnerType,
            lock_owner_type_hash  : this._lockOwnerTypeHash,
            locks                 : this._locks,
            name                  : this._name,
            priority              : this._priority,
            saved                 : this._saved,
            state                 : this._state,
            time                  : this._timestamp,
            traces                : this._traces,
            users                 : this._users
        };
    }

    private setLocks( locks: any[] ): void {
        if ( !locks ) {
            return;
        }

        locks.map( ( l: any ) => {
            if ( l.hash.substr( 0, 2 ) !== '0x' ) {
                l.hash = `0x${ parseInt( l.hash, 10 )
                    .toString( 16 ) }`;
            }

            return l;
        } );

        this._locks = locks;
    }

    private setState( state: string ): void {
        switch ( state ) {
            case 'RUNNABLE': {
                this._state = ThreadState.RUNNABLE;
                break;
            }
            case 'RUNNING': {
                this._state = ThreadState.RUNNING;
                break;
            }
            case 'DEADLOCKED': {
                this._state = ThreadState.DEADLOCKED;
                break;
            }
            case 'TIMED_WAITING': {
                this._state = ThreadState.TIMED_WAITING;
                break;
            }
            case 'WAITING': {
                this._state = ThreadState.WAITING;
                break;
            }
            case 'BLOCKED': {
                this._state = ThreadState.BLOCKED;
                break;
            }
            case 'SAVED': {
                this._state = ThreadState.SAVED;
                break;
            }
            default: {
                if ( !environment.production ) {
                    console.error( `ThreadState ${ state } not found` );
                }
            }
        }
    }

    private accumulatedStrings: string = '';

    get traceString() {
        this.accumulatedStrings = this.traces.reduce( ( previousLines: string, currentValue: ThrowableTraceLine ) => {
            return previousLines + currentValue.class_name + '.' + currentValue.method_name +
                '(' + currentValue.source_file + ':' + currentValue.line_number + ')\n'
        }, this.accumulatedStrings );
        return this.accumulatedStrings;
    }
}
