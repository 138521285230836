import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';

@Injectable( {
    providedIn: 'root'
} )
export class StatusService extends BaseService {
    constructor( private readonly http: HttpClient ) {
        super();
    }

    public getConnectedClientsCount(): Observable<number> {
        return this.http.get<{ client_id: string }[]>( `${ environment.urls.api.cdms.clients_go }v4/getAllMimirClients` )
            .pipe(
                // Filter out empty client id strings
                map( ( clients ) => clients.filter( value => value.client_id?.length ) ), // Remove non-FRs from list
                map( ( clients ) => clients.length ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }
}
