/**
 * adjust the position of the support chat bubble
 *
 * @param bottom Adjust the css bottom value.
 * @default 20px
 * @param right Adjust the css right value.
 * @default 20px
 */
export function repositionSupportChat( bottom: number|string = 20, right: number|string = 20 ): void {
    const chat: any = ( window as any ).Intercom;

    if ( chat ) {
        chat( 'update', {
            vertical_padding  : bottom,
            horizontal_padding: right
        } );
    }
}
