import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KsTimePickerService } from '@intergral/kaleidoscope';
import * as moment from 'moment-timezone';
import { BaseService } from './base.service';

@Injectable( {
    providedIn: 'root'
} )
export class UtilityService extends BaseService {
    static get isAMPMLocale(): boolean {
        return moment.localeData()
            .longDateFormat( 'LT' )
            .toLowerCase()
            .includes( 'a' );
    }

    static get graphXAxisDateFormatters(): { week: string; hour: string; month: string; year: string; millisecond: string; day: string; second: string; minute: string } {
        return {
            millisecond: '%H:%M:%S.%L',
            second     : '%H:%M:%S',
            minute     : '%H:%M',
            hour       : '%H:%M',
            day        : '%e. %b',
            week       : '%e. %b',
            month      : '%b \'%y',
            year       : '%Y'
        };
    }

    static get graphTooltipDateFormatters(): { week: string; hour: string; month: string; year: string; millisecond: string; day: string; second: string; minute: string } {
        const dateTimeLabelFormats = {
            millisecond: '%A, %e %b %H:%M:%S.%L',
            second     : '%A, %e %b %H:%M:%S',
            minute     : '%A, %e %b %H:%M',
            hour       : '%A, %e %b %H:%M',
            day        : '%A, %e %b %Y',
            week       : 'Week from %A, %e %b %Y',
            month      : '%B %Y',
            year       : '%Y'
        };

        if ( UtilityService.isAMPMLocale ) {
            dateTimeLabelFormats.millisecond = '%A, %b %e, %I:%M:%S.%L %p';
            dateTimeLabelFormats.second = '%A, %b %e, %I:%M:%S %p';
            dateTimeLabelFormats.minute = '%A, %b %e, %I:%M %p';
            dateTimeLabelFormats.hour = '%A, %b %e, %I:%M %p';
        }

        return dateTimeLabelFormats;
    }

    // tslint:enable

    public static formatITTTime( timestamp: any ): string {
        if ( !UtilityService.isAMPMLocale ) {
            return moment( timestamp ).format( 'LTS.SSS' );
        }

        // we're in a locale that uses am/pm, so we need to manually splice in the milliseconds in the correct position
        const parts = moment( timestamp ).format( 'LTS' ).split( ' ' );

        parts.splice( 1, 0, moment( timestamp ).format( '.SSS ' ) );

        return parts.join( '' );
    }

    public static formatITTDate( timestamp: any ): string {
        return moment( timestamp ).format( 'L' );
    }

    public static generateRandomColor(): string {
        return `rgb(${ Math.floor( Math.random() * 255 ) },${ Math.floor( Math.random() * 255 ) },${ Math.floor( Math.random() * 255 ) })`;
    }

    public static formatDate( epoch: number, format: string ): string {
        return moment( epoch ).format( format );
    }

    constructor( private readonly _timePickerService: KsTimePickerService, private readonly router: Router ) {
        super();

        this._timePickerService.timezoneUpdated
            .subscribe( ( a: any ) => {
                moment.tz.setDefault( a.tz() );
            } );
    }

    public generateQueryParamURL( obj: any ): string {
        let s = this.router.url.split( '?' )[0];

        s += '?';

        Object.keys( obj )
            .forEach( ( o ) => {
                if ( ( obj[o] !== null && obj[o] !== undefined ) ) {
                    s += `${ o }=${ obj[o] }&`;
                }
            } );

        if ( s.substr( s.length - 1 ) === '?' ) {
            return s.substr( 0, s.length - 1 );
        }

        return s;
    }

    /**
     * Convert user name to avatar
     * @param name string
     * @param size number
     * @param randomColor boolean
     */
    public LetterAvatar( name: string, size: number, randomColor = true ): string {
        const nameCopy = name || '';
        let sizeCopy = size || 60;

        const colours: string[] = [
            '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
            '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d'
        ];

        const nameSplit = this.nameSplit( nameCopy );
        const initials = nameSplit.length === 1
            ? nameSplit[0] ? nameSplit[0].charAt( 0 ) : '?'
            : nameSplit[0].charAt( 0 )
                .concat( nameSplit[1].charAt( 0 ) );
        const charIndex = ( initials === '?' ? 72 : initials.charCodeAt( 0 ) ) - 64;
        const colourIndex = charIndex % 20;
        let canvas;

        if ( window.devicePixelRatio ) {
            sizeCopy = ( sizeCopy * window.devicePixelRatio );
        }

        canvas = document.createElement( 'canvas' );
        canvas.width = sizeCopy;
        canvas.height = sizeCopy;

        const context = canvas.getContext( '2d' );

        context.fillStyle = randomColor ? colours[colourIndex - 1] : '#647493';
        context.fillRect( 0, 0, canvas.width, canvas.height );
        context.font = `${ Math.round( canvas.width / 2 ) }px Arial`;
        context.textAlign = 'center';
        context.fillStyle = '#FFF';
        context.fillText( initials, sizeCopy / 2, size / 1.5 );

        const dataURI = canvas.toDataURL();

        canvas = null;

        return dataURI;
    }

    public nameSplit(
        name: string,
        seperator = ' '
    ): string[] {
        return String( name )
            .toUpperCase()
            .split( seperator );
    }

    public formatEpochToHuman( epoch: number ): string {
        return moment( epoch )
            .format( 'lll' );
    }

    public getCurrentTime(): number {
        return moment()
            .valueOf();
    }

    public duration( epoch: number ): string {
        return moment( epoch )
            .fromNow();
    }

    /**
     * Generate GET url for API request
     * @param url string
     * @param params params
     * @param stripNulls boolean
     * @param realQueryArrays boolean
     */
    public generateUrl( url: string, params: Object, stripNulls = false, realQueryArrays = false ): string {
        let urlCopy: string = url.concat( url.includes( '?' ) ? '' : '?' );

        Object.keys( params )
            .forEach( ( param: any ) => {
                if ( !params.hasOwnProperty( param ) || ( stripNulls && ( params[param] === null || params[param] === undefined ) ) ) {
                    return;
                }

                if ( realQueryArrays && Array.isArray( params[param] ) ) {
                    params[param].forEach( ( p: any ) => {
                        urlCopy += `${ param }[]=${ p }&`;
                    } );
                } else {
                    urlCopy += `${ param }=${ params[param] }&`;
                }
            } );

        return urlCopy;
    }
}
