interface ISubTransaction {
    readonly dbDuration: number;
    readonly description: string;
    readonly duration: number;
    readonly flavor: string;
    readonly hasSubs: boolean;
    readonly id: string;
    readonly ids: number;
    readonly indent: number;
    readonly jdbcProperties: any;
    readonly parent: string;
    readonly parentSpanId: string;
    readonly rowCount: number;
    readonly source: string;
    readonly spanId: string;
    readonly subFlavor: string;
    readonly total: string;
    readonly startTimestamp: number;
    readonly stopTimestamp: number;
    subs: any[];
}

export class SubTransaction implements ISubTransaction {
    public inHotPath = false;
    public parents = [];
    public _subs: ISubTransaction[] = [];

    private readonly _dbDuration: number;
    private readonly _description: string;
    private readonly _duration: number;
    private readonly _flavor: string;
    private readonly _hasSubs: boolean;
    private readonly _id: string;
    private readonly _ids: number;
    private readonly _indent: number;
    private readonly _jdbcProperties: any;
    private readonly _parent: string;
    private readonly _parentSpanId: string;
    private readonly _rowCount: number;
    private readonly _source: string;
    private readonly _spanId: string;
    private readonly _subFlavor: string;
    private readonly _total: string;
    private readonly _startTimestamp: number;
    private readonly _stopTimestamp: number;

    constructor( private readonly data: any ) {
        this._dbDuration = data.db_duration;
        this._description = data.description;
        this._duration = data.duration;
        this._flavor = data.flavor;
        this._hasSubs = data.has_subs;
        this._id = data.id;
        this._ids = data.ids;
        this._indent = data.indent;
        this._jdbcProperties = data.jdbc_properties || {};
        this._parent = data.parent;
        this._parentSpanId = data.parentSpanId;
        this._rowCount = data.row_count;
        this._source = data.source;
        this._spanId = data.spanId;
        this._subFlavor = data.sub_flavor;
        this._startTimestamp = +new Date( data.start );
        this._stopTimestamp = +new Date( data.stop );
        this._total = data.total;
        this._subs = ( data.subs || [] ).map( ( sub: any ) => new SubTransaction( sub ) );

        this.data = null;
    }

    public get subs(): ISubTransaction[] {
        return this._subs;
    }

    public set subs( subs: ISubTransaction[] ) {
        this._subs = subs;
    }

    public get dbDuration(): number {
        return this._dbDuration;
    }

    public get description(): string {
        return this._description;
    }

    public get duration(): number {
        return this._duration;
    }

    public get flavor(): string {
        return this._flavor;
    }

    public get hasSubs(): boolean {
        return this._hasSubs;
    }

    public get id(): string {
        return this._id;
    }

    public get ids(): number {
        return this._ids;
    }

    public get indent(): number {
        return this._indent;
    }

    public get jdbcProperties(): any {
        return this._jdbcProperties;
    }

    public get parent(): string {
        return this._parent;
    }

    public get parentSpanId(): string {
        return this._parentSpanId;
    }

    public get rowCount(): number {
        return this._rowCount;
    }

    public get source(): string {
        return this._source;
    }

    public get spanId(): string {
        return this._spanId;
    }

    public get subFlavor(): string {
        return this._subFlavor;
    }

    public get total(): string {
        return this._total;
    }

    public get startTimestamp(): number {
        return this._startTimestamp;
    }

    public get stopTimestamp(): number {
        return this._stopTimestamp;
    }

    public getTimeOffset( time: Date ): number {
        return +new Date( this._startTimestamp ) - +new Date( time );
    }
}
