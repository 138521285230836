import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { KsLoadingService } from '@intergral/kaleidoscope';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';

@Injectable()
export class AuthHeadersInterceptor implements HttpInterceptor {
    private uiVersion: string = null; //  UI version the user currently has
    private latestVersion: string = null; // latest cloud version available

    constructor(
        private readonly router: Router,
        private readonly ksLoadingService: KsLoadingService,
        private readonly injector: Injector
    ) {
    }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authValue = AuthService.requestHeaders.get( 'Authorization' );
        const existingAuthValue = req.headers.get( 'Authorization' );
        const changedReq = !existingAuthValue && authValue ? req.clone( { setHeaders: { Authorization: authValue } } ) : req;

        return next.handle( changedReq )
            .pipe( tap( ( event: HttpEvent<any> ) => {
                if ( event instanceof HttpResponse ) {
                    const uiVersion = event.headers.get( 'ui_version' );

                    if ( !this.uiVersion ) {
                        this.uiVersion = uiVersion;
                    }

                    if ( !uiVersion ) {
                        return;
                    }

                    const _eventService = this.injector.get( EventService );

                    if ( this.uiVersion !== uiVersion ) { // if the user's cloud version is not the same as the header
                        if ( this.latestVersion !== uiVersion ) { // if the latest version is out of date
                            _eventService.nags.push( 'There is a new version of FusionReactor Cloud available, ' );
                        }
                        this.latestVersion = uiVersion;
                    }
                }
            }, async( err: any ) => {
                if ( err instanceof HttpErrorResponse ) {
                    if ( err.status === 401 ) {
                        const urlSegments = this.router.url.split( '/' );

                        if ( urlSegments[1] !== 'auth' && urlShouldLogout( changedReq.url ) ) { // CO-2328
                            this.ksLoadingService.complete();

                            const _authService = this.injector.get( AuthService );
                            await _authService.logout();
                        }
                    }
                }
            } ) );
    }
}

function urlShouldLogout( url: string ): boolean {
    return !url.includes( 'v2/room' );
}
