export enum IFlavorType {
    DATABASE,
    API,
    MIDDLEWARE,
}

export const FLAVOR_CONFIG = new Map<IFlavorType, { name: string, icon: string }>( [
    [ IFlavorType.API,
        {
            name: 'API',
            icon: 'icon-webhook'
        }
    ],
    [ IFlavorType.DATABASE,
        {
            name: 'Database',
            icon: 'icon-database'
        }
    ],
    [ IFlavorType.MIDDLEWARE,
        {
            name: 'Middleware',
            icon: 'icon-code'
        }
    ]
] );

// This maps known flavours to groups that we can identify them by.
export const FLAVOR_GROUPS = new Map<string, IFlavorType>( [
    [ 'Kafka', IFlavorType.MIDDLEWARE ],

    [ 'WebRequest', IFlavorType.API ],
    [ 'CFHTTP', IFlavorType.API ],
    [ 'HTTPClient', IFlavorType.API ],
    [ 'HttpClient', IFlavorType.API ],
    [ 'Jersey', IFlavorType.API ],

    // JDBC
    [ 'JDBCRequest', IFlavorType.DATABASE ],
    [ 'Cassandra', IFlavorType.DATABASE ],

    // MongoDB
    [ 'MongoDB', IFlavorType.DATABASE ],
    [ 'MongoDBConnection', IFlavorType.DATABASE ],
    [ 'WriteOperation', IFlavorType.DATABASE ],
    [ 'QueryBatch', IFlavorType.DATABASE ],
    [ 'ReadOperation', IFlavorType.DATABASE ]
] );
