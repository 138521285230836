import { merge } from 'lodash';
import { environment } from '../../../environments/environment';

export class UserSettings {
    protected _decompile: {
        editor: {
            theme: string,
        },
    };

    private _ui: {
        theme: string,
    };

    private _profile: {
        save_filters: boolean,
    };

    private _logging: { [key: string]: any };

    private _servers: {
        group: {
            selected: string, // Currently selected type
            view: string,
        },
        filter: {
            sort: string,
            search: string,
            status: string,
            fusionreactor: string,
            labels: string,
        },
    };

    private _notifications: {
        filter: {
            severity: string,
            dismissed: boolean,
            group: string,
            instance: string,
        },
    };

    private _applications: {
        filter: {
            predicate: string,
            reverse: string,
            deleted: boolean,
            search: string,
            filter: string,
            view: string,
        },
    };

    private _logViewer: {
        filter: {
            theme: string;
        },
    };

    private _alerting: {
        alert: {
            filters: {
                state: string,
                check: string,
                outdated: boolean,
            },
        },
        check: {
            filters: {
                state: string,
                condition: string,
                thing: string,
                enabled: boolean,
                search: string,
            },
        },
        subscription: {
            filters: {
                state: string,
                service: string,
                enabled: boolean,
                search: string,
            },
        },
    };

    private _timePicker: {
        start: number,
        end: number,
        currentTime: boolean,
        timezone: string,
        pageRefreshPeriod: number,
        liveModeDefault: boolean,
    };

    constructor( private readonly data?: any ) {
        if ( data && !Array.isArray( data ) ) {
            this._servers = data.servers ? buildServerSettings( data.servers ) : this.getDefaultSettings( 'servers' );
            this._notifications = data.notifications && !Array.isArray( data.notifications ) && data.notifications.filter
                ? data.notifications
                : this.getDefaultSettings( 'notifications' );
            this._applications = data.applications ? data.applications : this.getDefaultSettings( 'applications' );
            this._alerting = data.alerting ? data.alerting : this.getDefaultSettings( 'alerting' );
            this._timePicker = data.timePicker ? data.timePicker : this.getDefaultSettings( 'timePicker' );
            this._intercom = data.intercom ? data.intercom : this.getDefaultSettings( 'intercom' );

            // Set the true by default for existing users
            if ( this._timePicker.liveModeDefault === undefined ) {
                this._timePicker.liveModeDefault = true;
            }

            this._ui = data.ui ? data.ui : this.getDefaultSettings( 'ui' );
            this._decompile = data.decompile ? data.decompile : this.getDefaultSettings( 'decompile' );
            this._profile = data.profile ? data.profile : this.getDefaultSettings( 'profile' );
            this._logViewer = data.logViewer ? data.logViewer : this.getDefaultSettings( 'logViewer' );
            this._logging = data.logging || {};
        } else {
            this.setupDefaultSettings();
        }

        this.data = null;
    }

    private _intercom: {
        show: true,
    };

    get intercom(): { show: true } {
        return this._intercom;
    }

    set intercom( value: { show: true } ) {
        this._intercom = value;
    }

    get ui(): { theme: string } {
        return this._ui;
    }

    set ui( value: { theme: string } ) {
        this._ui = value;
    }

    get decompile(): { editor: { theme: string } } {
        return this._decompile;
    }

    set decompile( value: { editor: { theme: string } } ) {
        this._decompile = value;
    }

    get notifications(): any {
        return this._notifications;
    }

    get timePicker(): any {
        return this._timePicker;
    }

    get alerting(): any {
        return this._alerting;
    }

    get applications(): any {
        return this._applications;
    }

    get logging(): { [p: string]: any } {
        return this._logging;
    }

    set logging( value: { [p: string]: any } ) {
        this._logging = value;
    }

    get servers(): any {
        return this._servers;
    }

    get logViewer(): any {
        return this._logViewer;
    }

    get profile(): { save_filters: boolean } {
        return this._profile;
    }

    set profile( val: { save_filters: boolean } ) {
        this._profile = val;
    }

    get isEnabled(): boolean {
        return this.profile.save_filters;
    }

    public has(
        settingTree: string,
        settings: any = this.toJson()
    ): boolean {
        const settingAsArray = settingTree.split( '.' );

        if ( settingAsArray.length === 1 ) {
            return ( settings[settingAsArray[0]] !== null && settings[settingAsArray[0]] !== undefined );
        }

        const nextVal = settingAsArray[0];

        settingAsArray.splice( 0, 1 );

        return this.has( settingAsArray.join( '.' ), settings[nextVal] );
    }

    public update(
        value: any,
        key: string
    ): void {
        switch ( key ) {
            case 'servers': {
                this._servers = merge( this._servers, value );
                break;
            }
            case 'notifications': {
                this._notifications = merge( this._notifications, value );
                break;
            }
            case 'applications': {
                this._applications = merge( this._applications, value );
                break;
            }
            case 'alerting': {
                this._alerting = merge( this._alerting, value );
                break;
            }
            case 'timePicker': {
                this._timePicker = merge( this._timePicker, value );
                break;
            }
            case 'decompile': {
                this._decompile = merge( this._decompile, value );
                break;
            }
            case 'logViewer': {
                this._logViewer = merge( this._logViewer, value );
                break;
            }
            case 'ui': {
                this._ui = merge( this._ui, value );
                break;
            }
            case 'intercom': {
                this._intercom = merge( this._intercom, value );
                break;
            }
            case 'logging': {
                this._logging = merge( this._logging, value );
                break;
            }
            default: {
                if ( !environment.production ) {
                    console.error( `UserSettings key ${ key } not found` );
                }
            }
        }
    }

    public resetToDefault( key: string ): void {
        this[`_${ key }`] = this.getDefaultSettings( key );
    }

    public getDefaultSettings( key: string ): any {
        switch ( key ) {
            case 'servers': {
                return {
                    group: {
                        selected: null,
                        view    : null
                    },
                    filter: {
                        sort         : 'uptime',
                        search       : null,
                        labels       : 'on',
                        fusionreactor: null,
                        status       : 'online',
                        view         : 'detailed'
                    }
                };
            }
            case 'notifications': {
                return {
                    filter: {
                        severity : null,
                        dismissed: null,
                        group    : null,
                        instance : null
                    }
                };
            }
            case 'applications': {
                return {
                    filter: {
                        predicate: null,
                        reverse  : null,
                        deleted  : false,
                        search   : null,
                        filter   : null,
                        sort     : false,
                        view     : 'grid'
                    }
                };
            }
            case 'alerting': {
                return {
                    alert: {
                        filters: {
                            state   : null,
                            check   : null,
                            outdated: null
                        }
                    },
                    check: {
                        filters: {
                            state    : null,
                            condition: null,
                            thing    : null,
                            enabled  : null,
                            search   : null
                        }
                    },
                    subscription: {
                        filters: {
                            state  : null,
                            service: null,
                            enabled: null,
                            search : null
                        }
                    }
                };
            }
            case 'timePicker': {
                return {
                    start            : null,
                    end              : null,
                    timezone         : null,
                    currentTime      : null,
                    pageRefreshPeriod: 0,
                    liveModeDefault  : true
                };
            }
            case 'intercom': {
                return {
                    show: true
                };
            }
            case 'decompile': {
                return { editor: { theme: 'vs-dark' } };
            }
            case 'profile': {
                return { save_filters: true };
            }
            case 'logViewer': {
                return { filters: { theme: 'vs-dark' } };
            }
            case 'ui': {
                return { theme: 'night' };
            }
            default: {
                if ( !environment.production ) {
                    console.error( `UserSettings key ${ key } not found` );
                }
            }
        }
    }

    public toJson(): any {
        return {
            servers      : this.servers,
            notifications: this.notifications,
            alerting     : this.alerting,
            applications : this.applications,
            timePicker   : this.timePicker,
            decompile    : this.decompile,
            profile      : this.profile,
            logViewer    : this.logViewer,
            ui           : this.ui,
            logging      : this.logging,
            intercom     : this.intercom
        };
    }

    private setupDefaultSettings(): void {
        this._servers = this.getDefaultSettings( 'servers' );
        this._notifications = this.getDefaultSettings( 'notifications' );
        this._applications = this.getDefaultSettings( 'applications' );
        this._alerting = this.getDefaultSettings( 'alerting' );
        this._timePicker = this.getDefaultSettings( 'timePicker' );
        this._decompile = this.getDefaultSettings( 'decompile' );
        this._profile = this.getDefaultSettings( 'profile' );
        this._logViewer = this.getDefaultSettings( 'logViewer' );
        this._ui = this.getDefaultSettings( 'ui' );
        this._intercom = this.getDefaultSettings( 'intercom' );
        this._logging = {};
    }
}

function buildServerSettings( settings: any ): any {
    return {
        group: {
            selected: settings.group.selected
        },
        filter: {
            sort         : settings.filter.sort,
            search       : settings.filter.search,
            status       : settings.filter.status,
            fusionreactor: settings.filter.fusionreactor,
            labels       : settings.filter.labels,
            view         : 'detailed'
        }
    };
}
