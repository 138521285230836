import { ServerExtra } from './ServerExtra';
import { ServerExtraType } from './ServerExtraType';

export class Default extends ServerExtra {
    private readonly _data: any;
    constructor( private readonly d: Record<string, string>[]  ) {
        super( ServerExtraType.DEFAULT );

        this._data = d;

        this.d = null;
    }

    get data(): any {
        return this._data;
    }

    public toJson(): any {
        return this._data;
    }
}
