import * as moment from 'moment-timezone';
import { convertToServerExtra, convertToServerExtras } from './extras/convertToServerExtras';
import { Docker } from './extras/Docker';
import { IServerExtraListType } from './extras/IServerExtraListType';
import { JVM } from './extras/JVM';
import { OS } from './extras/OS';
import { Product } from './extras/Product';
import { ServerE } from './extras/ServerE';
import { ServerExtraType } from './extras/ServerExtraType';

export class Server {
    protected _id: string;
    private _alerting: boolean = false;
    private _name: string;
    private readonly _details: any;
    private readonly _lastSeen: number;
    private readonly _createdAt: number;
    private readonly _alphabeticalSortIdentifier: string;
    private readonly _routerLink: { url: string[], queryParams: any };
    private readonly features = {
        cput    : '7.3.0',
        tsv     : '7.3.0',
        state   : '6.0.0',
        snapshot: '7.3.0'
    };

    private readonly _extras: IServerExtraListType = {};

    private _status: 'online' | 'offline' = 'online';

    constructor( data ) {
        if ( !data ) {
            return;
        }

        if ( data.gruid ) {
            data.client_id = data.gruid;
        }

        this._id = data.client_id;
        this._name = data.name;
        this._lastSeen = data.last_seen;
        this._createdAt = data.created_at;
        this._extras = convertToServerExtras( data.extras );

        this._routerLink = {
            url        : [ '/servers', this.clientId ],
            queryParams: null
        };

        this._alphabeticalSortIdentifier = `${ this._name }_${ this._id }`;

        data = null;
    }

    get clientId(): string {
        return this._id;
    }

    get client_id(): string {
        return this._id;
    }

    get id(): string {
        return this._id;
    }

    get gruid(): string {
        return this._id;
    }

    get alphabeticalSortIdentifier(): string {
        return this._alphabeticalSortIdentifier;
    }

    get lastSeen(): number {
        return this._lastSeen;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    get status(): 'online' | 'offline' {
        return this._status;
    }

    set status( value: 'online' | 'offline' ) {
        this._status = value;
    }

    get serverProfile(): { panelMetrics: [ string, string, string ] } {
        // TODO: change into a proper method of versioning server functionality
        return {
            panelMetrics: [ 'server_panel_1', 'server_panel_2', 'server_panel_3' ]
        };
    }

    get alerting(): boolean {
        return this._alerting;
    }

    set alerting( value: boolean ) {
        this._alerting = value;
    }

    get serverDetails(): ServerE {
        return this._extras[ServerExtraType.SERVER];
    }

    get dockerDetails(): Docker {
        return this._extras[ServerExtraType.DOCKER];
    }

    get startTimeFormatted(): string {
        if ( !this._extras[ServerExtraType.INSTANCE] ) {
            return 'Unknown';
        }

        return moment( this._extras[ServerExtraType.INSTANCE].serverstartts )
            .format( 'MMM Do, Y, HH:mm' );
    }

    get connectedTimeFormatted(): string {
        if ( !this._createdAt ) {
            return 'Unknown';
        }

        return moment( this._createdAt )
            .format( 'MMM Do, Y, HH:mm' );
    }

    get startTime(): number {
        if ( !this._extras[ServerExtraType.INSTANCE] ) {
            return 0;
        }

        return this._extras[ServerExtraType.INSTANCE].serverstartts;
    }

    get ver(): any {
        if ( !this._extras[ServerExtraType.PRODUCT] ) {
            return '';
        }

        return this._extras[ServerExtraType.PRODUCT].productVersion;
    }

    get mac(): string {
        if ( !this._extras[ServerExtraType.NETWORK] ) {
            return '';
        }

        return this._extras[ServerExtraType.NETWORK].macAddress;
    }

    get ip(): string {
        if ( !this._extras[ServerExtraType.NETWORK] ) {
            return '';
        }

        return this._extras[ServerExtraType.NETWORK].hostIpAddress;
    }

    get agentDetails(): Product {
        return this._extras[ServerExtraType.PRODUCT];
    }

    set agentDetails( value: Product ) {
        this._extras[ServerExtraType.PRODUCT] = value;
    }

    get osDetails(): OS {
        return this._extras[ServerExtraType.OS];
    }

    set osDetails( value: OS ) {
        this._extras[ServerExtraType.OS] = value;
    }

    get javaDetails(): JVM {
        return this._extras[ServerExtraType.JVM];
    }

    set javaDetails( value: JVM ) {
        this._extras[ServerExtraType.JVM] = value;
    }

    get hostname(): string {
        if ( !this._extras[ServerExtraType.HOST] ) {
            return '';
        }

        return this._extras[ServerExtraType.HOST].hostname;
    }

    get environment(): any {
        if ( !this._extras[ServerExtraType.ENV] ) {
            return null;
        }

        return this._extras[ServerExtraType.ENV].details;
    }

    get transit(): any {
        if ( !this._extras[ServerExtraType.TRANSIT] ) {
            return null;
        }

        return this._extras[ServerExtraType.TRANSIT].toJson();
    }

    get extras(): any {
        return this._extras;
    }

    get uptime(): number {
        if ( !this.createdAt ) {
            return 0;
        }

        const lastOnline = moment( this.lastSeen );
        const uptimeValue = this.status === 'online' ? lastOnline.diff( this.createdAt ) : moment().diff( lastOnline );

        return ( !uptimeValue || uptimeValue < 0 ) ? 0 : uptimeValue;
    }

    get details(): any {
        return this._details;
    }

    get name(): string {
        return this._name;
    }

    set name( name: string ) {
        this._name = name === '' ? this._extras.instance.name : name;
    }

    get routerLink(): { url: string[]; queryParams: any } {
        return this._routerLink;
    }

    public hasFeature( featureName: string ): boolean {
        return this.isAtLeastVersion( this.features[featureName] );
    }

    public isAtLeastVersion( version: string ): boolean {
        if ( !this.ver ) {
            return false;
        }

        if ( version === this.ver ) {
            return true;
        }

        const current = this.ver.split( '.' )
            .map( ( part: string ) => parseInt( part, 10 ) );
        const min = version.split( '.' )
            .map( ( part: string ) => parseInt( part, 10 ) );

        for ( let i = 0; i < 3; i++ ) {
            if ( current[i] < min[i] ) {
                return false;
            }
            if ( current[i] > min[i] ) {
                return true;
            }
        }

        return true;
    }

    public setExtra( extra: any ): void {
        this._extras[extra.type] = convertToServerExtra( extra );
    }

    /**
     * Return true if this server started after the given timestamp
     * @param timestamp number
     */
    public startedAfter( timestamp: number ): boolean {
        return this.startTime > timestamp;
    }

    public toJson(): any {
        return {
            client_id    : this.clientId,
            computed_name: this.name,
            last_seen    : this.lastSeen,
            alerting     : this.alerting,
            extras       : this.extrasToJson()
        };
    }

    private extrasToJson(): any {
        const extras = {};

        Object.keys( this._extras )
            .forEach( ( key: string ) => {
                extras[key] = this._extras[key].toJson();
                extras[key].type = key;
            } );

        return extras;
    }
}
