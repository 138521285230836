import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { KsEntityService, KsTimePickerDataObject, KsTimePickerService, KsTimePickerSource } from '@intergral/kaleidoscope';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, pluck, retry, skip, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ProfileType } from '../models/ProfileType';
import { EventSnapshotRecord } from '../models/servers/EventSnapshot/EventSnapshotRecord';
import { Group } from '../models/servers/Group';
import { GroupType } from '../models/servers/GroupType';
import { IServerConfigurationOutcome } from '../models/servers/IServerConfigurationOutcome';
import { LogFile } from '../models/servers/LogFile';
import { Server } from '../models/servers/Server';
import { ProfileDataRecord } from '../models/threads/ProfileDataRecord';
import { SavedStackTrace } from '../models/threads/SavedStackTrace';
import { StackTrace } from '../models/threads/StackTrace';
import { Thread } from '../models/threads/Thread';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { ICacheableService } from './ICacheableService';

type ServerConfig = { key: string; value: string };

type MimirClientsResponesObj = {
    'client_id': string,
    'start_ts': string // timestamp ms
};

type SingleClientResponseObj = {
    'MimirData': MimirClientsResponesObj,
    'client': {
        account_id: string
        client_id: string
        computed_name: string
        created_at: string
        extras: object
        last_seen: string
        name: string
        remote_client_id: string
        tags: object
    }
};

type AllClientsResponseObj = {
    'MimirData': MimirClientsResponesObj,
    'client': {
        'client_name': string,
        'product_version': string,
        'hostname': string,
        'host_ip_address': string
        'extras'?: object
    }
};

@Injectable( {
    providedIn: 'root'
} )
export class ServerService extends BaseService implements ICacheableService {
    public readonly selectedServer$: BehaviorSubject<Server> = new BehaviorSubject<Server>( null );
    public readonly stackTraceInstanceCommand: EventEmitter<void> = new EventEmitter();
    // Servers will be in an object until later as it would require a massive refactor across many files to use it as just an array
    private readonly serverList$: BehaviorSubject<{ servers: Server[] }> = new BehaviorSubject<any>( { servers: [] } );

    private readonly groupList$: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>( null );
    private readonly STACKTRACE_ALL_ID: any = 'all';

    private serverApiHandler: any;
    private groupApiHandler: any;

    constructor( private readonly http: HttpClient,
        private readonly _timePickerService: KsTimePickerService,
        private readonly _authService: AuthService,
        private readonly _entityService: KsEntityService ) {
        super();
        this.listenForTimePicker();

        this._authService.logOutEvent
            .subscribe( () => this.onLogout() );

        this._authService.loginEvent
            .subscribe( () => {
                this.getGroupsPrivate();

                const time = moment();
                const end = time.valueOf();
                const start = time.subtract( 3, 'm' ).valueOf();
                this.getServersInTimeframe( end, start );

                this.serverList$
                    .pipe(
                        skip( 1 ),
                        pluck( 'servers' )
                    )
                    .subscribe( ( serverList: any ) => {
                        this.groupList$
                            .pipe(
                                filter( ( data: any ) => ( data !== null && data !== undefined ) ),
                                take( 1 )
                            )
                            .subscribe( ( data: any ) => {
                                this.generateUngroupedGroup( data, serverList );
                            } );
                    } );
            } );
    }

    public onLogout(): void {
        this.serverList$.next( { servers: [] } );
        this.groupList$.next( [] );
    }

    public async killThread( thread: Thread, gruid: string, force = false ): Promise<Thread> {
        let url = `${ environment.urls.api.coms.threadtrace }v1/threads/${ gruid }/${ thread.id }`;

        if ( force ) {
            url += '?force=1';
        }

        return this.http.delete<{ alive: string, id: number, name: string, state: string }>( url )
            .pipe( map( ( response: any ) => new Thread( response ) ) )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    public profileToggle( thread: Thread, gruid: string, action: string ): Observable<Thread> {
        return this.http.post<any>( `${ environment.urls.api.coms.profiler }v1/profile/${ gruid }/toggle`, {
            thread: thread.id,
            action
        } )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    public getProfileData( gruid: string, profileId: number | string, isSaved = false ): Observable<ProfileDataRecord> {
        if ( isSaved ) {
            return this.http.get<{ data: any }>(
                `${ environment.urls.api.coms.profiler }v1/profile/saved/${ gruid }/${ profileId }`
            )
                .pipe(
                    map( ( response: any ) => {
                        const res = response.data;

                        res.profile = JSON.parse( res.profile );

                        return new ProfileDataRecord( res );
                    } ),
                    catchError( this.handleErrorObservableWithExtra )
                );
        }

        return this.http.get<{ data: any }>(
            `${ environment.urls.api.coms.profiler }v1/profile/${ gruid }/details/${ profileId }`
        )
            .pipe(
                map( ( response: any ) => {
                    const profileData = JSON.parse( response.data.response );

                    return new ProfileDataRecord( {
                        ...profileData.response[0],
                        application_id: response.data.application_id
                    } );
                } ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public getEventSnapshot( clientId: string, txnId: string, eventSnapshotId: string ): Observable<EventSnapshotRecord> {
        const cleanTxnId = txnId.replace( /\//g, '_' );

        return this.http.get<{ data: any }>(
            `${ environment.urls.api.coms.profiler }v1/eventsnapshot/${ clientId }/${ cleanTxnId }/${ eventSnapshotId }`
        )
            .pipe( map( ( response: any ) => new EventSnapshotRecord( response.data ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public getProfiles( gruid: string, type: string = ProfileType.HISTORY ): Observable<any> {
        switch ( type ) {
            case ProfileType.SAVED: {
                return this.getSavedProfiles( gruid );
            }
            case ProfileType.ACTIVE: {
                return this.getActiveProfiles( gruid );
            }
            case ProfileType.HISTORY:
            default: {
                return this.getHistoricProfiles( gruid );
            }
        }
    }

    public getSavedProfiles( gruid: string ): Observable<ProfileDataRecord[]> {
        return this.http.get<{ data: any[] }>( `${ environment.urls.api.coms.profiler }v1/profile/saved/${ gruid }` )
            .pipe(
                map( ( response: any ) => {
                    const profileList = response.data;
                    profileList.forEach( ( p: any ) => p.profile = JSON.parse( p.profile ) );

                    return profileList.map( ( profile: any ) => new ProfileDataRecord( profile ) );
                } ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public getHistoricProfiles( gruid: string ): Observable<ProfileDataRecord[]> {
        return this.http.get<{ data: any[] }>( `${ environment.urls.api.coms.profiler }v1/profile/${ gruid }/history` )
            .pipe(
                map( ( response: any ) =>
                    response.data.map( ( profile: any ) => new ProfileDataRecord( profile ) ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public getActiveProfiles( gruid: string ): Observable<ProfileDataRecord[]> {
        const server = this.serverList$.getValue()
            .servers
            .find( ( s: Server ) => s.clientId === gruid );

        if ( !server || server.status === 'offline' ) {
            return of( [] );
        }

        return this.http.get<{ data: any[] }>( `${ environment.urls.api.coms.profiler }v1/profile/${ gruid }/active` )
            .pipe(
                map( ( response: any ) =>
                    response.data.map( ( profile: any ) => new ProfileDataRecord( profile ) ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Get a single server's thread list
     * @param gruid string
     */
    public getThreads( gruid: string ): Observable<{ meta: any, threads: Thread[] }> {
        return this.http.get<{ data: any[], meta: any }>( `${ environment.urls.api.coms.threadtrace }v1/threads/${ gruid }` )
            .pipe(
                map( ( response: any ) =>
                    ( {
                        meta   : response.meta,
                        threads: response.data.map( ( threadData: any ) => new Thread( threadData ) )
                    } ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Send a request to the UI Tunnel service to see if there's a UI connection to the server
     * @param gruid the gruid/clientID of the server, passed to see if there's a connection registered for it
     */
    public hasUITunnelConnection( gruid: string ): Observable<{result: boolean, error?: string }> {
        // ui-tunnel service returns 200 if it has a connection registered with the gruid/clientID - 404 if not
        return this.http.head( `${ environment.urls.api.ui_tunnel }connection/${ gruid }`, { observe: 'response' } )
            .pipe(
                map( ( response: HttpResponse<Object> ) => {
                    return { result: response?.status === 200 };
                } ),
                catchError( ( err ) => {
                    return of( { result: false, error: err.error  } );
                } ) );
    }

    /**
     * Send an IR to FR for the list of log files available
     * @param gruid string
     */
    public getLogFileList( gruid: string ): Observable<LogFile[]> {
        return this.http.get<{ data: any[] }>( `${ environment.urls.api.coms.log }v1/logs/${ gruid }` )
            .pipe( map( ( response: any ) =>
                response.data.map( ( logData: any ) => new LogFile( logData ) ) ),
            catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     *
     * @param gruid string
     * @param logId string
     */
    public getLogFileData( gruid: string, logId: string ): Observable<LogFile> {
        return this.http.get<{ data: any }>( `${ environment.urls.api.coms.log }v1/logs/${ gruid }/${ logId }` )
            .pipe(
                map( ( response: any ) => new LogFile( response.data ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Make an API call to FusionReactor to delete the specified log file
     * @param gruid string
     * @param log LogFile
     */
    public deleteLog( gruid: string, log: LogFile ): Observable<any> {
        return this.http.delete( `${ environment.urls.api.coms.log }v1/logs/${ gruid }/${ log.id }` )
            .pipe( catchError( this.handleErrorObservableWithExtra ) );
    }

    /**
     * Agree to terms and conditions for decompilation
     */
    public async setDecompilePermissions( status: boolean ): Promise<any> {
        return this.http.post( `${ environment.urls.api.coms.threadtrace }v1/decompile`, { accepted: status } )
            .pipe( retry( 3 ) )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * IR for stacktrace of a given thread
     * @param gruid string
     * @param threadId number
     * @param saved boolean
     */
    public getThreadStackTrace( gruid: string, threadId: number | string, saved = false ):
        Observable<{ meta: any, stackTrace: StackTrace[] }> {
        let url: string;

        if ( threadId !== this.STACKTRACE_ALL_ID && !saved ) {
            url = `${ environment.urls.api.coms.threadtrace }v1/traces/${ gruid }/${ threadId }`;
        } else if ( threadId === this.STACKTRACE_ALL_ID ) {
            url = `${ environment.urls.api.coms.threadtrace }v1/traces/${ gruid }`;
        } else if ( saved ) {
            url = `${ environment.urls.api.coms.threadtrace }v1/traces/saved/${ gruid }/${ threadId }`;
        }

        return this.http.get<{ data: any[], meta: any }>( url )
            .pipe(
                map( ( response: any ) =>
                    ( {
                        meta      : response.meta,
                        stackTrace: response.data.map( ( stackTraceData: any ) => new StackTrace( {
                            ...stackTraceData,
                            traceStartTime: response.data[0].time
                        } ) )
                    } ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public getSavedStacktraceList( gruid: string ): Observable<{ meta: any, list: SavedStackTrace[] }> {
        return this.http.get<{ data: any[], meta: any }>( `${ environment.urls.api.coms.threadtrace }v1/traces/saved/${ gruid }` )
            .pipe(
                map( ( response: any ) =>
                    ( {
                        meta: response.meta,
                        list: response.data.map( ( stackTraceData: any ) => new SavedStackTrace( stackTraceData ) )
                    } ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Save a single stackTrace frame
     * @param stackTrace StackTrace
     * @param gruid string
     */
    public async saveStackTraceFrame( stackTrace: StackTrace, gruid: string ): Promise<any> {
        return this.http.post( `${ environment.urls.api.coms.threadtrace }v1/traces/saved/${ gruid }`, {
            description: stackTrace.description,
            id         : stackTrace.id,
            time       : stackTrace.timestamp
        } )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Remove saved status from a single stackTrace frame
     * @param stackTrace StackTrace
     * @param gruid string
     */
    public async unsaveStackTraceFrame( stackTrace: StackTrace, gruid: string ): Promise<any> {
        return this.http.delete( `${ environment.urls.api.coms.threadtrace }v1/traces/saved/${ gruid }/${ stackTrace.timestamp }/${ stackTrace.id }` )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    public async unsaveStackTrace( stackTrace: SavedStackTrace, gruid: string ): Promise<any> {
        return this.http.delete( `${ environment.urls.api.coms.threadtrace }v1/traces/saved/${ gruid }/${ stackTrace.timestamp }` )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    public async deleteGroup( group: Group ): Promise<any> {
        return this.http.delete( `${ environment.urls.api.cdms.groups }v1/${ group.id }` )
            .toPromise()
            .then( () => {
                this.getGroupsPrivate();
            } )
            .catch( this.handleErrorPromise );
    }

    public async updateGroup( group: Group ): Promise<any> {
        return this.http.put<{
            group_id: string,
            name: string,
            description: string,
            client_ids: string[],
            type: GroupType,
        }>( `${ environment.urls.api.cdms.groups }v1/${ group.id }`, group.toJsonOld() )
            .toPromise()
            .then( () => {
                this.getGroupsPrivate();
            } )
            .catch( this.handleErrorPromise );
    }

    public async createGroup( group: Group ): Promise<Group> {
        return this.http.post<{
            group_id: string,
            name: string,
            description: string,
            client_ids: string[],
            type: GroupType,
        }>( `${ environment.urls.api.cdms.groups }v1`, group.toJsonOld() )
            .pipe( map( ( response: any ) => new Group( response ) ) )
            .toPromise()
            .then( () => {
                this.getGroupsPrivate();
            } )
            .catch( this.handleErrorPromise );
    }

    public getGroups(): Observable<Group[]> {
        return this.groupList$.pipe( filter( ( o: any ) => ( o !== null && o !== undefined ) ) );
    }

    public serverIsAtLeastVersion( gruid: string, version: string ): boolean {
        const server: Server = this.getServers( true )
            .getValue()
            .servers
            .find( ( s: Server ) => s.clientId === gruid );

        if ( !server ) {
            return true;
        }

        return server.isAtLeastVersion( version );
    }

    public getServers( returnBehaviourSubject = false ): any {
        if ( returnBehaviourSubject ) {
            return this.serverList$;
        }

        return this.serverList$.pipe( filter( ( o: any ) => ( o !== null && undefined !== o ) ) );
    }

    /**
     * Get a single server
     * @param gruid string
     */
    public getServer( gruid: string ): Observable<Server> {
        if ( !gruid || gruid === 'undefined' ) {
            return;
        }

        return this.http.get( `${ environment.urls.api.cdms.clients_go }v4/getClient/${ gruid }` )
            .pipe(
                map( ( statusObj: SingleClientResponseObj ) => {
                    const now = moment();

                    return new Server( {
                        client_id : statusObj.MimirData.client_id,
                        created_at: parseInt( statusObj.MimirData.start_ts ),
                        last_seen : now.valueOf(),
                        name      : statusObj.client.name,
                        extras    : statusObj.client?.extras
                    } );
                } ),
                catchError( ( response: any ) => {
                    return this.handleErrorObservableWithExtra( response );
                } ) );
    }

    /**
     * gets the groups that a server belongs to
     * @param gruid string
     */
    public getGroupsForServer( gruid: string ): Observable<Group[]> {
        if ( !this.groupList$.getValue() ) {
            return of( [] );
        }

        return of( this.groupList$.getValue()
            .filter( ( gl: Group ) => ( gl !== null && gl !== undefined ) )
            .filter( ( g: Group ) => g.servers.filter( ( s: Server ) => s.clientId === gruid ).length ) );
    }

    /**
     * Gets applications for a given server instance
     * @param serverId string
     */
    public getApplicationsForServer( serverId: string ): Observable<{ apps: any[] }> {
        const end = moment();

        const start = moment()
            .subtract( 7, 'days' );

        const formData = new HttpParams( {
            fromObject: {
                end: end.unix()
                    .toString( 10 ),
                start: start.unix()
                    .toString( 10 ),
                step : '5m',
                query: `group(app_up{client_id="${ serverId }"}) by (app_name)`
            }
        } );

        return this.http.post<any>( `${ environment.urls.api.prometheus }prometheus/api/v1/query_range`, formData )
            .pipe(
                map( ( response: any ) => response.data.result ),
                map( ( response: any ) =>
                    ( {
                        apps: response.map( ( app: { metric: any, values: any[] } ) => ( {
                            ...app.metric,
                            last_seen: app.values.pop()[0] * 1000
                        } ) )
                    } ) ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    /**
     * Send an IR to FR for asking the the current value of a single configuration
     * @param server string
     * @param property string
     */
    public getConfiguration( server: string, property: string ): Observable<{ result: string; value: string; }> {
        return this.http.get<{
            data: any
        }>( `${ environment.urls.api.coms.instance }v1/instances/${ server }/configuration/${ property }` )
            .pipe(
                map( ( resp: any ) => resp.data ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public mergeServerAndGroupLists( serverList: Server[], groupList: Group[] ): any {
        if ( !serverList || !groupList ) {
            return;
        }

        if ( groupList.length > 0 ) {
            groupList.map( ( group: Group ) => {
                group.servers = group.servers.map( ( server: any ) => {
                    return serverList.find( ( s  ) => {
                        return s.clientId === server.clientId;
                    } );
                } ).filter( value => !!value );

                group.onlineCount = group.servers.length;

                return group;
            } );
        }

        return groupList;
    }

    /**
     * Send an IR command to FR with an updated configuration value
     * @param configs configs
     * @param ids
     */
    public updateServerConfiguration( configs: ServerConfig[], ids: string[] ): Observable<IServerConfigurationOutcome> {
        return this.http.put<{ data: any }>( `${ environment.urls.api.coms.instance }v1/instances/configuration`, {
            configs,
            ids
        } )
            .pipe(
                map( ( resp: any ) => resp.data ),
                catchError( this.handleErrorObservableWithExtra )
            );
    }

    public async triggerGarbageCollectionk( gruid: string ): Promise<any> {
        return this.http.get( `${ environment.urls.api.coms.instance }v1/instances/${ gruid }/gc` )
            .toPromise()
            .catch( this.handleErrorPromise );
    }

    /**
     * Get all servers
     */
    public getServersInTimeframe( end: number, start?: number ): void {
        if ( this.serverApiHandler ) {
            return this.serverApiHandler;
        }

        if ( !end ) {
            end = moment().valueOf();
        }

        const query: any = {
            start,
            end
        };

        this.serverApiHandler = this.http.get<Record<string, any>>( `${ environment.urls.api.cdms.clients_go }v4/getClients`,
            { params: query } )
            .pipe(
                catchError( this.handleErrorObservableWithExtra )
            )
            .subscribe( {
                next : ( value ) => this.handleServersResponse( value, end ),
                error: ( e ) => {
                    console.error( e );
                },
                complete: () => {
                    this.serverApiHandler = null;
                }
            } );
    }

    /**
     * Fetches
     */
    private getGroupsPrivate(): any {
        if ( this.groupApiHandler ) {
            return this.groupApiHandler;
        }

        this.groupApiHandler = this.http.get<any[]>( `${ environment.urls.api.cdms.groups }v1` )
            .pipe(
                tap( () => {
                    this.groupApiHandler = null;
                } ),
                map( ( response: any ) => {
                    this._entityService.setGroups( response );

                    const res = response.map( ( data: any ) => new Group( data ) );

                    this.generateUngroupedGroup( res, this.serverList$.getValue().servers );

                    return res;
                } ),
                catchError( this.handleErrorObservableWithExtra )
            )
            .subscribe();
    }

    private listenForTimePicker(): void {
        this._timePickerService.timeframeUpdated
            .pipe(
                filter( ( t: KsTimePickerDataObject ) => {
                    return t.source === KsTimePickerSource.MINUTE_REFRESH || t.source === KsTimePickerSource.MANUAL_REFRESH;
                } ),
                debounceTime( 250 ),
                skip( 1 )
            )
            .subscribe( ( t: KsTimePickerDataObject ) => {
                if ( this._authService.loggedIn ) {
                    this.getGroupsPrivate();
                    this.getServersInTimeframe( t.end, t.start );
                }
            } );
    }

    private handleServersResponse( value: AllClientsResponseObj[], lastSeen: number ) {
        const entities = {};
        const servers = [];

        if ( value ) {
            value.forEach( ( statusObj: AllClientsResponseObj ) => {
                if ( !statusObj.MimirData?.client_id?.length ) {
                    return;
                }

                entities[statusObj.MimirData.client_id] = statusObj.client.client_name;
                const server = new Server( {
                    client_id : statusObj.MimirData.client_id,
                    created_at: parseInt( statusObj.MimirData.start_ts ),
                    last_seen : lastSeen,
                    name      : statusObj.client.client_name,
                    extras    : {
                        host: {
                            hostname: statusObj.client.hostname
                        },
                        network: {
                            host_ip_address: statusObj.client.host_ip_address
                        },
                        product: {
                            product_version: statusObj.client.product_version
                        }
                    }
                } );
                servers.push( server );
            } );
        }

        this.serverList$.next( { servers } );
        this._entityService.setClients( entities );
    }

    /**
     * Generate a group 'ungrouped' to contain all servers that are not in a group
     */
    private generateUngroupedGroup( groupList: Group[], serverList: Server[] ): any {
        const ungroupedServers: Server[] = [];

        /**
         * Now the ungrouped group is no longer generated in the API,
         * the UI must 'clear' it each time to ensure new servers are added etc...
         *
         * Could client-services tell us this maybe?
         */
        const groupListCopy = groupList.filter( ( g: Group ) => g.id !== 'ungrouped' );

        serverList.forEach( ( server: Server ) => {
            const group = groupListCopy.find( ( g: Group ) => g.servers
                .findIndex( ( s: Server ) => s.clientId === server.clientId ) !== -1 );

            if ( !group ) {
                ungroupedServers.push( server );
            }
        } );

        let ungroupedGroup = groupListCopy.find( ( g: Group ) => g.id === 'ungrouped' );

        if ( !ungroupedGroup ) {
            ungroupedGroup = new Group( {
                name       : 'ungrouped',
                description: '',
                type       : GroupType.ACCOUNT,
                group_id   : 'ungrouped'
            } );

            ungroupedGroup.servers = ungroupedServers;
            groupListCopy.push( ungroupedGroup );
        } else {
            ungroupedGroup.servers = ungroupedServers;
        }

        this.groupList$.next( [ ...groupListCopy ] );
    }
}
