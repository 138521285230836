import { NgModule } from '@angular/core';
import { GrafExplorerComponent } from './graf-explorer.component';
import { GrafExplorerRoutingModule } from './graf-explorer.routing.module';
import { FlexModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { KsSpinnerModule } from '@intergral/kaleidoscope';
import { GrafDashboardComponent } from './graf-dashboard.component';

@NgModule( {
    declarations: [
        GrafExplorerComponent,
        GrafDashboardComponent
    ],
    exports: [
        GrafExplorerComponent,
        GrafDashboardComponent
    ],
    imports: [
        GrafExplorerRoutingModule,
        FlexModule,
        CommonModule,
        KsSpinnerModule
    ]
} )
export class GrafExplorerModule {
}
