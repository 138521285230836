import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertServiceConfigGlobalPipe } from './alerting-pipes/alert-service-config-global.pipe';
import { AlertServiceConfiguredPipe } from './alerting-pipes/alert-service-configured.pipe';
import { MaxCountPipe } from './alerting-pipes/max-count.pipe';
import { AlphabeticalSortPipe } from './alphabetical-sort.pipe';
import { APIKeyFilterPipe } from './apikey-pipes/apikey-filter.pipe';
import { ApplicationMetricSortPipe } from './application-pipes/application-metric-sort.pipe';
import { ApplicationNamePipe } from './application-pipes/application-name.pipe';
import { CurrencySymbolPipe } from './billing-pipes/currency-symbol.pipe';
import { PlanLengthPipe } from './billing-pipes/plan-length.pipe';
import { PlanPriceFormatPipe } from './billing-pipes/plan-price-format.pipe';
import { ITTDatePipe } from './date-pipes/itt-date.pipe';
import { ITTTimePipe } from './date-pipes/itt-time.pipe';
import { GroupNamePipe } from './group-pipes/group-name.pipe';
import { IsNaNPipe } from './isNaN.pipe';
import { LogfilePipe } from './logfile-pipes/logfile.pipe';
import { QuerySearchPipe } from './logging/query-search.pipe';
import { SelectedLabelsPipe } from './logging/selected-labels.pipe';
import { TimestampSortPipe } from './logging/timestamp-sort.pipe';
import { VisibleFieldPipe } from './logging/visible-field.pipe';
import { VisibleLabelsPipe } from './logging/visible-labels.pipe';
import { ProfilerApplicationPipe } from './profiler-pipes/profiler-application.pipe';
import { ProfilerDurationPipe } from './profiler-pipes/profiler-duration.pipe';
import { ProfilerSearchPipe } from './profiler-pipes/profiler-search.pipe';
import { ServerAllFilterPipe } from './server-pipes/server-all-filter.pipe';
import { ServerFusionReactorPipe } from './server-pipes/server-fusion-reactor.pipe';
import { ServerGroupNotificationsPipe } from './server-pipes/server-group-notifications.pipe';
import { ServerGroupFilter } from './server-pipes/server-group.pipe';
import { ServerNamePipe } from './server-pipes/server-name.pipe';
import { ServerSortPipe } from './server-pipes/server-sort.pipe';
import { ServerUptimePipe } from './server-pipes/server-uptime.pipe';
import { SnapshotSearchPipe } from './snapshot-pipes/snapshot-search.pipe';
import { SnapshotStatePipe } from './snapshot-pipes/snapshot-state.pipe';
import { SnapshotUserPipe } from './snapshot-pipes/snapshot-user.pipe';
import { SavedStackTraceStateFilterPipe } from './stacktrace-pipes/stacktrace-saved-state.pipe';
import { SavedStackTraceTextFilterPipe } from './stacktrace-pipes/stacktrace-saved-text.pipe';
import { SavedStackTraceUserFilterPipe } from './stacktrace-pipes/stacktrace-saved-user.pipe';
import { StackTraceStateFilterPipe } from './stacktrace-pipes/stacktrace-state.pipe';
import { StackTraceTextFilterPipe } from './stacktrace-pipes/stacktrace-text.pipe';
import { ThreadSearchPipe } from './thread-pipes/thread-search.pipe';
import { ThreadStatePipe } from './thread-pipes/thread-state.pipe';
import { TracingSpanPipe } from './tracing-pipes/span-filter.pipe';
import { ThrowableFilterPipe } from './transaction-pipes/throwable.pipe';
import { TransactionFilterPipe } from './transaction-pipes/transaction-filter.pipe';
import { UpperFirstCharPipe } from './upper-first-char.pipe';
import { UserNameEmailPipe } from './user-pipes/user-name.pipe';
import { FilterAndMapSubscriptionLabelsPipe } from './alerting-pipes/filter-and-map-subscription-labels.pipe';
import { RuleEvaluationDatePipe } from './alerting-pipes/rule-evaluation-date.pipe';
import { NegativeToZeroPipe } from './negative-to-zero.pipe';
import { RuleGroupTableSearchPipe } from './alerting-pipes/rule-group-table/rule-group-table-search.pipe';
import { RuleGroupTableStateFilterPipe } from './alerting-pipes/rule-group-table/rule-group-table-state-filter.pipe';
import { RuleDetailTableStateFilterPipe } from './alerting-pipes/rule-detail-table/rule-detail-table-state-filter.pipe';
import { RuleDetailTableSearchPipe } from './alerting-pipes/rule-detail-table/rule-detail-table-search.pipe';
import { TableFilterViewstatePipe } from './table-filter-viewstate.pipe';
import { FilterViewStatePipe } from './filter-view-state.pipe';
import { ParseFloatPipe } from './parse-float.pipe';
import { RulerCheckMetricFilterPipe } from './alerting-pipes/ruler-check/ruler-check-metric-filter.pipe';
import { RulerCheckIdFilterPipe } from './alerting-pipes/ruler-check/ruler-check-id-filter.pipe';
import { RulerCheckTableFilterPipe } from './alerting-pipes/ruler-check/ruler-check-entity-filter.pipe';
import { ArrayWrapPipe } from './wrap-array.pipe';
import { MultiplyByPipe } from './multiply-by.pipe';
import { ParseIntPipe } from './parse-int.pipe';

@NgModule( {
    declarations: [
        ApplicationNamePipe,
        GroupNamePipe,
        UserNameEmailPipe,
        ServerNamePipe,
        AlertServiceConfiguredPipe,
        ThreadSearchPipe,
        ThreadStatePipe,
        TransactionFilterPipe,
        StackTraceTextFilterPipe,
        StackTraceStateFilterPipe,
        AlphabeticalSortPipe,
        AlertServiceConfigGlobalPipe,
        ServerGroupFilter,
        ApplicationMetricSortPipe,
        ServerGroupNotificationsPipe,
        SavedStackTraceStateFilterPipe,
        SavedStackTraceUserFilterPipe,
        SavedStackTraceTextFilterPipe,
        ServerAllFilterPipe,
        ServerFusionReactorPipe,
        ServerSortPipe,
        UpperFirstCharPipe,
        MaxCountPipe,
        ITTDatePipe,
        ITTTimePipe,
        ProfilerDurationPipe,
        ProfilerApplicationPipe,
        ProfilerSearchPipe,
        ThrowableFilterPipe,
        NegativeToZeroPipe,
        TracingSpanPipe,
        LogfilePipe,
        SnapshotUserPipe,
        SnapshotSearchPipe,
        SnapshotStatePipe,
        PlanLengthPipe,
        PlanPriceFormatPipe,
        RulerCheckMetricFilterPipe,
        RulerCheckIdFilterPipe,
        RulerCheckTableFilterPipe,
        CurrencySymbolPipe,
        ServerUptimePipe,
        TimestampSortPipe,
        IsNaNPipe,
        VisibleLabelsPipe,
        VisibleFieldPipe,
        APIKeyFilterPipe,
        QuerySearchPipe,
        FilterAndMapSubscriptionLabelsPipe,
        RuleEvaluationDatePipe,
        FilterViewStatePipe,
        RuleGroupTableSearchPipe,
        RuleGroupTableStateFilterPipe,
        RuleDetailTableSearchPipe,
        RuleDetailTableStateFilterPipe,
        TableFilterViewstatePipe,
        ParseFloatPipe,
        ParseIntPipe,
        SelectedLabelsPipe,
        ArrayWrapPipe,
        MultiplyByPipe
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [ AlphabeticalSortPipe ],
    exports  : [
        ApplicationNamePipe,
        GroupNamePipe,
        UserNameEmailPipe,
        ServerNamePipe,
        AlertServiceConfiguredPipe,
        ThreadSearchPipe,
        ThreadStatePipe,
        StackTraceStateFilterPipe,
        StackTraceTextFilterPipe,
        TransactionFilterPipe,
        StackTraceTextFilterPipe,
        StackTraceStateFilterPipe,
        AlphabeticalSortPipe,
        ServerGroupFilter,
        ApplicationMetricSortPipe,
        ServerGroupNotificationsPipe,
        SavedStackTraceStateFilterPipe,
        SavedStackTraceUserFilterPipe,
        SavedStackTraceTextFilterPipe,
        ServerAllFilterPipe,
        ServerFusionReactorPipe,
        ServerSortPipe,
        UpperFirstCharPipe,
        MaxCountPipe,
        ITTDatePipe,
        ITTTimePipe,
        ProfilerDurationPipe,
        ProfilerApplicationPipe,
        ProfilerSearchPipe,
        ThrowableFilterPipe,
        NegativeToZeroPipe,
        TracingSpanPipe,
        LogfilePipe,
        SnapshotUserPipe,
        SnapshotSearchPipe,
        SnapshotStatePipe,
        PlanLengthPipe,
        PlanPriceFormatPipe,
        AlertServiceConfigGlobalPipe,
        RulerCheckMetricFilterPipe,
        RulerCheckIdFilterPipe,
        RulerCheckTableFilterPipe,
        CurrencySymbolPipe,
        ServerUptimePipe,
        TimestampSortPipe,
        IsNaNPipe,
        VisibleLabelsPipe,
        VisibleFieldPipe,
        APIKeyFilterPipe,
        QuerySearchPipe,
        FilterAndMapSubscriptionLabelsPipe,
        RuleEvaluationDatePipe,
        FilterViewStatePipe,
        RuleGroupTableSearchPipe,
        RuleGroupTableStateFilterPipe,
        RuleDetailTableSearchPipe,
        RuleDetailTableStateFilterPipe,
        TableFilterViewstatePipe,
        ParseFloatPipe,
        SelectedLabelsPipe,
        ArrayWrapPipe,
        MultiplyByPipe,
        ParseIntPipe
    ]
} )
export class SharedPipesModule {
}
